html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.burger-shower, .lines-menu, .lines-x {
  display: none;
}

.login.buttonIDN {
  text-decoration: none;
}

.footA:hover {
  color: #1f49e4;
  transition: color, .7s;
}

/*main*/
.main-page .First_Screen .window1-descript {
  margin-top: 5%;
  margin-bottom: -5%;
}

/*main*/


/*Page2 start*/
.twofixed .center {
  text-align: center;
}

.twofixed .buttonIDN:hover {
  cursor: pointer;
}

.twofixed .Screen5--button-group.s7 {
  max-width: 800px;
}

.twofixed .added-element-before {
  position: relative;
}

.twofixed .dots {
  position: relative;
  z-index: 0;
}

.twofixed .dots::after,
.twofixed .back-color::before,
.twofixed .back-color::after,
.twofixed .S5-P2dots::before,
.common-info::after,
.twofixed .S5-P2dots::before {
  content: '';
  display: block;
  position: absolute;
  left: -38px;
  bottom: -38px;
  background-size: contain;
  z-index: -1;
  width: 180px;
  height: 180px;
  background-repeat: no-repeat;
  background-image: url('../../Images/dots.svg');
}

.dots.Screen5--dots::after {
  left: auto;
  right: -5.9%;
  bottom: -45px;
}

.twofixed .S5-P2dots {
  position: relative;
}

.twofixed .S5-P2dots::before {
  left: -78px;
  bottom: -178px;
  opacity: .2;
}

.twofixed .added-element-before {
  position: relative;
}

.twofixed .left-dashed, .twofixed .P2-S5dashed {
  position: relative;
}

.twofixed .P2-S5dashed::before, .twofixed .left-dashed::before {
  background: url('../../Images/dashed.png') center center repeat;
  content: '';
  position: absolute;
  top: -52%;
  left: 1.3%;
  display: block;
  height: 181%;
  width: 3px;
}

.twofixed .window2.P2.P2-S72.left-dashed::before {
  top: -45%;
  height: 110%;
}

.twofixed .P2-S5dashed::before {
  background: url('../../Images/linehorizont.png') center center repeat;
  height: 3px;
  width: 47%;
  bottom: -166px;
  left: 10.3%;
  top: auto;
}

.twofixed .Screen6.P2.P2-S5dashed.S6::before {
  bottom: -209px;
}

.twofixed .window2.P2.P2-S7.left-dashed::before {
  top: -44%;
  height: 176%;
}

.twofixed .Screen5.P2.P2-S7.P2-S5dashed::before {
  bottom: -198px;
}

.twofixed .added-element-before::before {
  content: '';
  position: absolute;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #3680F6;
  background-image: none;
}

.twofixed .window1-h.P2.S6-P2.added-element-before::before {
  left: 0.6%;
}

.twofixed .rounded-frame::before {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0 0 18px #eaeaea;
  left: -1.8%;
  margin: 0;
  top: 25px;
}

.twofixed .global-network::before {
  background: url('../../Images/global-network.svg') #fff center center no-repeat;
}

.twofixed .bird::before {
  background: url('../../Images/bird.svg') #fff center center no-repeat;
}

.twofixed .cursor::before {
  background: url('../../Images/cursor.svg') #fff center center no-repeat;
}

.twofixed .suitcase::before {
  background: url('../../Images/suitcase.svg') #fff center center no-repeat;
}

.twofixed .phonebook::before {
  background: url('../../Images/phonebook.svg') #fff center center no-repeat;
}

/*left*/
.twofixed .chat::before {
  background: url('../../Images/chat.svg') #fff center center no-repeat;
}

.twofixed .dotline::before {
  background: url('../../Images/dotline.svg') #fff center center no-repeat;
}

.twofixed .videocall::before {
  background: url('../../Images/videocall.svg') #fff center center no-repeat;
}

.twofixed .process::before {
  background: url('../../Images/process.svg') #fff center center no-repeat;
}

.twofixed .infrastructure::before {
  background: url('../../Images/infrastructure.svg') #fff center center no-repeat;
}

.twofixed .dots.P1-S2::after {
  left: -37px;
  bottom: -37px;
  box-sizing: initial;
}

.twofixed .common-info::after {
  right: -56px;
  top: 59px;
  left: auto;
  z-index: 1;
  width: 244px;
  height: 210px;
}

.twofixed .center {
  text-align: center;
}

.twofixed .Screen2.P1-S2, .twofixed .First_Screen, .twofixed .Screen5.S5, .twofixed .Screen6.S6, .twofixed .Screen9.P1-S9 {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

.main-page .Screen6.S6 {
  margin: 397px auto 184px;
}

.main-page .window2.Screen6--w2.Screen5--w1.S6 {
  top: -25px;
}

.twofixed .Screen4, .twofixed .Screen3.main, .twofixed .Screen7.S7, .twofixed .Screen8 {
  width: 100%;
}

.twofixed .Screen5--button-group.s7 {
  max-width: 800px;
}

.twofixed .main-top-nav-anchor:hover, .twofixed .footA:hover {
  color: #3680F6;
  transition: color 0.7s;
  text-decoration: none;
}

.twofixed .Screen2 {
  justify-content: space-around;
  position: relative;
  margin: auto 0 4.2vh;
  flex-flow: row wrap;
  display: flex;
}

.twofixed .illustrate {
  width: 680px;
  height: 572px;
  border-radius: 30px;
}

.twofixed .window1 {
  padding: 2px 0 0 9.65%;
  flex: 0 0 41%;
  box-sizing: initial;
}

.twofixed .window1-h {
  font: normal normal 600 84px/111px 'Poppins', sans-serif;
  letter-spacing: -2.3px;
  margin: -26px 0 0;
  color: #232D53;
}

.twofixed .window1-descript {
  font: normal normal 500 26px/52px 'Poppins', sans-serif;
  letter-spacing: -1.45px;
  padding: 0 5% 0 0;
  margin: 15px 0 0 -0.35%;
  color: #696871;
}

.twofixed .window1--description--button {
  font: normal normal 500 32px/48px 'Poppins', sans-serif;
  color: #232D53;
  letter-spacing: -1.3px;
  margin-bottom: 16px;
}

.twofixed .buttonIDN.login {
  width: 161px;
  height: 52px;
  font: normal normal 500 16px/25px 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
  background-color: #3680F6;
  border-width: 0;
  border-radius: 37px;
  color: #fff;
  z-index: 1;
  text-align: center;
  display: block;
  line-height: 52px;
}

.twofixed .login.buttonIDN.back-slider.top-login {
  height: 52px;
  width: 161px;
  font-size: 20px;
}

.twofixed .buttonIDN:focus {
  outline-width: 0;
}

.twofixed .buttonIDN.big {
  font: normal normal 500 20px/58px 'Poppins', sans-serif;
  width: 251px;
  height: 60px;
}

.twofixed .buttonIDN.big.P1-S7 {
  width: 277px;
  z-index: 1;
}

.twofixed .buttonIDN.white {
  background-color: #fff;
  color: #232D53;
  border: solid 1px #3680F6;
}

.twofixed .login.buttonIDN.S8 {
  display: block;
  margin: 58px auto 0;
  height: 54px;
  width: 271px;
  z-index: 1;
  background-color: transparent;
}

.twofixed .scroll-button.P2 {
  border-width: 0;
  box-shadow: none;
  background: url('../../Images/down-arrow.svg') 74% center no-repeat;
  text-indent: -55px;
}

.twofixed .buttonIDN.scroll-button.P2:hover {
  color: #3680F6;
}

.twofixed .window2 {
  justify-content: center;
  flex: 0 0 40%;
}

.twofixed .dots {
  left: 1.7%;
}

.twofixed .dots.topPhoto {
  left: -3.5%;
}

.twofixed .dots.odd {
  left: -3.1%;
}

.twofixed .window1--button-group {
  display: flex;
  justify-content: space-between;
}

.twofixed .window1--button-group {
  margin: 100px auto 0 0;
  width: 77.5%;
}

.twofixed .invert {
  color: #232D53;
  background-color: #fff;
}

.twofixed .chevron {
  display: block;
  width: 100%;
  margin: 71px auto 0;
}

.twofixed .chevron--down {
  height: 9px;
  width: 22px;
  margin: auto;
  display: block;
}

.twofixed .main {
  margin: 0;
  background-color: #F7F7FB;
}

.twofixed .main-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 216px 11% 295px 17.8%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: initial;
}

.twofixed .main-header-first.main-header-first-col {
  position: relative;
  display: flex;
  justify-content: center;
}

.twofixed .main-header-dots.dots::after {
  left: -154px;
  bottom: -43px;
  z-index: 0;
}

.twofixed .main-header-dots.dots {
  left: 2%;
  position: relative;
  width: 600px;
  height: 572px;
}

.twofixed .first-col-img {
  width: 600px;
  height: 572px;
  border-radius: 25px;
  margin-left: -142px;
  position: relative;
  left: 6.2%;
}

.twofixed .main-header-second {
  padding: 0 7.6% 0 9.85%;
  box-sizing: initial;
}

.twofixed .main-header-second-h2.head2 {
  font: normal normal 600 24px/24px 'Poppins', sans-serif;
  color: #3680F6;
  margin: 0;
  letter-spacing: -0.1px;
}

.twofixed .main-p {
  font: italic normal 400 38px/57px 'Poppins', sans-serif;
  margin: 35px 0 0;
  color: #10214F;
  text-indent: 1px;
}

.twofixed .main-post-link {
  font: normal normal 700 38px/57px 'Poppins', sans-serif;
  margin: 59px 0 0;
  display: block;
  position: relative;
  color: #10214F;
  max-width: max-content;
}

.twofixed .main-post-link:before {
  content: '';
  position: absolute;
  display: block;
  width: 49.1%;
  height: 4px;
  background-color: #FF6B0F;
  bottom: -1px;
  left: 34.9%;
}

.twofixed .Screen4 {
  padding: 94px 0 0;
  position: relative;
}

.twofixed .normal.main-p {
  font-style: normal;
  margin: 39px auto 0;
  max-width: 72%;
  line-height: 81px;
  letter-spacing: -1.2px;
}

.twofixed .normal.main-p.center.S4 {
  max-width: 1220px;
}

.twofixed .main-post-link.frame4 {
  margin: 11px auto 0;
  letter-spacing: -1.6px;
  max-width: 300px;
}

.twofixed .main-post-link.frame4::before {
  height: 2px;
  bottom: -31px;
  width: 91.8%;
  left: 6.2%;
}

.twofixed .back-color {
  background-color: #F7F7FB;
  padding-bottom: 88px;
  margin-top: -10px;
}

.twofixed .main-p.cover {
  font-size: 32px;
  margin-top: 126px;
  padding-top: 130px;
  letter-spacing: -1.1px;
  text-indent: -8px;
}

.twofixed .sectors {
  display: flex;
  max-width: 1285px;
  margin: auto;
}

.twofixed .sector {
  font: italic normal 500 28px/42px 'Poppins', sans-serif;
}

.twofixed .sector.normal {
  margin: auto;
  padding: 125px 0 0;
  letter-spacing: -1.2px;
  position: relative;
}

.twofixed .Screen4--all.center {
  margin: 40px 0 0;
}

.twofixed .selectors--all {
  font: normal normal 900 16px/28px 'Poppins', sans-serif;
  color: #3680F6;
  position: relative;
  text-indent: -28px;
  display: block;
  max-width: 165px;
  margin: auto;
  letter-spacing: -0.2px;
  text-decoration: none;
}

.twofixed .back-color {
  position: relative;
}

.twofixed .back-color::before {
  top: -90px;
  left: 2.3%;
  z-index: 0;
}

.twofixed .Screen4.P2-S4::before {
  top: -4.9%;
  left: 1%;
}

.twofixed .Screen4.P2-S4::before, .twofixed .Screen4.P2-S4::after {
  top: 10.3%;
  right: 1%;
}

.twofixed .back-color::after {
  top: -90px;
  left: auto;
  right: 0;
  z-index: 0;
}

.twofixed .Screen5 {
  display: flex;
  position: relative;
  margin: 182px auto 0;
  justify-content: space-between;
}

.twofixed .Screen5--w1 {
  padding: 11px 0 0;
  position: relative;
  left: 13.6%;
  max-width: 50%;
  flex: 0 0 50%;
}

.twofixed .Screen5--h2.head2 {
  text-align: left;
  letter-spacing: 0;
  padding: 0 0 0 3%;
  margin: -3px 0 14px;
}

.twofixed .Screen5--description--content {
  padding: 52px 0 0 0;
  max-width: 77%;
}

.twofixed .Screen5--strong {
  font: normal normal 700 26px/36px 'Poppins', sans-serif;
  color: #1D293F;
  padding: 0 0 0 11.8%;
  display: block;
  position: relative;
}

.twofixed .Screen5--strong.P1-S6 {
  padding: 0 0 0 12.2%;
}

.twofixed .Screen5--p.P1-S6 {
  padding-left: 12.2%;
}

.twofixed .Screen5--p {
  font: normal normal 400 22px/36px 'Poppins', sans-serif;
  color: #7E7F7E;
  padding: 1px 0 36px 11.7%;
  margin: 0;
  position: relative;
}

.twofixed .Screen5--strong::after, .twofixed .Screen5--p::after {
  content: '';
  display: block;
  position: absolute;
}

.twofixed .Screen5--strong::after {
  top: 1px;
  left: 4%;
  background-color: #3680F6;
  height: 32px;
  width: 32px;
  border-radius: 20px;
  z-index: 1;
  border: solid 2px #fff;
}

.twofixed .Screen5--p::after {
  top: -7px;
  left: 6.5%;
  background-color: #1D293F;
  height: 155%;
  width: 2px;
}

.twofixed .Screen5--p.S5::after {
  left: 6.2%;
}

.twofixed .Screen5--p.P1-S6::after {
  left: 6.3%;
}

.twofixed .Screen5--p:last-child::after {
  background-color: #fff;
  width: 6px;
  left: 5.5%;
  height: 100%;
}

.twofixed .Screen5--p.S5:last-child::after {
  left: 7.5%;
}

.twofixed .Sreen5--h3 {
  font: normal normal 600 50px/70px 'Poppins', sans-serif;
  color: #10214F;
  padding: 18px 0 0 3%;
  letter-spacing: -1.3px;
  margin: 0;
}

.twofixed .Screen5--button-group {
  max-width: 55.3%;
  margin: 19px 0 0 4.8%;
  justify-content: space-between;
}

.twofixed .login.Screen5--button--text {
  font: normal normal 500 16px/25px 'Poppins', sans-serif;
}

.twofixed .illustrate.Screen5-img {
  height: 654px;
  width: 600px;
}

.twofixed .dots.Screen5--dots::after {
  left: auto;
  right: -8%;
  bottom: -45px;
}

.twofixed .dots.Screen5--dots {
  left: -13.1%;
  top: 9px;
  width: 600px;
  height: 650px;
}

.twofixed .Screen6 {
  display: flex;
  position: relative;
  margin: 436px auto 217px;
}

.twofixed .dots.Screen6--dots {
  left: 3.1%;
}

.twofixed .Screen6--w2.window1 {
  padding: 22px 0 0 1.35%;
  max-width: 46%;
  flex: 0 0 48%;
}

.twofixed .dots.Screen5--dots.Screen6--dots::after {
  right: auto;
  left: -50px;
  bottom: auto;
  top: -120px;
}

.twofixed .Screen5--description--content.S6c {
  max-width: 86%;
  padding: 52px 0 0;
}

.twofixed .Screen5--button-group.S6bg {
  max-width: 63.2%;
  margin-top: 19px;
}

.twofixed .Screen7.P2-S7-remade {
  position: relative;
  background-image: none;
  padding: 92px 0 24px 9.2%;
  overflow: hidden;
}

.twofixed .Screen7--h2 {
  margin: 0;
  color: #fff;
  text-align: center;
  letter-spacing: -1.4px;
  font: normal normal 600 60px/60px 'Poppins', sans-serif;
}

.twofixed .Screen7--p {
  letter-spacing: -1.15px;
  color: #fff;
  text-align: center;
  font: normal normal 400 28px/63px 'Poppins', sans-serif;
  max-width: 1245px;
  margin: 84px auto 89px;
  position: relative;
  left: -0.15%;
}

.twofixed .Screen7--p.P2-S7-remade {
  font: normal normal 600 24px/56px 'Poppins', sans-serif;
  text-align: left;
  max-width: initial;
  margin: 16px auto 89px;
  letter-spacing: 0;
}

.twofixed .back-color.S7 {
  background-color: transparent;
  padding-bottom: 141px;
}

.twofixed .back-color.S7.P1-S7::before, .twofixed .back-color.S7.P1-S7::after {
  content: none;
}

.twofixed .back-color.S7.P2-S7-remade {
  padding-bottom: 0;
  margin-top: 0;
}

.twofixed .main-p.S7 {
  color: #fff;
}

.twofixed .sectors.S7 {
  max-width: 1335px;
  position: relative;
  justify-content: center;
}

.twofixed .sectors.S7.P2-S7-remade {
  max-width: 1363px;
  right: 0;
  margin: 0;
  justify-content: space-between;
  flex-wrap: wrap;

}

.twofixed .sector.S7 {
  margin: auto;
  padding: 108px 0 0;
  letter-spacing: 0.2px;
}

.twofixed .sector.normal.center.S7 {
  font: normal normal 700 22px/27px 'Open Sans', sans-serif;
}

.twofixed .info-graph {
  margin: 0;
  padding: 70px 0 50px;
  background-position: 0 8px;
  text-align: left;
}

.twofixed .dot-list {
  margin: 20px 0 0 4.6%;
  padding: 0;
  max-width: 422px;
}

.twofixed .dots-item {
  font: normal normal 600 20px/32px 'Poppins', sans-serif;
  color: #fff;
  list-style-type: none;
  position: relative;
}

.twofixed .dots-item::before {
  content: '';
  position: absolute;
  display: block;
  left: -20px;
  top: 14px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #3874FF;
}

.twofixed .sector.normal.one.S7 {
  background: url('../../Images/connection.svg') 52.2% 26px no-repeat;
}

.twofixed .sector.normal.main-p.S7.P2-S7-remade {
  background-position: 0 42%;
}

.twofixed .sector.normal.two.S7 {
  background: url('../../Images/opportunity.svg') 51.1% 33px no-repeat;
  position: relative;
  left: -0.65%;
}

.twofixed .sector.normal.three.S7 {
  background: url('../../Images/win.svg') 43.8% 30px no-repeat;
  position: relative;
  left: -2.2%;
}

.twofixed .sector.normal.four.S7 {
  background: url('../../Images/trust.svg') 36% 29px no-repeat;
}

.twofixed .sector.normal.five.S7 {
  background: url('../../Images/security.svg') 54.4% 25px no-repeat;
}

.twofixed .sector.normal.five.P3-S7-remade.S7 {
  background: url('../../Images/win.svg') 12.4% 37px no-repeat;
}

.twofixed .Screen5--button-group.S7 {
  max-width: 870px;
  margin: auto;
  padding-bottom: 73px;
}

.twofixed .window1--description--button.S7 {
  font: normal normal 500 36px/55px 'Poppins', sans-serif;
  color: #fff;
  padding: 9px 0 1px;
  text-align: center;
  letter-spacing: -1.5px;
  margin-top: -2px;
  margin-bottom: 16px;
}

.twofixed .window1--description--button.S7.Advisor {
  font: normal normal 500 33px/50px 'Poppins', sans-serif;
  margin-left: 4%;
}

.twofixed .S7.S71.dot-list.Advisor {
  margin: 36px 0;
  min-width: 422px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.twofixed .window1--description.S7 {
  margin: 0;
}

.twofixed .Screen7--h2.S8 {
  font: normal normal 700 40px/60px 'Poppins', sans-serif;
  padding: 118px 0 0 0;
  letter-spacing: -1.1px;
}

.twofixed .Screen9 {
  padding: 65px 0;
  display: flex;
  justify-content: space-between;
}

.twofixed .idn-img {
  width: 50px;
  height: 60px;
  display: block;
  background: url('../../Images/idn.png') center no-repeat;
  background-size: contain;
  flex-grow: 1;
  margin-top: -15px;
  position: relative;
  left: -2.6%;
}

.twofixed .foot-ul {
  padding: 0;
  margin: 0;
}

.twofixed .foot-lists {
  display: flex;
  flex-grow: 5;
  justify-content: space-around;
  position: relative;
  right: 1.15%;
  max-width: 1631px;
}

.twofixed .foot-li.foot-li-head {
  color: #1D293F;
  font: normal normal 700 20px/30px 'Poppins', sans-serif;
  padding: 0 0 27px 0;
  letter-spacing: -1.1px;
}

.twofixed .foot-li {
  letter-spacing: -1.1px;
  list-style-type: none;
  font: normal normal 500 18px/27px 'Poppins', sans-serif;
  color: #1D293F;
}

.twofixed .opacity6 {
  opacity: 0.6;
}

.twofixed .back-slider.buttonIDN::before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background-color: #3680F6;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -2;
}

.twofixed .back-slider-two.back-slider.buttonIDN::before {
  left: 0;
}

.twofixed .back-slider.buttonIDN.white::before {
  background-color: transparent;
}

.twofixed .back-slider.buttonIDN.white::before {
  z-index: -1;
}

.twofixed .back-slider.buttonIDN.white.decide::before {
  background-color: #fff;
}

.twofixed .back-slider.buttonIDN::after {
  content: '';
  width: 220%;
  height: 307%;
  border-radius: 50%;
  background-color: #112b88;
  position: absolute;
  left: -204%;
  top: 10%;
  z-index: -1;
  transition: all 0.5s;
}

.twofixed .back-slider.buttonIDN:hover::after {
  left: -15%;
  top: -60%;
}

.twofixed .indent-bigger {
  text-indent: 0;
  transition: all 0.5s;
}

.twofixed .indent-bigger:hover {
  text-indent: 15px;
}

.twofixed .white {
  color: #232D53;
  transition: all 0.5s;
}

.twofixed .buttonIDN.white:hover {
  color: #fff;
}

.twofixed .buttonIDN.font-size-scale {
  font-size: 20px;
  transition: all 0.5s;
}

.twofixed .buttonIDN.font-size-scale:hover {
  font-size: 15px;
}

.twofixed .back-slider.back-slider-two.buttonIDN {
  background-color: transparent;
}

.twofixed .back-slider-two.buttonIDN.white.border::before {
  border: solid 1px #3680F6;
  height: 96%;
  width: 96%;
  margin: auto;
}

.twofixed .back-slider-two.buttonIDN.white.border {
  border: solid 1px transparent;
}

.twofixed .back-slider-two.buttonIDN::before {
  left: 0;
  transition: all 0.5s;
}

.twofixed .back-slider-two.buttonIDN:hover::before {
  background-color: transparent;
  left: -100%;
}

.twofixed .back-slider.buttonIDN.why-idn.S2::after {
  color: #06113e;
}

.twofixed .sizes-scale {
  transition: all 0.7s;
}

.twofixed .sizes-scale:hover {
  transform: scale(0.9);
}

.twofixed .buttonIDN.white.blue-back.back-slider {
  transition: all 0.7s;
}

.twofixed .buttonIDN.white.blue-back.back-slider:hover {
  border-color: #3680F6;
}

.twofixed .login.buttonIDN.back-slider.back-slider-two.indent-bigger.white.border::after, .twofixed .login.buttonIDN.P1-S5.white.back-slider::after, .twofixed .buttonIDN.white.blue-back.back-slider::after {
  background-color: #3680F6;
}

.twofixed .buttonIDN.white.blue-back.back-slider:hover::after {
  background-color: #3680F6;
}

.twofixed .login.buttonIDN.decide {
  border-color: transparent;
  height: 60px;
  line-height: 60px;
}

.twofixed .back-slider-two.buttonIDN.white.border {
  border-width: 0;
}

.twofixed .buttonIDN.white.blue-back.back-slider, .twofixed .login.buttonIDN.P1-S5 {
  transition: left, font-size 0.7s;
  box-sizing: border-box;
}

.twofixed .login.buttonIDN.P1-S5:hover {
  border-color: #10214f;
  border-width: 0;
}

.twofixed .login.buttonIDN.P1-S5 {
  box-sizing: border-box;
}

@media (max-width: 1199px) and (min-width: 1070px) {
  .main-page .top-horizon-menu,
  .twofixed .top-horizon-menu.X1024-1200 {
      width: 990px;
      margin-top: 15px;
  }

  .twofixed .Screen2.P1-S2,
  .twofixed .Screen5.S5,
  .twofixed .Screen6.S6,
  .twofixed .Screen9.P1-S9 {
      max-width: 1240px;
      margin-right: auto;
      margin-left: auto;
  }

  .main-page .Screen6.S6.P1-S6 {
      margin-bottom: 63px;
  }

  .main-page .Screen7.S7 {
      padding-bottom: 73px;
  }

  .twofixed .Screen4,
  .twofixed .Screen3.main,
  .twofixed .Screen7.S7,
  .twofixed .Screen8 {
      width: 100%;
  }

  .twofixed .Header {
      padding: 6px 3.9% 76px 30px;
      margin: 40px 0 0 0;
  }

  .main-page .idnlogo,
  .twofixed .idnlogo {
      height: 51px;
      width: 155px;
      margin-top: 15px;
  }

  .twofixed .main-top-nav {
      padding: 0;
      flex-grow: 8;
  }

  .main-page .main-top-nav-anchor,
  .twofixed .main-top-nav-anchor {
      margin: 0;
      font: normal normal 500 19px/22px 'Poppins', sans-serif;
      letter-spacing: -0.6px;
      padding: 43px 20px 40px 20px;
  }

  .main-page .login-block,
  .twofixed .login-block {
      justify-content: flex-end;
      padding-left: 20px;
      margin-right: 10px;
  }

  .twofixed .buttonIDN.login {
      width: 161px;
      height: 52px;
      line-height: 52px;
  }

  .twofixed .Screen2.P1-S2 {
      width: 950px;
      margin: 0 auto;
  }

  .twofixed .window1-h {
      font: normal normal 600 49px/70px 'Poppins', sans-serif;
      letter-spacing: -1.9px;
  }

  .twofixed .window1.Screen2.X12000 {
      max-width: 50%;
      flex: 0 0 50%;
      margin-top: 10px;
  }

  .twofixed .window1, .twofixed .window2 {
      padding: 0 1%;
      flex: 0 0 40%;
      max-width: 40%;
  }

  .twofixed .window1--button-group.P1-S2 {
      width: 88%;
      flex-direction: row;
      margin: 0 auto 0 0;
  }


  .twofixed .window1-descript {
      font: normal normal 500 21px/41px 'Poppins', sans-serif;
      padding: 0 1% 0 0;
      margin-left: -0.1%;
      letter-spacing: -0.8px;
  }

  .twofixed .dots.Screen5--dots, .twofixed .dots {
      left: 8.2%;
      margin: 0 0 0 auto;
      width: auto;
      height: 401px;
  }

  .twofixed .dots.P1-S2 {
      left: 0.2%;
      margin: 0;
      width: auto;
      height: auto;
  }

  .twofixed .dots.Screen5--dots.S5 {
      margin: 0 0 0 auto;
      left: -49px;
      height: 401px;
      width: 368px;
  }

  .twofixed .dots.Screen6--dots {
      left: 50px;
      top: 23px;
  }

  .twofixed .illustrate.Screen5-img.S5 {
      width: 368px;
      height: 401px;
  }

  .twofixed .dots.Screen5--dots::after {
      right: 8.5%;
      bottom: -41px;
  }

  .twofixed .Screen5--strong {
      font: normal normal 700 22px/36px 'Poppins', sans-serif;
      padding: 0 0 0 18.8%;
  }

  .twofixed .Screen5--p {
      font: normal normal 400 18px/24px 'Poppins', sans-serif;
      padding: 1px 40px 24px 18.7%;
  }

  .twofixed .Screen5--p.S5::after {
      top: -7px;
      left: 9%;
  }

  .twofixed .Screen5--strong::after {
      top: 1px;
      left: 5.7%;
  }

  .twofixed .Screen5--strong.P1-S6::after {
      left: -4px;
  }

  .twofixed .Screen5--p.P1-S6::after {
      left: 2.7%;
  }

  .twofixed .window1--button-group.Screen5--button-group {
      flex-direction: row;
      width: 95.5%;
      justify-content: start;
      margin: 20px 0 0 27px;
      max-width: 100%;
  }

  .twofixed .Screen6 {
      margin: 154px auto 217px;
  }

  .twofixed .main-header-second {
      padding: 0 0 0 5%;
      width: 77%;
  }

  .twofixed .illustrate, .twofixed .illustrate.Screen2, .twofixed .illustrate.Screen5-img, .twofixed .first-col-img, .twofixed .main-header-dots.dots {
      width: 361px;
      height: 304px;
      margin: 0 0 0 auto;
  }

  .twofixed .illustrate.Screen6-img {
      height: 430px;
      width: 394px;
  }

  .twofixed .dots::after,
  .twofixed .back-color::before,
  .twofixed .back-color::after,
  .twofixed .dots.P1-S2::after {
      width: 180px;
      height: 180px;
      bottom: -46px;
      left: -29px;
  }

  .twofixed .dots::after {
      bottom: -38px;
      left: -38px;
  }

  .twofixed .back-color::after {
      left: auto;
  }

  .twofixed .back-color::before {
      left: auto;
      right: 73px;
  }


  .twofixed .main-header-dots.dots::after {
      left: -34px;
      bottom: -138px;
  }

  .twofixed .first-col-img {
      margin-left: 0;
      position: static;
  }

  .twofixed .window1--button-group {
      width: 100%;
      flex-direction: column;
  }

  .twofixed .main-header {
      padding: 64px 1% 48px 1%;
  }

  .twofixed .main-header-first, .twofixed .main-header-second {
      padding: 0 50px 0 56px;
  }

  .twofixed .main-header-second {
      padding: 0 45px 0 108px;
  }

  .twofixed .main-header-second-h2.head2 {
      margin: 6px 0 0;
      letter-spacing: -0.1px;
  }

  .twofixed .main-post-link.frame4::before {
      bottom: 6px;
      width: 46%;
      left: 27%;
  }

  .twofixed .normal.main-p.S4 {
      font: normal normal 400 30px/60px 'Poppins', sans-serif;
      margin: 46px auto 0;
      max-width: 95%;
      letter-spacing: -1px;
      text-indent: 0;
  }

  .twofixed .Screen5.S5 {
      margin: 80px auto 0;
      overflow: hidden;
  }

  .twofixed .Screen5--h2.head2.center.P1-S6 {
      margin-top: 0;
  }

  .twofixed .Sreen5--h3 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
      padding: 14px 0 0 5.8%;
      letter-spacing: -0.9px;
  }

  .twofixed .Screen5--description--content, .twofixed .Screen5--description--content.S6c {
      max-width: 100%;
      padding: 76px 0 0 0;
  }

  .twofixed .Screen5--description--content.S6c {
      padding: 50px 0 0 30px;
  }

  .twofixed .main-p {
      font: italic normal 400 30px/47px 'Poppins', sans-serif;
      margin: 32px 0 0;
      text-indent: 1px;
  }

  .twofixed .main-post-link {
      font: normal normal 700 30px/57px 'Poppins', sans-serif;
      margin: 41px 0 0;
      width: 104%;
  }

  .twofixed .first-col-img {
      margin-left: -13px;
      width: auto;
      height: 400px;
  }

  .twofixed .normal.main-p.P2 {
      margin: 93px auto 0;
      font: normal normal 400 26px/60px 'Poppins', sans-serif;
      max-width: 97%;
      letter-spacing: -0.9px;
  }

  .twofixed .sectors.S4 {
      max-width: 72%;
      justify-content: space-between;
  }

  .twofixed .Screen4--all.center {
      margin: 48px 0 0;
  }

  .twofixed .sector.normal {
      font: normal normal 600 18px/62px 'Poppins', sans-serif;
  }

  .twofixed .sector.normal.center.S7 {
      font: normal normal 700 18px/32px 'Open Sans', sans-serif;
      background-position-x: center;
      background-position-y: center;
      margin-top: 58px;
  }

  .twofixed .sector.normal.center {
      left: auto;
      background-position-x: center;
      background-position-y: center;
      padding: 150px 0 0;
      margin: 0;
      max-width: 100%;
      right: auto;
      letter-spacing: -0.8px;
  }

  .twofixed .main-post-link.frame4 {
      margin: 2px auto 0;
  }

  .twofixed .Screen5--w1, .twofixed .Screen5--w2 {
      padding: 0 1%;
      left: auto;
      flex: 0 0 46%;
      max-width: 100%;
      overflow-x: hidden;
  }

  .twofixed .Screen5--w1, .twofixed .Screen5--w2 {
      flex: 0 0 45.2%;
  }

  .twofixed .Screen5--decription {
      margin: 0;
  }

  .twofixed .Screen5--decription.S51 {
      margin: 0 0 0 25px;
  }

  .twofixed .window2.Screen6--w2.Screen5--w1.S6 {
      padding-top: 100px;
  }

  .twofixed .window1.Screen6--w2.Screen5--w2.S6 {
      padding-top: 124px;
  }

  .twofixed .Screen6--w2.window1 {
      padding: 24px 2% 0 0;
      max-width: 100%;
  }

  .twofixed .window1--description {
      margin: 0;
  }

  .twofixed .window1--description--button {
      letter-spacing: -1.5px;
      font: normal normal 500 25px/48px 'Poppins', sans-serif;
      margin: 29px auto 12px;
  }

  .twofixed .buttonIDN.login {
      width: 188px;
  }

  .twofixed .back-slider.buttonIDN.indent-bigger {
      line-height: 50px;
  }

  .twofixed .chevron {
      margin: 35px auto 0;
  }

  .twofixed .Screen5--button-group.S7 {
      display: flex;
      flex-direction: row;
      max-width: 57%;
      margin: auto;
  }

  .twofixed .Screen5--button-group.S6bg {
      max-width: none;
  }

  .twofixed .window1--description--button.S7 {
      font: normal normal 500 24px/35px 'Poppins', sans-serif;
      padding: 0 0 24px;
  }

  .twofixed .window1--button-group.Screen5--button-group.S6bg {
      margin: 17px 0 0 25px;
  }

  .twofixed .Screen7 {
      padding: 130px 0 64px;
  }

  .twofixed .Screen7--h2 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
  }

  .twofixed .Screen7--p.S7 {
      font: normal normal 400 22px/40px 'Poppins', sans-serif;
      max-width: 100%;
      letter-spacing: -0.9px;
      margin: 87px auto 0;
      padding: 0 60px;
  }

  .twofixed .Screen6.S6 {
      margin: 54px auto 60px;
  }

  .twofixed .sectors.S7 {
      justify-content: space-around;
      max-width: 77%;
      right: auto;
  }

  .twofixed .back-color.S7 {
      padding-bottom: 90px;
  }

  .twofixed .login.buttonIDN.P1-S5 {
      line-height: 52px;
  }

  .twofixed .Screen9.P1-S9 {
      max-width: 97.2%;
      margin: 0 2.9%;
      padding-bottom: 30px;
  }

  .twofixed .idn-img {
      width: 67px;
      height: 58px;
      left: -49px;
      top: -3px;
  }

  .twofixed .foot-lists {
      display: flex;
      flex-grow: 5;
      justify-content: space-between;
      right: 6px;
      max-width: 796px;
      top: -5px;
  }

  .twofixed .Screen5--p.S5:after {
      left: 8.5%;
  }

  .main-page .window1.Screen6--w2.Screen5--w2.S6 {
      margin-top: 97px;
      padding: 44px 0 0;
  }

  /*page5*/
  .page5 .dots.Screen5--dots.Screen6--dots.S6.P5-S6 {
      left: 0;
      width: 390px;
      height: 303px;
      padding: 0 0 60px 0;
      margin: 60px 0 auto auto;
  }

  .page5.twofixed .Screen5--p.P1-S6.P5-S6::after {
      height: 155%;
      left: .6%;
  }

  .page5.twofixed .Screen5--p.P1-S6.P5-S6:last-of-type::after {
      left: 0;
  }

  .page5 .illustrate.Screen5-img.Screen6-img.P5-S6 {
      width: 481px;
      height: 308px;
      object-fit: cover;
  }

  .page5 .window1.Screen6--w2.Screen5--w2.S6.P5-S6 {
      align-self: flex-end;
      padding-top: 0;
  }

  .twofixed.page5 .window2.Screen6--w2.Screen5--w1.S6 {
      display: flex;
      padding: 0;
  }

  /*page5*/
}

@media (max-width: 1600px) and (min-width: 1070px) {
  .twofixed .main-header-second-h2.head2.S3 {
      margin-top: -11px;
  }

  .twofixed .login.buttonIDN.back-slider {
      height: 50px;
      width: 190px;
      font-size: 20px;
  }

  .twofixed .login.buttonIDN.back-slider.white {
      font-size: 16px;
  }

  .twofixed .main-header-second {
      padding: 0.7% 7.6% 0 12.5%;
      max-width: 410px;
  }

  .twofixed .main-p.X1600 {
      max-width: 520px;
  }

  .twofixed .window1--button-group {
      justify-content: initial;
  }

  .twofixed .window1--description {
      margin-right: 40px;
  }

  .main-page .Screen5.S5.P1-S5 {
      margin: 194px auto 0;
  }

  .main-page .Screen6.S6 {
      margin: 242px auto 196px;
  }

  /*Page4 start*/
  .visualtextinfo.max-width1200 {
      justify-content: space-around;
  }

  /*Page4 finish*/
  /*Page5 start*/
  .page5.twofixed .window1.P2.S2-P2 {
      max-width: 600px;
  }

  .page5 .Screen5--p.P1-S6.P5-S6 {
      font-size: 18px;
      line-height: 24px;
      padding: 27px 0 4% 7%;
      margin-right: 23%;
      width: 420px;
  }

  .page5 .Screen5--p.P1-S6.P5-S6::before {
      left: -4%;
  }

  .page5 .Screen5--p.P1-S6.P5-S6::after {
      left: -.5%;
  }

  .page5 .Screen5--p.P1-S6.P5-S6:last-of-type::after {
      left: -1.3%;
  }

  /*Page5 finish*/
}

/*Page1 start*/
@media (max-width: 1600px) and (min-width: 1070px) {
  .twofixed .login.buttonIDN.decide,
  .twofixed .login.buttonIDN {
      width: 240px;
      height: 50px;
      line-height: 50px;
  }

  .twofixed .dots.dotted,
  .twofixed .dots.dotted {
      width: 393px;
      height: 493px;
  }

  .twofixed .Screen5--button-group {
      margin: 33px 0 0 6.2%;
      width: 86.8%;
      max-width: 86.8%;
  }

  .twofixed .Screen5--button-group.S6bg {
      margin: 16px 0 0 6.2%;
      width: 94.3%;
      max-width: 94.4%;
  }

  .twofixed .window1.Screen5--w1 {
      flex: 0 0 54%;
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .twofixed .Screen2.P1-S2,
  .twofixed .Screen5.S5,
  .twofixed .Screen6.S6,
  .twofixed .Screen9.P1-S9 {
      max-width: 1200px;
      margin-right: auto;
      margin-left: auto;
  }

  .twofixed .Screen4,
  .twofixed .Screen3.main,
  .twofixed .Screen7.S7,
  .twofixed .Screen8 {
      width: 100%;
  }

  .twofixed .Header {
      padding: 39px 1% 0;
      margin: 0 auto 0 0;
  }

  .main-page .logo-link,
  .twofixed .logo-link {
      width: 155px;
      height: 31px;
      margin: 15px 0 0 2px;
  }

  .twofixed .idnlogo {
      width: 185px;
      height: 51px;
  }

  .twofixed .main-top-nav-anchor {
      margin: 9px 0.1% 4.8% 5.4%;
      font: normal normal 500 20px/27px 'Poppins', sans-serif;
      letter-spacing: -0.8px;
  }

  .twofixed .login-block {
      justify-content: flex-end;
      padding-right: 1.5%;
  }

  .twofixed .buttonIDN.white.S2:hover {
      border-color: transparent;
  }

  .twofixed .window1-h {
      font: normal normal 600 60px/80px 'Poppins', sans-serif;
      letter-spacing: -1.7px;
      margin: 3px 0 0;
  }

  .twofixed .window1.Screen2.X1600 {
      flex: 0 0 48%;
      width: 48%;
      padding: 2px 0 0 0.75%;
      margin-top: -20px;
  }

  .twofixed .window1--description--button {
      font: normal normal 500 24px/35px 'Poppins', sans-serif;
      margin: 37px 0 16px;
  }

  .twofixed .window2.X1600 {
      flex: 0 0 33%;
      width: 33%;
  }

  .twofixed .dots {
      left: -8.3%;
      position: relative;
      z-index: 0
  }

  .twofixed .window1-descript {
      margin: 24px 0 0 0.3%;
      font: normal normal 500 22px/40px 'Poppins', sans-serif;
      letter-spacing: -1.2px;
      padding: 0 1% 0 0;
  }

  .twofixed .illustrate.Screen2 {
      width: 396px;
      height: 334px;
      border-radius: 25px;
  }

  .twofixed .chevron {
      margin-top: 28px;
  }

  .twofixed .main-header {
      padding: 114px 0 295px 0;
      max-width: 90%;
      margin: auto;
  }

  .twofixed .main-header-second {
      padding: 0.7% 7.6% 0 10%;
      box-sizing: initial;
  }

  .twofixed .main-post-link {
      font: normal normal 700 38px/57px 'Poppins', sans-serif;
      margin: 59px 0 0;
  }

  .twofixed .main-post-link {
      font: normal normal 700 30px/49px 'Poppins', sans-serif;
      margin: 44px 0 0;
  }

  .twofixed .main-header.S3 {
      padding: 115px 0 164px;
      max-width: 1050px;
  }

  .twofixed .main-p {
      font: italic normal 400 30px/47px 'Poppins', sans-serif;
      margin: 35px 0 0;
  }

  .twofixed .normal.main-p {
      margin: 45px auto 0;
      max-width: 78%;
      font: normal normal 400 30px/60px 'Poppins', sans-serif;
      letter-spacing: -0.9px;
  }

  .twofixed .normal.main-p.P2 {
      margin: 135px auto 0;
      max-width: 78%;
      font: normal normal 400 26px/60px 'Poppins', sans-serif;
      letter-spacing: -0.9px;
  }

  .twofixed .sectors.S4 {
      max-width: 800px;
  }

  .twofixed .sector.normal.main-p.center {
      margin: 12px auto 0;
      max-width: 100%;
      font: normal normal 500 17px/20px 'Poppins', sans-serif;
      letter-spacing: -0.9px;
      background-position-x: center;
      background-position-y: center;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      text-indent: -25px;
      padding: 160px 0 0;
  }

  .twofixed .selectors--all {
      margin-top: 69px;
  }

  .twofixed .dots.Screen5--dots {
      height: 495px;
      width: auto;
      left: -13%;
  }

  .twofixed .illustrate.Screen5-img {
      height: 495px;
      width: auto;
  }

  .twofixed .Screen5--w1 {
      left: 0;
  }

  .twofixed .Screen5--decription {
      padding: 0 0 0 11.1%;
      min-width: 100%;
      box-sizing: initial;
  }

  .twofixed .back-color::after {
      left: auto;
  }

  .twofixed .back-color::before {
      left: 27px;
  }

  .twofixed .main-header-second-h2.head2 {
      font: normal normal 600 24px/24px 'Poppins', sans-serif;
  }

  .twofixed .Screen4 {
      overflow: hidden;
  }

  .twofixed .Sreen5--h3 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
      padding: 13px 0 0 3%;
      letter-spacing: -0.9px;
  }

  .twofixed .Screen5--description--content {
      padding: 68px 0 0 0;
      max-width: 92%;
  }

  .twofixed .Screen5--strong {
      font: normal normal 700 22px/36px 'Poppins', sans-serif;
      padding: 0 0 0 18%;
  }

  .twofixed .Screen5--strong.S5 {
      width: 63%;
      padding: 0 0 0 14%;
  }

  .twofixed .Screen5--p {
      font: normal normal 400 18px/24px 'Poppins', sans-serif;
      padding: 0 0 28px 18%;
  }

  .twofixed .Screen5--p.S5 {
      width: 100%;
      padding: 0 0 28px 14%;
  }

  .twofixed .main-header-dots.dots {
      left: 29.8%;
      position: relative;
      width: 473px;
      height: 451px;
  }

  .twofixed .Screen5--button-group {
      margin: 33px 0 0 6.2%;
  }

  .twofixed .window1--button-group.Screen5--button-group {
      margin: 10px 0 0 6.2%;
  }

  .twofixed .window1--button-group.P1-S2.X1600 {
      margin: 26px auto 0 0;
      width: 90.3%;
      min-width: 90.3%;
  }

  .twofixed .Screen5--button-group.S6 {
      max-width: 100%;
      width: 74.5%;
      margin: 35px 0 0 6.8%;
      justify-content: space-between;
  }

  .twofixed .first-col-img {
      width: 473px;
      height: 451px;
  }

  .twofixed .Screen6 {
      margin: 290px 0 0;
  }

  .twofixed .window2.S6 {
      max-width: 40%;
      flex: 0 0 40%;
  }

  .twofixed .dots.Screen5--dots.Screen6--dots {
      height: 495px;
      width: auto;
      left: 6%;
  }

  .twofixed .illustrate.Screen6-img {
      height: 495px;
      width: auto;
  }

  .twofixed .Screen5--description--content.S6c {
      max-width: 100%;
      padding: 78px 0 0;
  }

  .twofixed .Screen5--strong.P1-S6 {
      padding: 0 0 0 17%;
  }

  .twofixed .Screen5--p.P1-S6 {
      padding: 0 0 28px 17%;
  }

  .twofixed .Screen5--p.P1-S6::after {
      left: 7%;
  }

  .twofixed .Screen7 {
      margin: 169px 0 0;
      padding: 129px 0 0;
  }

  .twofixed .Screen7--h2 {
      letter-spacing: -1.4px;
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
  }

  .twofixed .Screen7--p {
      letter-spacing: -0.95px;
      font: normal normal 400 22px/40px 'Poppins', sans-serif;
      max-width: 962px;
      margin: 87px auto 89px;
      left: 0;
  }

  .twofixed .sectors.S7 {
      max-width: 772px;
      right: 0;
  }

  .twofixed .sector.normal.main-p.center.S7 {
      margin: -21px auto 32px;
      max-width: 100%;
      font: normal normal 500 19px/19px 'Poppins', sans-serif;
      letter-spacing: -0.9px;
      background-position: center center;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      text-indent: 0;
      padding: 146px 0 15px;
  }

  .twofixed .back-color.S7 {
      padding-bottom: 34px;
  }

  .twofixed .window1--description--button.S7 {
      font: normal normal 500 24px/35px 'Poppins', sans-serif;
      margin: 6px 0 36px;
  }

  .twofixed .Screen5--button-group.S7 {
      margin: auto;
      max-width: 570px;
  }

  .twofixed .Screen7--h2.S8 {
      font: normal normal 700 40px/60px 'Poppins', sans-serif;
      padding: 186px 0 0 0;
      letter-spacing: -1.1px;
  }

  .twofixed .Screen9 {
      padding: 65px 0 30px 19px;
      display: flex;
      justify-content: space-between;
  }

  .twofixed .Screen5--p.S5::after {
      left: 5.6%;
  }

  .twofixed .top-horizon-menu {
      width: 1050px;
  }

  .twofixed .top-horizon-menu {
      width: 1050px;
  }

  .main-page .top-horizon-menu,
  .twofixed .top-horizon-menu.X1200-1600 {
      width: 1180px;
  }

  .twofixed .Header--logo.X1024-1600 {
      left: 30px;
  }
}

@media (max-width: 1100px) and (min-width: 1070px) {
  .twofixed .main-header-second {
      padding: 0.7% 4%;
  }

  .twofixed .normal.main-p.center.S4 {
      margin-left: 30px;
      margin-right: 30px;
  }
}

@media (max-width: 1600px) and (min-width: 1070px) {
  .twofixed .normal.main-p.center.S4 {
      max-width: 1000px;
  }
}

@media (min-width: 1601px) and (max-width: 1820px) {
  .twofixed .window1-h.X12000 {
      min-width: 727px;
      font-size: 81px;
  }

  .twofixed .Screen5--w1.S6 {
      left: 5%;
  }

  .twofixed .main-header-second.S3 {
      padding: 0 1.8% 0 1.8%;
      margin: 0 auto auto;
      width: 520px;
  }

  .twofixed .Screen5--description--content.S6c {
      overflow: hidden;
  }

  .twofixed .Screen5.S5 {
      width: 1200px;
      margin: 182px auto 0 auto;
  }

  .twofixed .Screen5--description--content {
      max-width: 100%;
  }

  .twofixed .dots.Screen5--dots {
      left: 6.9%;
  }

  .twofixed .dots.Screen5--dots::after {
      right: -12%;
  }

  .twofixed .Screen5--p.S5::after {
      left: 6.7%;
  }

  .twofixed .Screen5--p.S5:last-child::after {
      left: 6.5%;
  }

  .twofixed .window1.Screen5--w1 {
      left: 0;
  }
}

@media (min-width: 1199px) and (max-width: 1920px) {
  .twofixed .Screen7 {
      padding-bottom: 67px;
  }

  .twofixed .Screen7--h2.S8.X1200 {
      padding: 120px 0 0;
  }
}

@media (min-width: 1600px) {
  .twofixed .Screen5--button-group.S2 {
      min-width: 596px;
  }

  .twofixed .Screen5--button-group.X1600 {
      min-width: 231px;
  }

  .twofixed .window1--button-group.Screen5--button-group {
      min-width: 526px;
  }

  .twofixed .main-p.X1600 {
      min-width: 520px;
      width: 520px;
  }

  .twofixed .window1--description.S1 {
      margin-right: 99px;
  }

  .twofixed .window1--description {
      margin-right: 40px;
  }

  .main-page .dots.Screen5--dots.Screen6--dots:after {
      top: -124px;
      left: -49px;
  }
}

@media (max-width: 1919px) and (min-width: 1070px) {
  .twofixed .window1.P2.S2-P2 {
      padding: 4px 0 0 2%;
      flex: 0 0 50%;
      max-width: 50%;
  }
}

@media (min-width: 1920px) {
  .twofixed .Screen7--h2.why {
      padding: 157px 0 70px;
  }

  .twofixed .Screen7.S7 {
      padding: 0;
  }

  .twofixed .Screen7--p {
      margin-top: 11px;
  }
}

.twofixed .chevron {
  margin: 12vh auto 3vh;
}

.twofixed .Header {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  padding: 0;
}

.First_Screen {
  min-height: 560px;
}

.twofixed .First_Screen {
  height: 93vh;
  max-height: 1130px;
  min-height: 830px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.twofixed.page2 .First_Screen,
.twofixed.page3 .First_Screen {
  height: 94vh;
}

.twofixed.page5 .First_Screen {
  height: 100vh;
}

.twofixed .Header--logo {
  margin-top: 0;
  position: absolute;
  top: 30px;
  left: 60px;
}

.twofixed .window1--button-group.Screen5--button-group.S7 {
  justify-content: space-between;
}

/*2 page start*/

.twofixed .dots {
  position: relative;
}

.twofixed .center {
  text-align: center;
}

.twofixed .Screen5--button-group.s7 {
  max-width: 800px;
}

.twofixed .Header--logo {
  flex-grow: 1;
  margin-top: -1px;
  z-index: 1;
}

.twofixed .logo-link:hover {
  cursor: pointer;
}

.twofixed .Header--logo--img {
  height: 75px;
  width: 77px;
}

.twofixed .logo-texts {
  margin-left: 1px;
  color: #232D53;
}

.twofixed .logo-text {
  color: #232D53;
  font: normal normal 900 80px/48px 'Poppins', sans-serif;
  padding: 5px 4%;
  letter-spacing: 2.2px;
}

.twofixed .main-top-nav {
  flex-grow: 3;
}

.twofixed .illustrate {
  width: 680px;
  height: 572px;
}

.page2.twofixed .illustrate,
.page3.twofixed .illustrate {
  border-radius: 26px;
}

.twofixed .window1 {
  padding: 43px 15% 20px 12.8%;
  flex: 0 0 60%;
}

.twofixed .window1-h {
  font: normal normal 600 84px/111px 'Poppins', sans-serif;
  letter-spacing: -2.3px;
  margin: 0 0 15px;
  color: #232D53;
}

.twofixed .window1-descript {
  font: normal normal 500 26px/52px 'Poppins', sans-serif;
  letter-spacing: -1.45px;
  padding: 0 22% 0 0;
  margin-left: -0.1%;
  color: #696871;
}

.twofixed .window1--description--button {
  font: normal normal 500 32px/48px 'Poppins', sans-serif;
  color: #232D53;
  letter-spacing: -1.3px;
  margin-bottom: 16px;

}

.twofixed .window1--button-group {
  display: flex;
  justify-content: space-between;
}

.twofixed .window1--button-group {
  margin: 107px 0 0 0;
}

.twofixed .window1--button-group {
  width: 77.7%;
}

.twofixed .invert {
  color: #232D53;
  background-color: #fff;
}

.twofixed .chevron {
  display: block;
  width: 100%;
  margin: 130px auto 0;
}

.twofixed .chevron--down {
  height: 9px;
  width: 22px;
  margin: auto;
  display: block;
}

.twofixed .main {
  margin: 25px 0 0 0;
  background-color: #F7F7FB;
}

.twofixed .main-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 216px 11% 295px 17.8%;
}

.twofixed .main-header-first.main-header-first-col {
  position: relative;
  display: flex;
  justify-content: center;
}

.twofixed .main-header-dots.dots::after {
  left: -154px;
  bottom: -43px;
  z-index: 0;
}

.twofixed .main-header-dots.dots {
  left: 2%;
  position: relative;
  width: 600px;
  height: 572px;
}

.twofixed .first-col-img {
  width: 600px;
  height: 572px;
  border-radius: 25px;
  margin-left: -142px;
  position: relative;
  left: 6.2%;
}

.twofixed .main-header-second {
  padding: 1.8% 7.6% 0 9.85%;
}

.twofixed .main-header-second-h2.head2 {
  font: normal normal 600 24px/24px 'Poppins', sans-serif;
  color: #3680F6;
  margin: 11px 0 0;
  letter-spacing: -0.1px;
}

.twofixed .main-p {
  font: italic normal 400 38px/57px 'Poppins', sans-serif;
  margin: 35px 0 0;
  color: #10214F;
  text-indent: 1px;
}

.twofixed .main-post-link {
  font: normal normal 700 38px/57px 'Poppins', sans-serif;
  margin: 59px 0 0;
  display: block;
  position: relative;
  color: #10214F;
  max-width: max-content;
}

.twofixed .main-post-link:before {
  content: '';
  position: absolute;
  display: block;
  width: 49.1%;
  height: 4px;
  background-color: #FF6B0F;
  bottom: -1px;
  left: 34.9%;
}

.twofixed .Screen4 {
  padding: 94px 0 0;
  position: relative;
}

.twofixed .normal.main-p {
  font-style: normal;
  margin: 39px auto 0;
  max-width: 64%;
  line-height: 81px;
  letter-spacing: -1.2px;
}

.twofixed .main-post-link.frame4 {
  margin: 11px auto 0;
  letter-spacing: -1.6px;
  max-width: 300px;
}

.twofixed .main-post-link.frame4::before {
  height: 2px;
  bottom: -31px;
  width: 91.8%;
  left: 6.2%;
}

.twofixed .back-color {
  background-color: #F7F7FB;
  padding-bottom: 88px;
  margin-top: -10px;
}

.twofixed .main-p.cover {
  font-size: 32px;
  margin-top: 126px;
  padding-top: 130px;
  letter-spacing: -1.1px;
  text-indent: -8px;
}

.twofixed .sectors {
  display: flex;
  max-width: 1285px;
  margin: auto;
}

.twofixed .sector {
  font: italic normal 500 28px/42px 'Poppins', sans-serif;
}

.twofixed .sector.normal {
  margin: auto;
  padding: 125px 0 0;
  letter-spacing: -1.2px;
  position: relative;
}

.twofixed .Screen4--all.center {
  margin: 40px 0 0;
}

.twofixed .selectors--all {
  font: normal normal 900 16px/25px 'Poppins', sans-serif;
  color: #3680F6;
  position: relative;
  text-indent: -28px;
  display: block;
  max-width: 165px;
  margin: auto;
  letter-spacing: -0.2px;
}

.twofixed .Screen5 {
  display: flex;
  position: relative;
  margin: 182px auto 0;
  justify-content: space-between;
}

.twofixed .Screen5--w1 {
  padding: 11px 0 0;
  position: relative;
  left: 13.6%;
  max-width: 50%;
}

.twofixed .Screen5--h2.head2 {
  text-align: left;
  letter-spacing: 0;
  padding: 0 0 0 3%;
}

.twofixed .Screen5--description--content {
  padding: 30px 0 0 0;
  max-width: 73%;
}

.twofixed .Screen5--strong {
  font: normal normal 700 26px/36px 'Poppins', sans-serif;
  color: #1D293F;
  padding: 0 0 0 12.5%;
  display: block;
  position: relative;
}

.twofixed .Screen5--p {
  font: normal normal 400 22px/36px 'Poppins', sans-serif;
  color: #7E7F7E;
  padding: 1px 0 13px 12.5%;
  position: relative;
}

.twofixed .Screen5--strong::after, .twofixed .Screen5--p::after {
  content: '';
  display: block;
  position: absolute;
}

.twofixed .Screen5--strong::after {
  top: 1px;
  left: 4%;
  background-color: #3680F6;
  height: 34px;
  width: 34px;
  border-radius: 16px;
  z-index: 1;
  border: solid 2px #fff;
}

.twofixed .Screen5--p::after {
  top: -7px;
  left: 6.2%;
  background-color: #1D293F;
  height: 170%;
  width: 2px;
}

.twofixed .Screen5--p:last-child::after {
  content: none;
}

.twofixed .Sreen5--h3 {
  font: normal normal 600 50px/70px 'Poppins', sans-serif;
  color: #10214F;
  padding: 18px 0 0 3%;
  letter-spacing: -1.3px;
}

.twofixed .Screen5--button-group {
  max-width: 55.4%;
  margin: 42px 0 0 4.8%;
  justify-content: space-between;
}

.twofixed .login.Screen5--button--text {
  font: normal normal 500 16px/25px 'Poppins', sans-serif;
}

.twofixed .illustrate.Screen5-img {
  height: 654px;
  width: 600px;
}

.twofixed .dots.Screen5--dots::after {
  left: auto;
  right: -8%;
  bottom: -45px;
}

.twofixed .dots.Screen5--dots {
  left: -13.1%;
  top: 9px;
  width: 600px;
  height: 650px;
}

.twofixed .Screen6 {
  display: flex;
  position: relative;
  margin: 436px auto 217px;
  justify-content: space-between;
}

.twofixed .dots.Screen6--dots {
  left: 3.7%;
}

.twofixed .Screen6--w2.window1 {
  padding: 24px 0 0 1.25%;
  max-width: 50%;
}

.twofixed .dots.Screen5--dots.Screen6--dots::after {
  right: auto;
  left: -50px;
  bottom: auto;
  top: -120px;
}

.twofixed .Screen5--description--content.S6c {
  max-width: 80%;
  padding-top: 29px;
}

.twofixed .Screen5--button-group.S6bg {
  max-width: 59.4%;
}

.twofixed .Screen7.P2-S7-remade {
  position: relative;
  background-image: none;
  padding: 92px 0 24px 9.2%;
  overflow: hidden;
  max-width: 1910px;
  margin: auto;
}

.twofixed .common-info {
  background-color: #10214F;
}

.common-info {
  position: relative;
  overflow: hidden;
}

.common-info::before {
  content: '';
  display: block;
  position: absolute;
  height: 1008px;
  width: 1008px;
  border-radius: 504px;
  background-size: contain;
  bottom: -60.7%;
  left: 37.6%;
  background-color: #06113e;
}

.twofixed .Screen7--h2 {
  color: #fff;
  text-align: center;
  letter-spacing: -1.4px;
  font: normal normal 600 60px/60px 'Poppins', sans-serif;
}

.twofixed .Screen7--h2.P2-S7-remade {
  color: #fff;
  text-align: left;
  font: normal normal 600 40px/60px 'Poppins', sans-serif;
}

.twofixed .Screen7--p {
  letter-spacing: -1.15px;
  color: #fff;
  text-align: center;
  font: normal normal 400 28px/63px 'Poppins', sans-serif;
  max-width: 1245px;
  margin: 84px auto 89px;
  position: relative;
  left: -0.15%;
}

.twofixed .Screen7--p.P2-S7-remade {
  position: relative;
  font: normal normal 600 24px/56px 'Poppins', sans-serif;
  text-align: left;
  max-width: 1149px;
  margin: 16px auto 89px 0;
  letter-spacing: 0;
}

.Screen7--p.P2-S7-remade::after {
  content: '';
  position: absolute;
  width: 111.4%;
  height: 1px;
  background-color: #EAE9F2;
  left: 0;
  bottom: -32px;
  opacity: .4;
}

.twofixed .back-color.S7 {
  background-color: transparent;
  padding-bottom: 141px;
}

.twofixed .back-color.S7.P2-S7-remade {
  padding-bottom: 0;
  margin-top: 0;
}

.twofixed .main-p.S7 {
  color: #fff;
}

.twofixed .sectors.S7 {
  max-width: 1335px;
  position: relative;
  right: 1.7%;
  justify-content: center;
}

.twofixed .sectors.S7.P2-S7-remade {
  max-width: 1363px;
  right: 0;
  margin: 0;
  justify-content: space-between;
  flex-wrap: wrap;

}

.twofixed .sector.S7 {
  margin: auto;
  padding: 108px 0 0;
  letter-spacing: 0.2px;
}

.twofixed .sector.normal.center.S7 {
  font: normal normal 700 22px/27px 'Open Sans', sans-serif;
}

.twofixed .info-graph {
  margin: 0;
  padding: 70px 0 50px;
  background-position: 0 8px;
  text-align: left;
}

.twofixed .dot-list {
  margin: 20px 0 0 4.6%;
  padding: 0;
  max-width: 422px;
  opacity: .8;
}

.twofixed .dots-item {
  font: normal normal 600 20px/32px 'Poppins', sans-serif;
  color: #fff;
  list-style-type: none;
  position: relative;
}

.item.h3-P4::before,
.twofixed .dots-item::before {
  content: '';
  position: absolute;
  display: block;
  left: -20px;
  top: 12px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #3874FF;
}

.twofixed .sector.normal.one.S7 {
  background: url('../../Images/connection.svg') 52.2% 26px no-repeat;
}

.twofixed .sector.normal.main-p.S7.P2-S7-remade {
  background-position: 0 42%;
}

.twofixed .sector.normal.two.S7 {
  background: url('../../Images/opportunity.svg') 51.1% 33px no-repeat;
  position: relative;
  left: -0.65%;
}

.twofixed .sector.normal.three.S7 {
  background: url('../../Images/win.svg') 43.8% 30px no-repeat;
  position: relative;
  left: -2.2%;
}

.twofixed .sector.normal.four.S7 {
  background: url('../../Images/trust.svg') 36% 29px no-repeat;
}

.twofixed .sector.normal.COMMUNICATION.S7 {
  background: url('../../Images/communication.svg') 36% 29px no-repeat;
}

.twofixed .sector.normal.five.S7 {
  background: url('../../Images/security.svg') 54.4% 25px no-repeat;
}

.twofixed .sector.normal.five.supp.S7 {
  background: url('../../Images/support.svg') 12.4% 37px no-repeat;
}

.twofixed .sector.normal.five.P3-S7-remade.S7 {
  background: url('../../Images/win.svg') 12.4% 37px no-repeat;
}

/*Page3*/
.twofixed .sector.normal.COMMUNICATION.S7.exchange-P3 {
  background: url('../../Images/exchange.svg') 12.4% 37px no-repeat;
}

.twofixed .sector.normal.two.S7.communication-P3 {
  background: url('../../Images/communication.svg') 12.4% 37px no-repeat;
}

.twofixed .sector.normal.five.supp.S7.winwin-P3 {
  background: url('../../Images/win.svg') 12.4% 37px no-repeat;
}

.twofixed .Screen5--button-group.S7 {
  max-width: 870px;
  margin: auto;
  padding-bottom: 73px;
}

.twofixed .window1--description--button.S7 {
  font: normal normal 500 36px/55px 'Poppins', sans-serif;
  color: #fff;
  padding: 7px 0 0;
  text-align: center;
  letter-spacing: -1.5px;
  margin-top: -2px;
  margin-bottom: 16px;
}

.twofixed .window1--description.S7.S71 {
  margin-left: 9px;
}

.twofixed .window1--description--button.S7.S72 {
  position: relative;
  left: 3.2%;
  top: -1px;
}

.twofixed .window1--description.S7 {
  margin: 0 0 0 5px;
}

.twofixed .Screen7--h2.S8 {
  font: normal normal 600 40px/60px 'Poppins', sans-serif;
  padding: 120px 0 0 0;
  letter-spacing: -1.1px;
}

.twofixed .Screen9 {
  padding: 65px 0 45px;
  display: flex;
  justify-content: space-between;
}

.twofixed .idn-img {
  width: 50px;
  height: 60px;
  display: block;
  background: url('../../Images/idn.png') center no-repeat;
  background-size: contain;
  flex-grow: 1;
  margin-top: -15px;
  position: relative;
  left: -2.6%;
}

.twofixed .foot-ul {
  padding: 0;
  margin: 0;
}

.twofixed .foot-lists {
  display: flex;
  flex-grow: 5;
  justify-content: space-around;
  position: relative;
  right: 1.15%;
  max-width: 1631px;
}

.twofixed .foot-li.foot-li-head {
  color: #1D293F;
  font: normal normal 700 20px/30px 'Poppins', sans-serif;
  padding: 0 0 27px 0;
  letter-spacing: -1.1px;
}

.twofixed .foot-li {
  letter-spacing: -1.1px;
  list-style-type: none;
  font: normal normal 500 18px/27px 'Poppins', sans-serif;
  color: #1D293F;
}

.twofixed .in {
  margin: 31px 0 0 1%;
  width: 52px;
  height: 52px;
  display: block;
  background: url('../../Images/in.svg') center no-repeat;
  background-size: contain;
  border-radius: 26px;
}

.in:hover {
  background-color: #3680F6;
  transition: background-color, .7s;
}

.twofixed .opacity6 {
  opacity: 0.6;
}

@media (max-width: 1650px) and (min-width: 1070px) {
  .twofixed .main-header-dots.dots {
      width: 600px;
      height: 572px;
  }

  .twofixed .Screen5--w1,
  .twofixed .Screen5--w2 {
      padding: 0 1%;
      left: auto;
      flex: 0 0 50%;
  }

  .twofixed .dots.Screen5--dots {
      left: auto;
      margin: 0;
  }

  .twofixed .Screen5--button-group.S6bg {
      max-width: none;
  }

  /*page4*/
  .dots.P2-S2.topPhoto.P4-S2 {
      left: 1%;
  }

  /*page4*/
}

@media (max-width: 1850px) and (min-width: 1070px) {

  .twofixed .Screen5--button-group, .twofixed .Screen5--button-group.S6bg {
      max-width: none;
  }

  .twofixed .main-header-second, .twofixed .main-header-dots.dots {
      padding: 0 1%;
  }

  .twofixed .dots {
      left: 0;
  }

  /*Page4*/
  .two-explain.Screen2.P2-S3, .two-explain.Screen2.max-width1600 {
      max-width: 84%;
  }

  .dots.P2-S2.topPhoto.P4-S2 {
      left: 0;
  }
}

/* page2 */
.twofixed .window1.P2 {
  padding: 0 0 0 12.75%;
  margin-top: -7px;
}

.twofixed .window1-descript.P2 {
  font: normal normal 300 48px/80px 'Poppins', sans-serif;
  color: #10214F;
  letter-spacing: -1px;
  margin-top: 17px;
  padding-right: 15.3%;
}

.twofixed .main-header-second-h2.P2 {
  text-transform: uppercase;
}

.twofixed .main-post-link.P2 {
  font: normal normal 300 48px/80px 'Poppins', sans-serif;
  display: inline-block;
  margin: auto;
}

.twofixed .main-post-link.P2.S2-P2 {
  font: normal normal 400 44px/80px 'Poppins', sans-serif;
}

.twofixed .main-post-link.P2:before {
  width: 100%;
  left: auto;
}

.twofixed .yellow-upper {
  color: #3680F6;
  letter-spacing: 0.5px;
}

.twofixed .window1--button-group.P2.P2-S2 {
  margin: 60px auto 0 7%;
  justify-content: space-between;
  width: 70%;
}

.twofixed .Screen4.P2 {
  margin: 60px 0 0;
  padding: 150px 0 120px;
  overflow-x: hidden;
}

.twofixed .main-post-link.frame4.P2 {
  font: normal normal 600 48px/80px 'Poppins', sans-serif;
  max-width: initial;
  display: block;
}

.twofixed .main-post-link.frame4.P2::before {
  content: none;
}

.twofixed .main-post-link.center.frame4.P2 {
  text-indent: 1.7%;
  margin-top: 44px;
  letter-spacing: -1.2px;
}

.twofixed .main-p.cover.P2.P2-S4 {
  margin: 36px auto 172px;
  padding-top: 0;
  max-width: 1021px;
  letter-spacing: 0;
  font: normal normal 400 36px/62px 'Poppins', sans-serif;
}

.twofixed .two-columns {
  column-count: 2;
  max-width: 1317px;
  margin: auto;
  column-gap: 100px;
}

.twofixed .two-column--p {
  font: normal normal 400 26px/56px 'Poppins', sans-serif;
  margin: 0;
}

.twofixed.page2 .two-column--p {
  display: inline;
}

.twofixed .main-post-link.P2.third-screen {
  font: normal normal 400 26px/56px 'Poppins', sans-serif;
}

.twofixed .main-post-link.P2.third-screen:before {
  bottom: 3px;
  width: 105%;
}

.twofixed .main-post-link.P2.third-screen.third-line {
  font: normal normal 400 36px/62px 'Poppins', sans-serif;
}

.twofixed .main-post-link.P2.third-screen.third-line:before {
  bottom: -60px;
  width: 62%;
  margin: auto;
  left: 0;
  right: 0;
}

.twofixed .Screen5.P2, .twofixed .Screen6.P2, .twofixed .Screen7-P2.P2 {
  max-width: 1625px;
  margin: 265px auto 340px;
}

.twofixed .Screen5.P2.P2-S72 {
  margin: 408px auto 214px;
}

.twofixed .Screen6.P2 {
  margin: 0 auto 340px;
}

.twofixed .Screen6.P2.P2-S7 {
  margin: 382px auto 0;
}

.twofixed .Screen5.P2.P2-S7 {
  margin: 433px auto 340px;
}

.twofixed .window1.P2.P2-S7 {
  padding: 4px 0 0 11.65%;
}

.twofixed .window1.P2.P2-S7.P2-S62 {
  padding: 4px 0 0 9.45%;
  margin-top: 20px;
}

.twofixed .dots.P2-S72 {
  left: -3.9%;
}

.twofixed .Screen7-P2.P2 {
  display: flex;
}

.twofixed .window2.P2, .twofixed .window1.P2 {
  justify-content: center;
  flex: 0 0 50%;
  max-width: 50%;
}

.twofixed .window1.P2 {
  padding: 4px 0 0 9.75%;
  flex: 0 0 40%;
  max-width: 40%;
}

.twofixed .P2-S6.left-dashed::before {
  left: 21%;
}

.twofixed .window1.P2.P2-S7.P2-S62::before {
  top: -30%;
  height: 156%;
  left: 20.6%;
}

.twofixed .Screen6.P2.P2-S7.P2-S5dashed::before {
  bottom: -175px;
}

.twofixed .window1.P2.P2-S6::before {
  top: -23%;
  height: 152%;
}

.twofixed .window1.P2.P2-S5 {
  padding: 4px 0 0 12.25%;
}

.twofixed .dots.P2::after, .twofixed .P2-S53::before {
  left: -44px;
}

.twofixed .P2-S53::before {
  bottom: -201px;
  top: auto;
  left: -10.3%;
  border-style: none;
  opacity: 0.3;
}

.twofixed .window2.P2 {
  justify-content: center;
  flex: 0 0 43%;
  margin: 43px 0 0;
}

.twofixed .window2.P2.P2-S5.left-dashed::before {
  height: 107%;
  top: 20%;
}

.twofixed .main-header-second-h2.head2.P2, .twofixed .window1-h.P2, .twofixed .window1-descript.P2 {
  padding: 0 0 0 12.8%;
}

.twofixed .main-header-second-h2.head2.P2.S2-P2 {
  padding: 0 0 0 8%;
  margin: 0;
}

.twofixed .window1-h.P2 {
  margin: 34px 0 36px;
  font: normal normal 600 73px/111px 'Poppins', sans-serif;
}

.twofixed .window1-h.P2::before {
  top: 0;
  bottom: 0;
  margin: auto;
}

.twofixed .window1-h.P2.P2-S7 {
  margin-bottom: 0;
  padding: 0 0 0 9.5%;
}

.twofixed .window1-h.P2.P2-S7, .twofixed .window1-descript.P2.P2-S72 {
  margin: 71px 0 9px;
}

.twofixed .window1-descript.P2.P2-S7 {
  margin: 0;
  padding: 35px 26.3% 35px 9.9%;;
}

.twofixed .window1-descript.P2 {
  font: normal normal 400 24px/36px 'Poppins', sans-serif;
  padding: 35px 19% 23px 12.8%;
  color: #696871;
  margin: 0;
}

.twofixed .window1-descript.P2.P2-S72 {
  line-height: 36px;
  padding: 24px 24.6% 28px 9.8%;
}

.twofixed .window1-descript.P2.P2-S72.beyond {
  padding: 35px 24.6% 35px 9.8%;
  margin: 0;
  line-height: 36px;
}

.twofixed .window1-descript.P2.S2-P2 {
  padding: 0 13% 12px 7.8%;
  font: normal normal 400 44px/80px 'Poppins', sans-serif;
  color: #10214F;
  letter-spacing: 1px;
  max-width: 608px;
}

.twofixed .dots.dots-P2.P2 {
  left: 3.3%;
}

.twofixed .dots-P2::after {
  width: 210px;
  height: 210px;
  bottom: -47px;
  background-image: url('../../Images/dots.svg');
}

.twofixed .illustrate.P2 {
  width: 520px;
  height: 654px;
}

.twofixed .window1-h.P2.S6-P2 {
  margin-top: 118px;
}

.twofixed .window1-h.P2.S6-P2.added-element-before {
  margin: 116px 0 36px;
}

.twofixed .window1-h.P2.S6-P2.added-element-before.P2-S6 {
  margin: 153px 0 2px;
}

.twofixed .window1-h.P2.S6-P2.P2-S7 {
  margin-top: 186px;
}

.twofixed .window1-descript.P2.S6-P2.S6-P22 {
  padding: 35px 19% 35px 12.5%;
  font: normal normal 400 22px/35px 'Poppins', sans-serif;
  letter-spacing: 0;
  margin: 0;
}

.twofixed .window1-descript.P2.S6-P2 {
  padding: 35px 15% 35px 12.8%;
  margin: 0;
}

/*5*/
.twofixed .Screen5.P2.P3-S51 {
  margin: 324px auto 340px;
}

.twofixed .window1-h.P2.beyond {
  padding: 0 0 0 9.3%;
}

@media (max-width: 1600px) and (min-width: 1070px) {
  .twofixed .First_Screen,
  .twofixed .Screen5.P2.P2-S5dashed,
  .twofixed .Screen6.P2.P2-S5dashed,
  .twofixed .Screen5.P2.P2-S7.P2-S5dashed,
  .twofixed .Screen6.P2.P2-S7.P2-S5dashed,
  .twofixed .Screen5.P2.P2-S72.P2-S53,
  .twofixed .Screen9.P1-S9.X1023 {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
  }

  .twofixed .Screen4.P2.P2-S4.back-color,
  .twofixed .Screen8, .twofixed .common-info {
      width: 100%;
  }

  .main-page .window2.Screen6--w2.Screen5--w1.S6 {
      top: -33px;
  }

  .twofixed .First_Screen {
      min-height: 705px;
  }

  .twofixed .Screen9.P1-S9.X1023 {
      padding: 65px 30px 35px 30px;
  }

  .twofixed .Screen4.P2 {
      margin: 63px 0;
      padding: 110px 0 103px;
      overflow-x: hidden;
  }

  .twofixed .Screen4.P2-S4::before, .twofixed .Screen4.P2-S4::after {
      top: 8.3%;
  }

  .twofixed .Screen4.P2-S4::before {
      left: 0;
  }

  .twofixed .Screen4.P2-S4::after {
      right: 0;
  }

  .twofixed .window1.P2 {
      padding: 4px 0 0 0;
      flex: 0 0 55%;
      max-width: 55%;
  }

  .twofixed .window1.P2.S2-P2 {
      max-width: 500px;
  }

  .twofixed .window1-descript.P2.S2-P2, .twofixed .main-post-link.P2.S2-P2 {
      font: normal normal 400 30px/60px 'Poppins', sans-serif;
  }

  .twofixed .yellow-upper {
      letter-spacing: -.7px;
  }

  .twofixed .window1-descript.P2.S2-P2 {
      padding: 0 14% 12px 5%;
      margin: 15px 0 0;
      letter-spacing: -0.8px;
  }

  .twofixed .window1, .twofixed .window2 {
      padding: 0 1%;
      flex: 0 0 47%;
  }

  .twofixed .main-post-link.center.frame4.P2 {
      font-size: 40px;
      text-indent: 0;
      line-height: 60px;
      margin-top: 51px;
  }

  .twofixed .main-p.cover.P2.P2-S4 {
      font-size: 30px;
      text-indent: 0;
      line-height: 60px;
      margin: 51px auto 125px;
      padding: 0;
      max-width: 860px;
  }

  .twofixed .two-columns {
      max-width: 1060px;
      margin: auto;
      grid-column-gap: 65px;
  }

  .twofixed .main-post-link.P2.third-screen {
      font: normal normal 400 18px/42px 'Poppins', sans-serif;
  }

  .twofixed .main-post-link.P2.third-screen.third-line {
      font-size: 30px;
  }

  .twofixed .main-post-link.P2.third-screen.third-line:before {
      bottom: -44px;
  }

  .twofixed .two-column--p {
      font: normal normal 400 18px/42px 'Poppins', sans-serif;
  }

  .twofixed .Screen5.P2, .twofixed .Screen6.P2, .twofixed .Screen7-P2.P2, .twofixed .Screen5.P2.P2-S7, .twofixed .Screen6.P2.P2-S7, .twofixed .Screen5.P2.P2-S72 {
      max-width: 1625px;
      margin: 0 auto 190px;
  }

  .twofixed .Screen5.P2.P2-S72 {
      margin: 0 auto 146px;
  }

  .twofixed .window1.P2.P2-S5 {
      padding: 0;
  }

  .twofixed .window2.P2, .twofixed .window1.P2 {
      justify-content: center;
      flex: 0 0 50%;
      max-width: 50%;
      box-sizing: border-box;
      padding: 0;
  }

  .twofixed .window1.P2.P2-S7 {
      padding: 4px 0 0 1.65%;
  }

  .twofixed .window2.P2.P2-S5.left-dashed::before {
      height: 105%;
      top: 24%;
      left: 1.8%;
  }

  .twofixed .Screen5.P2::before {
      width: 43.2%;
      left: 8%;
      bottom: -131px;
  }

  .twofixed .Screen5.P2.P2-S7.P2-S5dashed::before {
      bottom: -131px;
  }

  .twofixed .Screen6.P2.P2-S5dashed::before {
      width: 43.7%;
      left: 8%;
  }

  .twofixed .Screen6.P2.P2-S7.P2-S5dashed::before {
      bottom: -127px;
  }

  .twofixed .Screen6.P2.P2-S5dashed.S6::before {
      bottom: -125px;
  }

  .twofixed .window1.P2.P2-S6::before {
      top: -13%;
      height: 137%;
      left: 16.1%;
  }

  .twofixed .P2-S7.left-dashed::before {
      top: -15%;
      height: 153%;
  }

  .twofixed .window2.P2.P2-S7.left-dashed::before {
      top: -24%;
      height: 151%;
  }

  .twofixed .window1.P2.P2-S7.P2-S62::before {
      top: -17%;
      height: 141.1%;
      left: 16.3%;
  }

  .twofixed .window2.P2.P2-S72.left-dashed::before {
      top: -14%;
      height: 89%;
  }

  .twofixed .window2.P2.P2-S72.left-dashed::before {
      top: -22%;
      height: 101%;
  }

  .twofixed .dots.P2.S6-P2 {
      left: 0;
  }

  .twofixed .dots.P2.S6-P2 {
      left: 0;
      width: 393px;
      height: 493px;
      margin: 0 12.3% 0 auto;
  }

  .twofixed .window1-h.P2 {
      margin: 39px 0 29px;
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
      letter-spacing: -0.6px;
  }

  .twofixed .window1-h.P2.beyond {
      margin: 100px 0 46px;
      padding: 0 0 0 14.6%;
  }

  .twofixed .window1--button-group.P2.P2-S2 {
      margin: 25px auto 0 7%;
  }

  .twofixed .main-header-second-h2.head2.P2, .twofixed .window1-h.P2, .twofixed .window1-descript.P2 {
      padding: 0 0 0 14.8%;
  }

  .twofixed .window1-h.P2.S6-P2.added-element-before.P2-S6 {
      margin: 97px 0 29px;
  }

  .twofixed .window1-h.P2.P2-S7, .twofixed .window1-descript.P2.P2-S72 {
      margin: 56px 0 9px;
      padding: 0 0 0 15%;
      letter-spacing: -1.1px;
  }

  .twofixed .window1-descript.P2.P2-S72.beyond {
      padding: 35px 12.5% 35px 14.8%;
      margin: 0;
      line-height: 27px;
      letter-spacing: 0;
  }

  .twofixed .window1-descript.P2.P2-S7,
  .twofixed .window1-descript.P2.P2-S72.beyond {
      margin-left: -4px;
  }

  .twofixed .window1-h.P2.P2-S7.connect {
      margin: 48px 0 40px;
  }

  .twofixed .main-header-second-h2.head2.P2.S2-P2 {
      padding-left: 5%;
  }

  .twofixed .window1-h.P2.P2-S5::before {
      top: 40%;
      left: 2px;
  }

  .twofixed .window1-descript.P2.P2-S7 {
      padding: 35px 15% 35px 14.9%;
  }

  .twofixed .window1-descript.P2,
  .twofixed .window1-descript.P2.S6-P2.S6-P22 {
      font: normal normal 400 18px/24px 'Poppins', sans-serif;
      padding: 35px 13% 35px 15%;
      color: #696871;
      margin: 0;
      letter-spacing: -0.1px;
  }

  .twofixed .illustrate.P2-S2, .twofixed .dots.P2-S2 {
      width: 395px;
      height: 333px;
  }

  .twofixed .illustrate.P2, .twofixed .dots.P2-S5 {
      width: 393px;
      height: 493px;
      left: 0;
  }

  .twofixed .dots.odd {
      margin: 21px auto 0 75px;
  }

  .twofixed .dots {
      left: -11.8%;
  }

  .twofixed .dots.P2-S2 {
      left: 20.8%;
      top: -1.2%;
  }

  .twofixed .illustrate {
      border-radius: 10px;
  }

  .twofixed .window1-h.P2.S6-P2.P2-S5dashed {
      margin: 100px 0 36px;
  }

  .twofixed .window1.P2-S6 {
      margin: 3px 0 0;
  }

  .twofixed .window1.P2-S6,
  .twofixed .window1.P2.P2-S7.P2-S62 {
      padding: 4px 0 0 7.05%;
  }

  .twofixed .window1-descript.P2.S6-P2,
  .twofixed .window1-descript.P2.S6-P2.S6-P22 {
      padding: 35px 0 35px 15%;
      letter-spacing: 0;
      margin: 0;
  }

  .twofixed .window1-descript.P2.S6-P2::before,
  .twofixed .window1-descript.P2.S6-P2.S6-P22::before,
  .twofixed .rounded-frame::before {
      margin-top: -5px;
  }

  .twofixed .Screen7.P2-S7-remade {
      padding: 93px 6.5% 0;
      max-width: 1200px;
  }

  .twofixed .info-graph {
      padding: 77px 0 52px;
  }

  .twofixed .sector.normal.main-p.center.S7,
  .twofixed .sector.normal.five.supp.S7.winwin-P3 {
      margin: 0;
      background-position-y: 60%;
  }

  .twofixed .Screen7--p.P2-S7-remade {
      padding: 0;
      margin-bottom: 83px;
      max-width: 1010px;
  }

  .twofixed .sectors.S7.P2-S7-remade {
      max-width: 954px;
  }

  .twofixed .login.buttonIDN.back-slider.why-idn {
      width: 277px;
      height: 60px;
      line-height: 60px;
      font-size: 20px;
  }

  .twofixed .S7.S71.dot-list.Advisor {
      margin: 15px 0 0;
  }

  .common-info::before {
      width: 1083px;
      height: 1210px;
      left: 27%;
      bottom: -58.4%;
      border-radius: 50%;
  }


  .dots.Screen5--dots::after {
      right: -1.9%;
  }

  /*page4*/
  .visualtextinfo.max-width1600 {
      justify-content: space-evenly;
      max-width: 1030px;
      margin: auto;
  }

  /*page4*/
}


@media (max-width: 1199px) and (min-width: 1070px) {
  .twofixed .First_Screen,
  .twofixed .Screen5.P2.P2-S5dashed,
  .twofixed .Screen6.P2.P2-S5dashed,
  .twofixed .Screen5.P2.P2-S7.P2-S5dashed,
  .twofixed .Screen6.P2.P2-S7.P2-S5dashed,
  .twofixed .Screen5.P2.P2-S72.P2-S53,
  .twofixed .Screen9.P1-S9.X1023 {
      max-width: 1024px;
      margin-left: auto;
      margin-right: auto;
  }

  .twofixed .Screen4.P2.P2-S4.back-color,
  .twofixed .Screen8, .twofixed .common-info {
      width: 100%;
  }

  .twofixed .window1, .twofixed .window2 {
      padding: 0 1%;
      flex: 0 0 33%;
  }

  .main-page .window2.Screen6--w2.Screen5--w1.S6 {
      top: -46px;
  }

  .twofixed .dots.P2-S2 {
      left: -6.4%;
      top: 0;
  }

  .twofixed .Screen9.P1-S9.X1023 {
      padding-right: 30px;
      padding-left: 30px;
  }


  .twofixed .window1--button-group.P2.P2-S2 {
      flex-direction: initial;
  }

  .twofixed .window1-descript.P2.S2-P2 {
      padding: 0 15.2% 12px 5%;
  }

  .twofixed .Screen4.P2-S4::before, .twofixed .Screen4.P2-S4::after {
      height: 210px;
      width: 180px;
      top: 74px;
  }

  .twofixed .Screen4.P2-S4::before, .twofixed .Screen4.P2-S4::after {
      height: 210px;
      width: 180px;
      top: 74px;
  }

  .twofixed .Screen6.P2.P2-S5dashed.S6::before {
      width: 43%;
  }

  .twofixed .Screen6.P2.P2-S7.P2-S5dashed::before {
      bottom: -127px;
      width: 43%;
  }

  .twofixed .window2.P2.P2-S72.left-dashed::before {
      top: -23%;
  }

  .twofixed .main-post-link.center.frame4.P2 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
      width: auto;
  }

  .twofixed .main-p.cover.P2.P2-S4 {
      line-height: 57px;
  }

  .twofixed .two-columns {
      max-width: 877px;
      grid-column-gap: 60px;
  }

  .twofixed .two-column--p {
      max-width: 400px;
  }

  .twofixed .Screen4.P2.P2-S4.back-color {
      padding: 110px 0 86px;
  }

  .twofixed .window1-descript.P2 {
      margin-left: -4px;
  }

  .twofixed .window1-descript.P2.P2-S7,
  .twofixed .window1-descript.P2.P2-S72.beyond {
      margin-left: -6px;
  }

  .twofixed .window1-descript.P2.S6-P2 {
      margin-left: -4px;
  }

  .twofixed .window1-descript.P2.S6-P2.S6-P22 {
      margin-left: -3px;
  }

  .twofixed .window1-h.P2::before {
      left: -1px;
  }

  .twofixed .window2.P2.P2-S72.left-dashed::before {
      top: -22%;
      height: 102%;
  }

  .twofixed .foot-lists {
      padding-left: 20px;
  }

  .twofixed .Screen7.P2-S7-remade {
      max-width: 1024px;
      padding: 93px 5.9% 0;
  }

  .twofixed .sectors.S7.P2-S7-remade {
      max-width: 864px;
  }

  /*page5*/
  .page5.twofixed .grey {
      margin-top: 0;
      padding: 98px 0 0;
  }

  .page5.twofixed .Screen5--decription.S5.S52.P5-S6 {
      margin: 0 auto 0 9.6%;
  }

  .page5.twofixed .dots.Screen5--dots.Screen6--dots.S6.P5-S6 {
      margin: 60px 12% auto auto;
  }

  .page5 .Screen5--description--content.S6c.P5-S6 {
      padding: 16px 0 0;
  }

  .page5 .main-header-second-h2.Screen5--h2.head2.center.P1-S6.P5-S6 {
      margin-left: 21%;
  }

  .page5.twofixed .Screen5--p.P1-S6.P5-S6 {
      padding: 6% 0 6% 12.6%;
      width: 328px;
      margin: 0 0 0 6.8%;
  }

  .page5.twofixed .wishes {
      margin: 0 0 0 20%;
  }
}

.footA {
  font: normal normal 500 18px/27px 'Poppins', sans-serif;
  color: #1D293F;
  text-decoration: none;
}

.active {
  position: relative;
}

.main-top-nav .active::after {
  content: '';
  position: absolute;
  bottom: 28%;
  left: 0;
  right: 0;
  margin: auto;
  width: 65%;
  height: 3px;
  background-color: #FF6B0F;
}

/*3page*/
.twofixed .arrowsquare::before {
  background: url('../../Images/arrowsquare.svg') #fff center center no-repeat;
}

.twofixed .papartext::before {
  background: url('../../Images/papartext.svg') #fff center center no-repeat;
}

.twofixed .googleforms::before {
  background: url('../../Images/googleforms.svg') #fff center center no-repeat;
}

.twofixed .notificationdot::before {
  background: url('../../Images/notificationdot.svg') #fff center center no-repeat;
}

.twofixed .favorite::before {
  background: url('../../Images/favorite.svg') #fff center center no-repeat;
}

.twofixed .target::before {
  background: url('../../Images/target.svg') #fff center center no-repeat;
}

.twofixed .ipo::before {
  background: url('../../Images/ipo.svg') #fff center center no-repeat;
}

.twofixed .page3.window1-descript.P2.S2-P2 {
  padding-right: 11%;
  max-width: 808px;
}

.twofixed .page2.window1-descript.P2.S2-P2 {
  padding-right: 14%;
  max-width: 808px;
}

/*Page2 finish*/

/*Page1 start*/
.center {
  text-align: center;
}

.buttonIDN:hover {
  cursor: pointer;
}

.Screen5--button-group.s7 {
  max-width: 800px;
}

.added-element-before {
  position: relative;
}

.dots {
  position: relative;
  z-index: 0;
}

.dots::after,
.back-color::before,
.back-color::after,
.common-info::after {
  content: '';
  display: block;
  position: absolute;
  left: -50px;
  bottom: -38px;
  background-image: url("../../Images/dots.svg");
  background-size: contain;
  z-index: -1;
  width: 180px;
  height: 180px;
  background-repeat: no-repeat;
}

.dots.P1-S2::after {
  left: -37px;
  bottom: -37px;
  box-sizing: initial;
}

.center {
  text-align: center;
}

.Screen2.P1-S2,
.Screen5.S5,
.Screen6.S6,
.Screen9.P1-S9 {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

.Screen4,
.Screen3.main,
.Screen7.S7,
.Screen8 {
  width: 100%;
}

.Screen5--button-group.s7 {
  max-width: 800px;
}


.First_Screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 700px;
}

.Header {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  align-self: center;
  justify-content: center;
}

.Header--logo {
  margin-top: 0;
  position: absolute;
  top: 30px;
  left: 60px;
  z-index: 1;
}

.top-horizon-menu {
  width: 1600px;
  display: flex;
}

.main-top-nav {
  flex-grow: 1;
}

.main-top-nav-anchor {
  font: normal normal 500 22px/22px 'Poppins', sans-serif;
  letter-spacing: -0.2px;
  color: #232D53;
  text-decoration: none;
  margin: 0;
  padding: 40px;
}

.Header--logo {
  flex-grow: 1;
  margin-top: 0;
}

.logo-link {
  display: flex;
  width: 234px;
  height: 80px;
}

.Header--logo--img {
  height: 75px;
  width: 77px;
}

.main-top-nav {
  flex-grow: 3;
}

.main-top-nav {
  display: flex;
  padding: 58px 0 30px 10%;
  justify-content: flex-end;
}

.main-top-nav-anchor {
  margin: 9px 0 2.8%;
  font: normal normal 500 22px/22px 'Poppins', sans-serif;
  letter-spacing: -0.2px;
  color: #232D53;
  text-decoration: none;
}

.main-top-nav-anchor:hover {
  color: #3680F6;
  transition: color .7s;
  text-decoration: none;
}

.login-block {
  display: flex;
  justify-content: center;
  align-self: center;
  padding-left: 75px;
}

.Screen2 {
  justify-content: space-around;
  position: relative;
  flex-flow: row wrap;
  display: flex;
}

.illustrate {
  width: 680px;
  height: 572px;
  border-radius: 30px;
}

.window1 {
  padding: 2px 0 0 9.65%;
  flex: 0 0 41%;
  box-sizing: initial;
}

.window1-h {
  font: normal normal 600 84px/111px 'Poppins', sans-serif;
  letter-spacing: -2.3px;
  margin: -26px 0 0;
  color: #232D53;
}

.window1-descript {
  font: normal normal 500 26px/52px 'Poppins', sans-serif;
  letter-spacing: -1.45px;
  padding: 0 5% 0 0;
  margin: 15px 0 0 -0.35%;
  color: #696871;
}

.window1--description--button {
  font: normal normal 500 32px/48px 'Poppins', sans-serif;
  color: #232D53;
  letter-spacing: -1.3px;
  margin-bottom: 16px;
}

.buttonIDN.login {
  width: 161px;
  height: 52px;
  font: normal normal 500 16px/25px 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
  background-color: #3680F6;
  border-width: 0;
  border-radius: 37px;
  color: #fff;
  z-index: 1;
  text-align: center;
  display: block;
  line-height: 52px;
}

.login.buttonIDN.back-slider.top-login {
  height: 52px;
  width: 161px;
  font-size: 20px;
}

.buttonIDN:focus {
  outline-width: 0;
}

.buttonIDN.big {
  font: normal normal 500 20px/58px 'Poppins', sans-serif;
  width: 251px;
  height: 60px;
}

.buttonIDN.big.P1-S7 {
  width: 277px;
  z-index: 1;
}

.buttonIDN.white {
  background-color: #fff;
  color: #232D53;
  box-shadow: inset 0 0 1px #3680F6;
}

.login.buttonIDN.S8 {
  display: block;
  margin: 58px auto 0;
  height: 54px;
  width: 271px;
  z-index: 1;
  background-color: transparent;
}

.ant-btn-lg.login.S2.ant-btn-primary.P2-S7 {
  width: 277px;
}

.login.S2.invert.P2-S2 {
  border-width: 0;
  box-shadow: none;
  background: url('../../Images/down-arrow.svg') 74% center no-repeat;
  text-indent: -55px;
}

.window2 {
  justify-content: center;
  flex: 0 0 40%;
}

.dots {
  left: -3.3%;
}

.window1--button-group {
  display: flex;
  justify-content: space-between;
}

.window1--button-group {
  margin: 100px auto 0 0;
  width: 77.5%;
}

.invert {
  color: #232D53;
  background-color: #fff;
}

.chevron {
  display: block;
  width: 100%;
  margin: 71px auto 0;
}

.chevron--down {
  height: 9px;
  width: 22px;
  margin: auto;
  display: block;
}

.main {
  margin: 0;
  background-color: #F7F7FB;
}

.main-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 216px 11% 295px 17.8%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: initial;
}

.main-header-first.main-header-first-col {
  position: relative;
  display: flex;
  justify-content: center;
}

.main-header-dots.dots::after {
  left: -154px;
  bottom: -43px;
  z-index: 0;
}

.hands.main-header-dots.dots::after {
  z-index: -1;
}

.main-header-dots.dots {
  left: 2%;
  position: relative;
  width: 600px;
  height: 572px;
}

.first-col-img {
  width: 600px;
  height: 572px;
  border-radius: 25px;
  margin-left: -142px;
  position: relative;
  left: 6.2%;
}

.main-header-second {
  padding: 0 7.6% 0 9.85%;
  box-sizing: initial;
}

.main-header-second-h2.head2 {
  font: normal normal 600 24px/24px 'Poppins', sans-serif;
  color: #3680F6;
  margin: 0;
  letter-spacing: -0.1px;
}

.main-p {
  font: italic normal 400 38px/57px 'Poppins', sans-serif;
  margin: 35px 0 0;
  color: #10214F;
  text-indent: 1px;
}

.main-post-link {
  font: normal normal 700 38px/57px 'Poppins', sans-serif;
  margin: 59px 0 0;
  display: block;
  position: relative;
  color: #10214F;
  max-width: max-content;
}

.main-post-link:before {
  content: '';
  position: absolute;
  display: block;
  width: 49.1%;
  height: 4px;
  background-color: #FF6B0F;
  bottom: -1px;
  left: 34.9%;
}

.main-page .main-post-link:before {
  width: 58.1%;
  left: auto;
  right: 0;
}

.Screen4 {
  padding: 94px 0 0;
  position: relative;
}

.normal.main-p {
  font-style: normal;
  margin: 39px auto 0;
  max-width: 72%;
  line-height: 81px;
  letter-spacing: -1.2px;
}

.normal.main-p.center.S4 {
  max-width: 1220px;
}

.main-post-link.frame4 {
  margin: 11px auto 0;
  letter-spacing: -1.6px;
  max-width: 300px;
}

.main-post-link.frame4::before {
  height: 2px;
  bottom: -31px;
  width: 91.8%;
  left: 6.2%;
}

.back-color {
  background-color: #F7F7FB;
  padding-bottom: 88px;
  margin-top: -10px;
}

.main-p.cover {
  font-size: 32px;
  margin-top: 126px;
  padding-top: 130px;
  letter-spacing: -1.1px;
  text-indent: -8px;
}

.sectors {
  display: flex;
  max-width: 1285px;
  margin: auto;
}

.sector {
  font: italic normal 500 28px/42px 'Poppins', sans-serif;
}

.sector.normal {
  margin: auto;
  padding: 125px 0 0;
  letter-spacing: -1.2px;
  position: relative;
}

.sector.normal.one {
  background: url("../../Images/delivery-truck.svg") 52.6% 71px no-repeat;
  left: -2px;
}

.sector.normal.two {
  background: url("../../Images/flash.svg") 54.6% 64px no-repeat;
  left: -22px;
}

.sector.normal.three {
  background: url("../../Images/satellite-dish.svg") 51.7% 62px no-repeat;
}

.sector.normal.four {
  background: url("../../Images/building.svg") 50.2% 59px no-repeat;
  left: -5px;
}

.Screen4--all.center {
  margin: 40px 0 0;
}

.selectors--all {
  font: normal normal 900 16px/28px 'Poppins', sans-serif;
  color: #3680F6;
  position: relative;
  text-indent: -28px;
  display: block;
  max-width: 165px;
  margin: auto;
  letter-spacing: -0.2px;
  text-decoration: none;
}

.selectors--all::after {
  content: '';
  background: url("../../Images/right-chevron.svg") center center no-repeat;
  display: block;
  width: 10px;
  height: 16px;
  position: absolute;
  right: 4px;
  top: 5px;
}

.back-color {
  position: relative;
}

.back-color::before {
  top: -90px;
  left: 0;
  z-index: 0;
}

.Screen4.P2-S4::before {
  top: -4.9%;
  left: -0.3%;
}

.back-color::after {
  top: -90px;
  left: auto;
  right: 0;
  z-index: 0;
}

.Screen4.P2-S4::before,
.Screen4.P2-S4::after {
  top: .3%;
}

.Screen5 {
  display: flex;
  position: relative;
  margin: 182px auto 0;
  justify-content: space-between;
}

.Screen5--w1 {
  padding: 11px 0 0;
  position: relative;
  left: 13.6%;
}

.main-page .Screen5--w1 {
  padding: 0;
}

.window1.Screen5--w1 {
  flex: 0 0 50%;
  max-width: 50%;
}

.window2.Screen6--w2.Screen5--w1.S6 {
  max-width: 40%;
  flex: 0 0 40%;
  left: 0;
  top: -45px;
}

.Screen5--h2.head2 {
  text-align: left;
  letter-spacing: 0;
  padding: 0 0 0 3%;
  margin: -3px 0 14px;
}

.Screen5--description--content {
  padding: 52px 0 0 0;
  max-width: 77%;
}

.Screen5--strong {
  font: normal normal 700 26px/36px 'Poppins', sans-serif;
  color: #1D293F;
  padding: 0 0 0 11.8%;
  display: block;
  position: relative;
}

.Screen5--strong.P1-S6 {
  padding: 0 0 0 12.2%;
}

.Screen5--p {
  font: normal normal 400 22px/36px 'Poppins', sans-serif;
  color: #7E7F7E;
  padding: 1px 0 36px 11.7%;
  margin: 0;
  position: relative;
}

.Screen5--p.P5-S6::before,
.Screen5--strong::after,
.Screen5--p::after {
  content: '';
  display: block;
  position: absolute;
}

.Screen5--p.P5-S6::before,
.Screen5--strong::after {
  top: 1px;
  left: 4%;
  background-color: #3680F6;
  height: 32px;
  width: 32px;
  border-radius: 20px;
  z-index: 1;
  border: solid 2px #fff;
}

.Screen5--p::after {
  top: -7px;
  left: 6.5%;
  background-color: #1D293F;
  height: 155%;
  width: 2px;
}

.Screen5--p.S5::after {
  left: 6.25%;
}

.Screen5--p.P1-S6::after {
  left: 6.5%;
}

.Screen5--p:last-child::after {
  background-color: #fff;
  width: 6px;
  left: 5.5%;
  height: 100%;
}

.Screen5--p.S5:last-child::after {
  left: 8.5%;
}

.Sreen5--h3 {
  font: normal normal 600 50px/70px 'Poppins', sans-serif;
  color: #10214F;
  padding: 18px 0 0 3%;
  letter-spacing: -1.3px;
  margin: 0;
}

.Screen5--button-group {
  max-width: 55.3%;
  margin: 19px 0 0 4.8%;
  justify-content: space-between;
}

.login.Screen5--button--text {
  font: normal normal 500 16px/25px 'Poppins', sans-serif;
}

.illustrate.Screen5-img {
  height: 654px;
  width: 600px;
}

.dots.Screen5--dots {
  left: -13.2%;
  top: 9px;
  width: 605px;
  height: 650px;
  margin: 0 auto 70px 0;
}

.main-page .dots.Screen5--dots {
  top: 0;
}

.Screen6 {
  display: flex;
  position: relative;
  margin: 436px auto 217px;
}

.window1.Screen6--w2.Screen5--w2.S6 {
  padding: 0;
  max-width: 60%;
  flex: 0 0 60%;
  display: flex;
  justify-content: center;
}

.Screen5--decription.S5.S52 {
  width: 63%;
}

.dots.Screen6--dots {
  left: 16.6%;
  margin: 45px 0 0 auto;
}

.main-page .dots.Screen6--dots {
  margin: 25px 0 0 auto;
}

.Screen6--w2.window1 {
  padding: 22px 0 0 1.35%;
  max-width: 46%;
  flex: 0 0 48%;
}

.dots.Screen5--dots.Screen6--dots::after {
  right: auto;
  left: -38px;
  bottom: auto;
  top: -38px;
}

.Screen5--description--content.S6c {
  max-width: 100%;
  padding: 52px 0 0;
}

.Screen5--button-group.S6bg {
  max-width: 63.2%;
  margin-top: 19px;
}

.Screen7 {
  padding: 161px 0 0;
  background: url("../../Images/why-idn.png") center no-repeat;
  background-size: cover;
}

.Screen7.P2-S7-remade {
  position: relative;
  background-image: none;
  padding: 92px 0 24px 9.2%;
  overflow: hidden;
}

.Screen7--h2 {
  margin: 0;
  color: #fff;
  text-align: center;
  letter-spacing: -1.4px;
  font: normal normal 600 60px/60px 'Poppins', sans-serif;
}

.Screen7--h2.P2-S7-remade {
  color: #fff;
  text-align: left;
  font: normal normal 600 40px/60px 'Poppins', sans-serif;
}

.Screen7--p {
  letter-spacing: -1.15px;
  color: #fff;
  text-align: center;
  font: normal normal 400 28px/63px 'Poppins', sans-serif;
  max-width: 1245px;
  margin: 84px auto 89px;
  position: relative;
  left: -0.15%;
}

.Screen7--p.P2-S7-remade {
  font: normal normal 600 24px/56px 'Poppins', sans-serif;
  text-align: left;
  max-width: initial;
  margin: 16px auto 89px;
  letter-spacing: 0;
}

.back-color.S7 {
  background-color: transparent;
  padding-bottom: 141px;
}

.back-color.S7.P1-S7::before, .back-color.S7.P1-S7::after {
  content: none;
}

.back-color.S7.P2-S7-remade {
  padding-bottom: 0;
  margin-top: 0;
}

.main-p.S7 {
  color: #fff;
}

.sectors.S7 {
  max-width: 1335px;
  position: relative;
  justify-content: center;
}

.sectors.S7.P2-S7-remade {
  max-width: 1363px;
  right: 0;
  margin: 0;
  justify-content: space-between;
  flex-wrap: wrap;

}

.sector.S7 {
  margin: auto;
  padding: 108px 0 0;
  letter-spacing: .2px;
}

.sector.normal.center.S7 {
  font: normal normal 700 22px/27px 'Open Sans', sans-serif;
}

.info-graph {
  margin: 0;
  padding: 70px 0 50px;
  background-position: 0 8px;
  text-align: left;
}

.dot-list {
  margin: 20px 0 0 4.6%;
  padding: 0;
  max-width: 422px;
}

.dots-item {
  font: normal normal 600 20px/32px 'Poppins', sans-serif;
  color: #fff;
  list-style-type: none;
  position: relative;
}

.dots-item::before {
  content: '';
  position: absolute;
  display: block;
  left: -20px;
  top: 14px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #3874FF;
}

.sector.normal.one.S7 {
  background: url("../../Images/connection.svg") 52.2% 26px no-repeat;
}

.sector.normal.main-p.S7.P2-S7-remade {
  background-position: 0 42%;
}

.sector.normal.two.S7 {
  background: url("../../Images/opportunity.svg") 51.1% 33px no-repeat;
  position: relative;
  left: -0.65%;
}

.sector.normal.three.S7 {
  background: url("../../Images/win.svg") 43.8% 30px no-repeat;
  position: relative;
  left: -2.2%;
}

.sector.normal.four.S7 {
  background: url("../../Images/trust.svg") 36% 29px no-repeat;
}

.sector.normal.five.S7 {
  background: url("../../Images/security.svg") 54.4% 25px no-repeat;
}

.sector.normal.five.P3-S7-remade.S7 {
  background: url("../../Images/win.svg") 12.4% 37px no-repeat;
}

.Screen5--button-group.S7 {
  max-width: 870px;
  margin: auto;
  padding-bottom: 73px;
}

.window1--description--button.S7 {
  font: normal normal 500 36px/55px 'Poppins', sans-serif;
  color: #fff;
  padding: 9px 0 1px;
  text-align: center;
  letter-spacing: -1.5px;
  margin-top: -2px;
  margin-bottom: 16px;
}

.window1--description--button.S7.Advisor {
  padding-right: 2%;
}

.window1--description--button.S7.Advisor.P2-S7 {
  font: normal normal 500 33px/50px 'Poppins', sans-serif;
  text-indent: -9px;
}

.S7.S71.dot-list.Advisor {
  margin: 36px 0;
  min-width: 422px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 35%;
}

.ant-btn-lg.login.S7 {
  width: 277px;
  font: normal normal 500 20px/53px 'Poppins', sans-serif;
  letter-spacing: 0.2px;
  text-indent: 2px;
}

.window1--description.S7 {
  margin: 0;
}

.Screen7--h2.S8 {
  font: normal normal 700 40px/60px 'Poppins', sans-serif;
  padding: 118px 0 0 0;
  letter-spacing: -1.1px;
}

.ant-btn-lg.login.S8 {
  display: block;
  margin: 58px auto 0;
  max-width: 268px;
}

.Screen9 {
  padding: 65px 0;
  display: flex;
  justify-content: space-between;
}

.idn-img {
  width: 50px;
  height: 60px;
  display: block;
  background: url("../../Images/idn.png") center no-repeat;
  background-size: contain;
  flex-grow: 1;
  margin-top: -15px;
  position: relative;
  left: -2.6%;
}

.foot-ul {
  padding: 0;
  margin: 0;
}

.foot-lists {
  display: flex;
  flex-grow: 5;
  justify-content: space-around;
  position: relative;
  right: 1.15%;
  max-width: 1631px;
}

.foot-li.foot-li-head {
  color: #1D293F;
  font: normal normal 700 20px/30px 'Poppins', sans-serif;
  padding: 0 0 27px 0;
  letter-spacing: -1.1px;
}

.foot-li,
foot-li > a {
  letter-spacing: -1.1px;
  list-style-type: none;
  font: normal normal 500 18px/27px 'Poppins', sans-serif;
  color: #1D293F;
}

.in {
  margin: 31px 0 0 1%;
  width: 52px;
  height: 52px;
  display: block;
  border-radius: 26px;
  background: url("../../Images/in.svg") center no-repeat;
  background-size: contain;
}

.opacity6 {
  opacity: 0.6;
}

.back-slider.buttonIDN::before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background-color: #3680F6;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -2;
}

.back-slider-two.back-slider.buttonIDN::before {
  left: 0;
}

.back-slider.buttonIDN.white::before {
  background-color: transparent;
}

.back-slider.buttonIDN.white::before {
  z-index: -1;
}

.back-slider.buttonIDN.white.decide::before {
  background-color: #fff;
}

.back-slider.buttonIDN::after {
  content: '';
  width: 220%;
  height: 307%;
  border-radius: 50%;
  background-color: #112b88;
  position: absolute;
  left: -204%;
  top: 10%;
  z-index: -1;
  transition: all .5s;
}

.back-slider.buttonIDN:hover::after {
  left: -15%;
  top: -60%;
}

.indent-bigger {
  text-indent: 0;
  transition: all .5s;
}

.indent-bigger:hover {
  text-indent: 15px;
}

.white {
  color: #232D53;
  transition: all .5s;
}

.buttonIDN.white:hover {
  color: #fff;
}

.buttonIDN.font-size-scale {
  font-size: 20px;
  transition: all .5s;
}

.buttonIDN.font-size-scale:hover {
  font-size: 15px;
}

.back-slider.back-slider-two.buttonIDN {
  background-color: transparent;
}

.back-slider-two.buttonIDN.white.border::before {
  border: solid 1px #3680F6;
  height: 96%;
  width: 96%;
  margin: auto;
}

.back-slider-two.buttonIDN.white.border {
  border: solid 1px transparent;
}

.back-slider-two.buttonIDN::before {
  left: 0;
  transition: all .5s;
}

.back-slider-two.buttonIDN:hover::before {
  background-color: transparent;
  left: -100%;
}

.back-slider.buttonIDN.why-idn.S2::after {
  color: #06113e;
}

.sizes-scale {
  transition: all .7s;
}

.sizes-scale:hover {
  transform: scale(0.9);
}

.buttonIDN.white.blue-back.back-slider {
  transition: all .7s;
}

.buttonIDN.white.blue-back.back-slider:hover {
  border-color: #3680F6;
}

.login.buttonIDN.back-slider.back-slider-two.indent-bigger.white.border::after,
.login.buttonIDN.P1-S5.white.back-slider::after,
.buttonIDN.white.blue-back.back-slider::after {
  background-color: #3680F6;
}

.buttonIDN.white.blue-back.back-slider:hover::after {
  background-color: #3680F6;
}

.login.buttonIDN.decide {
  border-color: transparent;
  height: 60px;
  line-height: 60px;
  box-sizing: initial;
}

.back-slider-two.buttonIDN.white.border {
  border-width: 0;
}

.buttonIDN.white.blue-back.back-slider, .login.buttonIDN.P1-S5 {
  transition: left, font-size .7s;
  border-width: 0;
  box-shadow: inset 0 0 1px #3680F6;
}

.login.buttonIDN.P1-S5:hover {
  border-color: #10214f;
  border-width: 0;
}

.login.buttonIDN.P1-S5 {
  box-sizing: border-box;
}

@media (max-width: 1199px) and (min-width: 1070px) {
  .Screen2.P1-S2,
  .Screen5.S5,
  .Screen6.S6,
  .Screen9.P1-S9 {
      max-width: 1024px;
      margin-right: auto;
      margin-left: auto;
  }

  .Screen4,
  .Screen3.main,
  .Screen7.S7,
  .Screen8 {
      width: 100%;
  }

  .Header {
      padding: 0;
      margin: 0;
  }

  .Header--logo {
      margin-left: -30px;
  }

  .idnlogo {
      height: 51px;
      width: 155px;
  }

  .main-top-nav {
      padding: 0 0 1%;
      flex-grow: 8;
  }

  .main-top-nav-anchor {
      margin: 9px 1.75% 4.8% 6%;
      font: normal normal 500 19px/22px 'Poppins', sans-serif;
      letter-spacing: -0.6px;
  }

  .login-block {
      justify-content: flex-end;
  }

  .buttonIDN.login {
      width: 161px;
      height: 52px;
      line-height: 52px;
  }

  .Screen2.P1-S2 {
      width: 950px;
      margin: 0 auto;
  }

  .window1-h {
      font: normal normal 600 49px/70px 'Poppins', sans-serif;
      letter-spacing: -1.9px;
  }

  .window1.Screen2.X12000 {
      max-width: 50%;
      flex: 0 0 50%;
      margin-top: 10px;
  }

  .window1, .window2 {
      padding: 0 1%;
      flex: 0 0 40%;
      max-width: 40%;
  }

  .window1--button-group.P1-S2 {
      width: 88%;
      flex-direction: row;
      margin: 0 auto 0 0;
  }


  .window1-descript {
      font: normal normal 500 21px/41px 'Poppins', sans-serif;
      padding: 0 1% 0 0;
      margin-left: -0.1%;
      letter-spacing: -0.8px;
  }

  .dots.Screen5--dots, .dots {
      left: 0;
      margin: 0 0 0 auto;
      width: 440px;
      height: 401px;
  }

  .dots.P1-S2 {
      left: 0;
      margin: 0;
      width: auto;
      height: auto;
  }

  .dots.Screen5--dots.S5 {
      margin: 0 0 0 auto;
      left: 0;
      height: 401px;
      width: 368px;
  }

  .dots.Screen6--dots {
      left: 15%;
      top: 23px;
  }

  .main-page .Screen6--w2 .dots.Screen5--dots.Screen6--dots.S6 {
      top: 10px;
  }

  .illustrate.Screen5-img.S5 {
      width: 368px;
      height: 401px;
      margin: 0 auto 0 0;
      display: block;
  }

  .dots.Screen5--dots::after {
      right: 8.5%;
      bottom: -41px;
  }

  .Screen5--strong {
      font: normal normal 700 22px/36px 'Poppins', sans-serif;
      padding: 0 0 0 15.4%;
  }

  .Screen5--p.P1-S6 {
      padding: 0 25.2% 27px 12.4%;
  }

  .Screen5--p {
      font: normal normal 400 18px/24px 'Poppins', sans-serif;
      padding: 1px 34% 24px 15.6%;
  }

  .Screen5--p.S5::after {
      top: -7px;
      left: 9%;
  }

  .Screen5--strong::after {
      top: 1px;
      left: 5.7%;
  }

  .Screen5--strong.P1-S6::after {
      left: -4px;
  }

  .Screen5--p.P1-S6::after {
      left: 2.4%;
  }

  .window1--button-group.Screen5--button-group {
      flex-direction: row;
      width: 95.5%;
      justify-content: start;
      margin: 20px 0 0 27px;
      max-width: 100%;
  }

  .Screen6 {
      margin: 154px auto 217px;
  }

  .main-header-second {
      padding: 0 0 0 5%;
      width: 77%;
  }

  .illustrate, .illustrate.Screen2, .illustrate.Screen5-img, .first-col-img, .main-header-dots.dots {
      width: 361px;
      height: 304px;
      margin: 0 0 0 auto;
  }

  .illustrate.Screen6-img {
      width: 392px;
      height: 431px;
  }

  .dots::after, .back-color::before, .back-color::after, .dots.P1-S2::after {
      width: 180px;
      height: 180px;
      bottom: -46px;
      left: -29px;
      background-image: url("../../Images/dots.svg");
  }

  .back-color::after {
      left: 73px;
  }

  .back-color::before {
      left: auto;
      right: 73px;
  }


  .main-header-dots.dots::after {
      left: -34px;
      bottom: -138px;
  }

  .first-col-img {
      margin-left: 0;
      position: static;
  }

  .window1--button-group {
      width: 100%;
      flex-direction: column;
  }

  .main-header {
      padding: 64px 1% 48px 1%;
  }

  .main-header-first, .main-header-second {
      padding: 0 50px 0 56px;
  }

  .main-header-second {
      padding: 0 45px 0 108px;
  }

  .main-header-second-h2.head2 {
      margin: 6px 0 0;
      letter-spacing: -0.1px;
  }

  .main-post-link.frame4::before {
      bottom: 6px;
      width: 46%;
      left: 27%;
  }

  .normal.main-p.S4 {
      font: normal normal 400 30px/60px 'Poppins', sans-serif;
      margin: 46px auto 0;
      max-width: 95%;
      letter-spacing: -1px;
      text-indent: 0;
  }

  .Screen5.S5 {
      margin: 80px auto 0;
      overflow: hidden;
  }

  .main-page .Screen5.S5.P1-S5 {
      margin: 90px auto 0;
  }

  .main-page .Screen6.S6.P1-S6 {
      margin: 75px auto 70px;
  }

  .main-header-second-h2.head2.Screen5--h2 {
      margin: 6px 0 0 16px;
  }

  .main-page .main-header-second-h2.head2.Screen5--h2 {
      margin-top: 0;
      margin-left: .9%;
  }

  .Screen5--h2.head2.center.P1-S6 {
      margin-top: 0;
  }

  .Sreen5--h3 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
      padding: 14px 4% 0 5.8%;
      letter-spacing: -0.9px;
  }

  .main-page .Sreen5--h3.S5 {
      padding-left: 3.5%;
  }

  .Screen5--description--content, .Screen5--description--content.S6c {
      max-width: 100%;
      padding: 76px 0 0 0;
  }

  .Screen5--description--content.S6c {
      padding: 50px 0 0 30px;
  }

  .main-p {
      font: italic normal 400 30px/47px 'Poppins', sans-serif;
      margin: 32px 0 0;
      text-indent: 1px;
  }

  .main-post-link {
      font: normal normal 700 30px/57px 'Poppins', sans-serif;
      margin: 41px 0 0;
      width: 104%;
  }

  .first-col-img {
      margin-left: -13px;
      width: auto;
      height: 400px;
  }

  .normal.main-p.P2 {
      margin: 93px auto 0;
      font: normal normal 400 26px/60px 'Poppins', sans-serif;
      max-width: 97%;
      letter-spacing: -0.9px;
  }

  .sectors.S4 {
      max-width: 72%;
      justify-content: space-between;
  }

  .Screen4--all.center {
      margin: 48px 0 0;
  }

  .sector.normal {
      font: normal normal 600 18px/62px 'Poppins', sans-serif;
  }

  .sector.normal.center.S7 {
      font: normal normal 700 18px/32px 'Open Sans', sans-serif;
      background-position-x: center;
      background-position-y: center;
      margin-top: 58px;
  }

  .sector.normal.center {
      left: auto;
      background-position-x: center;
      background-position-y: center;
      padding: 150px 0 0;
      margin: 0;
      max-width: 100%;
      right: auto;
      letter-spacing: -0.8px;
  }

  .main-post-link.frame4 {
      margin: 2px auto 0;
  }

  .Screen5--w1, .Screen5--w2 {
      padding: 0;
      left: auto;
      max-width: 100%;
  }

  .Screen5--w1, .Screen5--w2 {
      flex: 0 0 42%;
  }

  .Screen5--decription {
      margin: 0 0 0 11.3%;
      width: 100%;
  }

  .Screen5--decription.S5.S52 {
      width: 100%;
  }

  .Screen5--decription.S5.S52.P5-S6 {
      margin-right: auto;
  }

  .Screen5--decription.S51 {
      margin: 0 0 0 25px;
  }

  .window2.Screen6--w2.Screen5--w1.S6 {
      padding-top: 100px;
      margin: 0 0 0 auto;
  }

  .window1.Screen6--w2.Screen5--w2.S6 {
      padding-top: 124px;
  }

  .main-page .window1.Screen6--w2.Screen5--w2.S6 {
      padding-top: 40px;
  }

  .Screen6--w2.window1 {
      padding: 24px 2% 0 0;
      max-width: 100%;
  }

  .window1--description {
      margin: 0;
  }

  .window1--description--button {
      letter-spacing: -1.5px;
      font: normal normal 500 25px/48px 'Poppins', sans-serif;
      margin: 29px auto 12px;
  }

  .buttonIDN.login {
      width: 188px;
  }

  .back-slider.buttonIDN.indent-bigger {
      line-height: 50px;
  }

  .chevron {
      margin: 35px auto 0;
  }

  .Screen5--button-group.S7 {
      display: flex;
      flex-direction: row;
      max-width: 57%;
      margin: auto;
  }

  .Screen5--button-group.S6bg {
      max-width: none;
  }

  .window1--description--button.S7 {
      font: normal normal 500 24px/35px 'Poppins', sans-serif;
      padding: 0 0 24px;
  }

  .window1--button-group.Screen5--button-group.S6bg {
      margin: 17px 0 0 25px;
  }

  .Screen7 {
      padding: 130px 0 64px;
  }

  .Screen7--h2 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
  }

  .Screen7--p.S7 {
      font: normal normal 400 22px/40px 'Poppins', sans-serif;
      max-width: 100%;
      letter-spacing: -0.9px;
      margin: 87px auto 0;
      padding: 0 60px;
  }

  .S7 br {
      display: contents;
  }

  .Screen6.S6 {
      margin: 54px auto 60px;
  }

  .sectors.S7 {
      justify-content: space-around;
      max-width: 77%;
      right: auto;
  }

  .back-color.S7 {
      padding-bottom: 90px;
  }

  .login.buttonIDN.P1-S5 {
      line-height: 52px;
  }

  .Screen9.P1-S9 {
      max-width: 97.2%;
      margin: 0 2.9%;
      padding-bottom: 30px;
  }

  .idn-img {
      width: 67px;
      height: 58px;
      left: -49px;
      top: -3px;
  }

  .foot-lists {
      display: flex;
      flex-grow: 5;
      justify-content: space-between;
      right: 6px;
      max-width: 796px;
      top: -5px;
  }

  .Screen5--p.S5:after {
      left: 9%;
  }

  /*page4*/
  .two-explain.Screen2.P2-S3, .two-explain.Screen2.max-width1600 {
      max-width: 84%;
  }

  .page4 .dots.P2-S2.topPhoto.P4-S2 {
      left: 0;
  }

  .page4 .head3 {
      font-size: 30px;
      line-height: 40px;
  }

  .page4 .text-extending {
      font: normal normal 300 18px/24px 'Poppins', sans-serif;
  }

  .page4 .Screen7--h2.S8.P4-S8 {
      max-width: 1000px;
  }

  /*page4*/
}

@media (max-width: 1300px) and (min-width: 1070px) {

  /*page4*/
  .page4 .head3 {
      font-size: 30px;
      line-height: 40px;
  }

  .page4 .text-big.text-S2-P4.P4.S2 {
      font-size: 30px;
      line-height: 50px;
      margin: auto;
  }

  .page4 .text-big.text-S2-P4.P4.S2.center.width800 {
      max-width: 600px;
  }

  /*page4*/
}

@media (max-width: 1600px) and (min-width: 1070px) {
  .main-header-second-h2.head2.S3 {
      margin-top: -11px;
  }

  .login.buttonIDN.back-slider {
      height: 50px;
      width: 190px;
      font-size: 20px;
  }

  .login.buttonIDN.back-slider.white {
      font-size: 16px;
  }

  .main-header-second {
      padding: 0.7% 7.6% 0 12.5%;
      max-width: 410px;
  }

  .main-p.X1600 {
      max-width: 520px;
  }

  .window1--button-group {
      justify-content: initial;
  }

  .window1--description {
      margin-right: 40px;
  }

  .dots.Screen5--dots.Screen6--dots {
      top: 40px;
  }

  .window1.Screen6--w2.Screen5--w2.S6 {
      top: 40px;
  }

  .main-page .window1.Screen6--w2.Screen5--w2.S6 {
      margin-top: 97px;
  }

  .dots.Screen5--dots.Screen6--dots.S6 {
      height: 495px;
      width: auto;
      left: 18.6%;
      padding-top: 50px;
  }

  .main-page .dots.Screen5--dots.Screen6--dots.S6 {
      top: 55px;
  }

  /*page4*/
  .page4 .dots.P2-S2.topPhoto.P4-S2,
  .page4 .dots.P2-S2.topPhoto.P4-S2.P4-S3 {
      width: 91%;
  }

  .page4 .text-extending {
      font-size: 18px;
      line-height: 30px;
  }

  /*page4*/
  /*page5*/
  .page5 .yellow-upper.P5,
  .page5 .window1-descript.P2.S2-P2.page3.P5-S2,
  .page5 .main-post-link.P2.S2-P2 {
      font: normal normal 400 30px/60px 'Poppins', sans-serif
  }

  .page5 .dots.Screen6--dots.P5-S6 {
      height: 427px;
      left: 14%;
      margin: 42px auto auto;
  }

  .page5 .Screen5--decription.S5.S52.P5-S6 {
      margin: auto;
  }

  .page5 .Screen2.P5-S2 {
      margin: auto 0 15.7vh;
  }

  .page5 .text-big.text-S2-P4.P4.S2.P5-our-team.P5 {
      margin: 114px auto 36px;
  }

  .page5.twofixed .Screen7--h2.S8.P4-S8 {
      padding: 122px 0 21px;
  }

  .page5.twofixed .Screen8 {
      padding-bottom: 141px;
  }

  /*page5*/
}

@media (max-width: 1600px) and (min-width: 1070px) {
  .login.buttonIDN.decide,
  .login.buttonIDN {
      width: 240px;
      height: 50px;
      line-height: 50px;
  }

  .Screen5--button-group {
      margin: 33px 0 0 6.2%;
      width: 86.8%;
      max-width: 86.8%;
  }

  .Screen5--button-group.S6bg {
      margin: 16px 0 0 6.2%;
      width: 94.3%;
      max-width: 94.4%;
  }

  .login-block {
      padding-left: 40px;
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .Screen2.P1-S2,
  .Screen5.S5,
  .Screen6.S6,
  .Screen9.P1-S9 {
      max-width: 1200px;
      margin-right: auto;
      margin-left: auto;
  }

  .Screen4,
  .Screen3.main,
  .Screen7.S7,
  .Screen8 {
      width: 100%;
  }

  .Screen6.S6 {
      margin-top: 315px;
  }

  .Header {
      margin: 0 auto 0 0;
  }

  .idnlogo {
      width: 185px;
      height: 51px;
  }

  .main-top-nav {
      padding: 20px 0 0;
      position: relative;
  }

  .main-top-nav-anchor,
  .twofixed .main-top-nav-anchor {
      margin: 9px 0 4.8% 4.9%;
      letter-spacing: -0.8px;
      padding: 33px 0 20px;
      font: normal normal 500 18px/22px 'Poppins', sans-serif;
  }

  .main-top-nav .active::after {
      bottom: 7%;
      width: 100%;
  }

  .login-block {
      justify-content: flex-end;
      padding-right: 1.5%;
  }

  .buttonIDN.white.S2:hover {
      border-color: transparent;
  }

  .window1-h {
      font: normal normal 600 60px/80px 'Poppins', sans-serif;
      letter-spacing: -1.7px;
      margin: 3px 0 0;
  }

  .window1.Screen2.X1600 {
      flex: 0 0 48%;
      width: 48%;
      padding: 2px 0 0 3.35%;
      margin-top: -20px;
  }

  .window1--description--button {
      font: normal normal 500 24px/35px 'Poppins', sans-serif;
      margin: 37px 0 16px;
  }

  .window2.X1600 {
      flex: 0 0 33%;
      width: 33%;
  }

  .dots {
      left: -8.3%;
      position: relative;
      z-index: 0
  }

  .window1-descript {
      margin: 24px 0 0 0.3%;
      font: normal normal 500 22px/40px 'Poppins', sans-serif;
      letter-spacing: -1.2px;
      padding: 0 1% 0 0;
  }

  .illustrate.Screen2 {
      width: 396px;
      height: 334px;
      border-radius: 25px;
  }

  .chevron {
      margin-top: 28px;
  }

  .main-header {
      padding: 114px 0 295px 0;
      max-width: 90%;
      margin: auto;
  }

  .main-header-second {
      padding: 0.7% 7.6% 0 10%;
      box-sizing: initial;
  }

  .main-post-link {
      font: normal normal 700 38px/57px 'Poppins', sans-serif;
      margin: 59px 0 0;
  }

  .main-post-link {
      font: normal normal 700 30px/49px 'Poppins', sans-serif;
      margin: 44px 0 0;
  }

  .main-header.S3 {
      padding: 115px 0 164px;
      max-width: 1050px;
  }

  .main-p {
      font: italic normal 400 30px/47px 'Poppins', sans-serif;
      margin: 35px 0 0;
  }

  .normal.main-p {
      margin: 45px auto 0;
      max-width: 78%;
      font: normal normal 400 30px/60px 'Poppins', sans-serif;
      letter-spacing: -0.9px;
  }

  .normal.main-p.P2 {
      margin: 135px auto 0;
      max-width: 78%;
      font: normal normal 400 26px/60px 'Poppins', sans-serif;
      letter-spacing: -0.9px;
  }

  .sectors.S4 {
      max-width: 800px;
  }

  .sector.normal.main-p.center {
      margin: 12px auto 0;
      max-width: 100%;
      font: normal normal 500 17px/20px 'Poppins', sans-serif;
      letter-spacing: -0.9px;
      background-position-x: center;
      background-position-y: center;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      text-indent: -25px;
      padding: 160px 0 0;
  }

  .selectors--all {
      margin-top: 69px;
  }

  .dots.Screen5--dots {
      height: 495px;
      width: auto;
      left: -12%;
      top: 0;
  }

  .illustrate.Screen5-img {
      height: 495px;
      width: auto;
  }

  .Screen5--w1 {
      left: 0;
  }

  .Screen5--decription {
      padding: 0 0 0 9.3%;
      min-width: 100%;
      box-sizing: initial;
  }

  .Screen5--decription.S5.S52 {
      padding: 0 0 0 17%;
  }

  /*Page 5*/
  .Screen5--decription.S5.S52.P5-S6 {
      padding: 0;
  }

  /*Page 5*/
  .Screen5--h2.head2 {
      margin-bottom: 0;
  }

  .back-color::after {
      left: auto;
  }

  .main-header-second-h2.head2 {
      font: normal normal 600 24px/24px 'Poppins', sans-serif;
  }

  .Screen4 {
      overflow: hidden;
  }

  .Sreen5--h3 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
      padding: 13px 24% 0 4%;
      letter-spacing: -0.9px;
  }

  .Screen5--description--content {
      padding: 68px 0 0 0;
      max-width: 83%;
  }

  .Screen5--strong {
      font: normal normal 700 22px/36px 'Poppins', sans-serif;
      padding: 0 0 0 18%;
  }

  .Screen5--strong.S5 {
      width: 63%;
      padding: 0 0 0 14%;
  }

  .Screen5--p {
      font: normal normal 400 18px/24px 'Poppins', sans-serif;
      padding: 0 0 28px 18%;
  }

  .Screen5--p.S5 {
      width: 67%;
      padding: 0 0 28px 14%;
  }

  .main-header-dots.dots {
      left: 29.8%;
      position: relative;
      width: 473px;
      height: 451px;
  }

  .Screen5--button-group {
      margin: 33px 0 0 6.2%;
  }

  .window1--button-group.Screen5--button-group {
      margin: 10px 0 0 6.2%;
  }

  .window1--button-group.P1-S2.X1600 {
      margin: 26px auto 0 0;
      width: 90.3%;
      min-width: 90.3%;
  }

  .Screen5--button-group.S6 {
      max-width: 100%;
      width: 74.5%;
      margin: 35px 0 0 6.8%;
      justify-content: space-between;
  }

  .first-col-img {
      width: 473px;
      height: 451px;
  }

  .Screen6 {
      margin: 290px 0 0;
  }

  .window2.S6 {
      max-width: 40%;
      flex: 0 0 40%;
  }

  .dots.Screen5--dots.Screen6--dots {
      height: 495px;
      width: auto;
      left: 18.6%;
  }

  .illustrate.Screen6-img {
      height: 431px;
      width: 396px;
  }

  .Screen5--description--content.S6c {
      max-width: 100%;
      padding: 78px 0 0;
  }

  .Screen5--strong.P1-S6 {
      padding: 0 0 0 12.1%;
  }

  .Screen5--p.P1-S6 {
      padding: 0 46% 28px 12%;
  }

  .Screen5--p.P1-S6::after {
      left: 6.35%;
  }

  .Screen7 {
      margin: 169px 0 0;
      padding: 129px 0 0;
  }

  .Screen7--h2 {
      letter-spacing: -1.4px;
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
  }

  .Screen7--p {
      letter-spacing: -0.95px;
      font: normal normal 400 22px/40px 'Poppins', sans-serif;
      max-width: 962px;
      margin: 87px auto 89px;
      left: 0;
  }

  .Screen7--p br {
      display: contents;
  }

  .sectors.S7 {
      max-width: 772px;
      right: 0;
  }

  .sector.normal.main-p.center.S7 {
      margin: -21px auto 32px;
      max-width: 100%;
      font: normal normal 500 19px/19px 'Poppins', sans-serif;
      letter-spacing: -0.9px;
      background-position: center center;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      text-indent: 0;
      padding: 146px 0 15px;
  }

  .back-color.S7 {
      padding-bottom: 34px;
  }

  .window1--description--button.S7 {
      font: normal normal 500 24px/35px 'Poppins', sans-serif;
      margin: 6px 0 36px;
  }

  .Screen5--button-group.S7 {
      margin: auto;
      max-width: 570px;
  }

  .Screen7--h2.S8 {
      font: normal normal 700 40px/60px 'Poppins', sans-serif;
      padding: 186px 0 0 0;
      letter-spacing: -1.1px;
  }

  .Screen9 {
      padding: 65px 0 30px 19px;
      display: flex;
      justify-content: space-between;
  }

  .Screen5--p.S5::after {
      left: 8.05%;
  }

  .Screen5--p.S5:last-child::after {
      left: 7%;
  }

  .Header--logo.X1024-1600 {
      left: 30px;
  }

  .page4 .First_Screen .Screen2 {
      margin: 9vh 0 auto;
  }

  .twofixed.page4 .window1.P2.S2-P2 {
      max-width: 59%;
      flex: 0 0 59%;
      padding: 0 0 0 3.4%;
  }

  .twofixed.page4 .dots.P2-S2 {
      left: 0;
      top: 0;
  }

  .twofixed.page4 .window2 {
      max-width: 39%;
      flex: 0 0 39%;
  }

  .page4 .text-big.text-S2-P4.P4.S2,
  .page4 .text-big.text-S2-P4.P4.S2.center.width800 {
      font: normal normal 700 40px/60px 'Poppins', sans-serif;
  }

  .page4 .text-big.text-S2-P4.P4.S2.center.width800 {
      max-width: 660px;
      padding: 50px 0 80px;
  }

  .page5.twofixed .Screen7--h2.S8.P4-S8,
  .page4.twofixed .Screen7--h2.S8 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
  }
}

@media (max-width: 1100px) and (min-width: 1070px) {
  .main-header-second {
      padding: 1.9% 3% 0 10.7%;
  }

  .normal.main-p.center.S4 {
      margin-left: 30px;
      margin-right: 30px;
  }
}

@media (max-width: 1600px) and (min-width: 1070px) {
  .normal.main-p.center.S4 {
      max-width: 1000px;
  }

  .page5 .Screen6.S6.P5-S6 {
      /*margin: 0 auto;*/
  }

  .page5 .grey {
      margin: 0 auto 0;
      padding-top: 38px;
  }

  .page5 .Screen5--h2.head2.P5-S6 {
      margin: 17px 7% 14px;
      padding: 0;
      letter-spacing: -1px;
  }

  .page5 .wishes {
      margin: 17px 0 0 7%;
      padding: 0;
      letter-spacing: -1.3px;
  }

  .page5 .our-team {
      padding-top: 80px;
  }

  .Screen5.S5.P1-S5, .Screen6.S6.P1-S6 {
      overflow: hidden;
  }

  .page4 .window1--description {
      margin: 0 auto 30px 44%;
  }

  .twofixed .common-info::after,
  .twofixed .S5-P2dots::before {
      content: none;
  }

  .Screen7--p.P2-S7-remade::after {
      width: 103.9%;
  }

  .page5.twofixed .Screen5--h2.head2.center.P1-S6,
  .page5 .wishes {
      line-height: 60px;
      font-size: 40px;
  }

  .page5 .our-team-h2 {
      font-size: 40px;
  }

  .page5 .avatars-collection {
      margin-top: 56px;
  }

  .page5 .Screen7--h2.S8.P4-S8 {
      font-size: 40px;
      line-height: 60px;
  }

  .page5 .illustrate.Screen5-img.Screen6-img.P5-S6 {
      height: 290px;
      width: 390px;
  }

  .page5 .dots.Screen6--dots.P5-S6 {
      height: 360px;
      width: 390px;
      margin: 40px 34% 0 auto;
      left: 11.6%;
      box-sizing: initial;
  }

  .main-page .Sreen5--h3 {
      padding: 13px 6% 0 3%;
  }
}

@media (min-width: 1601px) and (max-width: 1820px) {
  .window1-h.X12000 {
      min-width: 727px;
      font-size: 81px;
  }

  .main-header-second.S3 {
      padding: 0 1.8% 0 1.8%;
      margin: 0 auto auto;
      width: 520px;
  }

  .Screen5--description--content.S6c {
      overflow: hidden;
  }

  .main-p.X1600 br {
      display: contents;
  }

  .Screen5.S5 {
      width: 1400px;
      margin: 182px auto 0 auto;
  }

  .Screen5--description--content {
      max-width: 100%;
  }

  .Screen5--p.P1-S6::after {
      left: 6.55%;
  }

  .dots.Screen5--dots.dots51 {
      left: 0;
      margin: 0 60px 55px auto;
  }

  .dots.Screen6--dots {
      left: -9%;
      margin: 30px 0 20px 20%;
  }

  .window1.Screen5--w1 {
      left: 0;
  }

  .window2.Screen6--w2.Screen5--w1.S6 {
      left: 5%;
  }
}

@media (min-width: 1200px) and (max-width: 1920px) {
  .Screen7 {
      padding-bottom: 67px;
  }

  .Screen7--h2.S8.X1200 {
      padding: 120px 0 0;
  }

  .page4 .Screen8 .Screen7--h2 {
      max-width: 1000px;
  }
}

@media (min-width: 1600px) {
  .Screen5--button-group.S2 {
      min-width: 596px;
  }

  .Screen5--button-group.X1600 {
      min-width: 231px;
  }

  .window1--button-group.Screen5--button-group {
      min-width: 526px;
      justify-content: flex-start;
  }

  .main-p.X1600 {
      width: 480px;
  }

  .window1--description.S1 {
      margin-right: 99px;
  }

  .window1--description {
      margin-right: 40px;
  }

  .window1.Screen6--w2.Screen5--w2.S6 {
      width: 100%;
  }

  .page5.twofixed .Screen7--h2.S8.P4-S8,
  .page4.twofixed .Screen7--h2.S8.P4-S8 {
      font-size: 40px;
  }
}

@media (min-width: 1920px) {
  .Screen5--decription.S5.S52 {
      width: 63%;
  }

  .page4 .Screen8 .Screen7--h2 {
      max-width: 1000px;
  }

  .Screen7--h2.why {
      padding: 157px 0 70px;
  }

  .Screen7.S7 {
      padding: 0;
  }

  .Screen7--p {
      margin-top: 11px;
  }

  .Screen5--p.P1-S6::after {
      left: 6.35%;
  }
}

.chevron {
  margin: 12vh auto 3vh;
}


.window1--button-group.Screen5--button-group.S7 {
  justify-content: space-between;
}

/*Page3 finish*/
/*Page4 sart*/
.page4 .Screen2 {
  margin: auto 0 3.5vh;
}

.page4 .First_Screen {
  height: 100vh;
}

.page4 .window1--button-group.P2.P2-S2 {
  justify-content: flex-end;
  width: auto;
}

.page4 .page3.window1-descript.P2.S2-P2 {
  padding-right: 9%;
}

.page4 .window1--description {
  margin: 0 70px 30px 0;
}

.width800 {
  max-width: 800px;
  margin: auto;
  padding: 34px 0 80px;
}

.S2-P4 {
  background-color: #F7F7FB;
}

.wrapper {
  margin: auto;
  max-width: 1400px;
}

.two-explain.Screen2 {
  display: flex;
  flex-wrap: nowrap;
  max-width: 1500px;
  margin: 0 auto 0;
  padding: 212px 0;
}

.dots.P2-S2.topPhoto.P4-S2 {
  height: 100%;
  width: 80%;
  object-fit: cover;
  left: 0;
  top: 0;
  margin: 0 0 0 auto;
}

.dots.P2-S2.topPhoto.P4-S2.P4-S3 {
  width: 83.1%;
  left: 0;
  height: 100%;
}

.illustrate.P2-S2.P4-S3-img.max-width1850 {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.illustrate.P2-S2.P4-S3-img.P4-two-exp1 {
  width: 100%;
}

.illustrate.P2-S2.P4-S3-img.P4-two-exp2 {
  width: 100%;
}

.head3 {
  font: normal normal 700 40px/60px 'Poppins', sans-serif;
}

.text-extending {
  font: normal normal 300 22px/36px 'Poppins', sans-serif;
  margin: 40px 0 0;
  color: #7E7F7E;
  letter-spacing: 0.2px;
}

.dots.topPhoto.P4-S2 {
  left: 5.6%;
}

.dots.topPhoto.P4-S3 {
  left: 24%;
}

.dots.topPhoto.P4-S3::after {
  left: auto;
  right: -39px;
}

.illustrate.P2-S2.P4-S3-img {
  height: 656px;
  width: 600px;
  border-radius: 15px;
}

.window-left {
  flex: 1 0 50%;
  padding: 0 0 0 7.2%;
}

.not-so-good {
  opacity: .77;
  padding: 0 0 0 14%;
}

.not-so-good .text-extending {
  margin: 40px 11% 0 0.8%;
}

.window-left.P4-S3 {
  flex: 1 0 52%;
  padding: 0 0 0 2.8%;
}

.window-right.P4-S3 {
  flex: 1 0 48%;
}

.window-right {
  flex: 1 0 50%;
}

.main-header-second-h2.head2.P2.S2-P2.P4-S3-H2,
.main-header-second-h2.head2.P2.S2-P2.P4-S3,
.main-header-second-h2.head2.P2.S2-P2 {
  padding: 0;
}

.head3.P4-S3,
.head3.P4-S2 {
  color: #232D53;
  margin: 20px 0 0;
  letter-spacing: -1.4px;
}

.text-big.text-S2-P4.P4.S2 {
  font: normal normal 700 40px/60px 'Poppins', sans-serif;
  color: #10214F;
  letter-spacing: -1.1px;
}

.visualtextinfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.info-vis {
  height: 240px;
  width: 360px;
  border-radius: 25px;
}

.cail {
  flex: 0 0 0;
}

.header.h3-P4 {
  font: normal normal 700 22px/30px 'Poppins', sans-serif;
  padding: 8.6% 0 0;
  margin: 0;
  color: #19191B;
}

.ul-P4 {
  padding: 0;
  margin: 5px 0 82px;
  column-count: 2;
}

.item.h3-P4 {
  position: relative;
  list-style-type: none;
  padding: 0 0 0 14%;
  font: normal normal 300 18px/32px 'Poppins', sans-serif;
  width: 100%;
  letter-spacing: -1px;
}

.item.h3-P4::before {
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.foot-head {
  padding: 0 0 75px;
  margin: 0;
}

.Screen8 {
  padding: 0 0 120px 0;
  background-image: linear-gradient(to right, #3874FF, #29579F);
}

.Screen7--h2.S8.P4-S8 {
  font: normal normal 400 32px/60px 'Poppins', sans-serif;
  margin: auto;
  letter-spacing: -0.5px;
  padding-left: 60px;
  padding-right: 60px;
}

/*Page4 finish*/
/*test*/
.page1,
.page2,
.page3 {
  position: relative;
}

/*Page5 start*/
.Screen2.P5-S2 {
  margin: auto 0 8.2vh;
}

.main-header-second-h2.head2.P2.S2-P2.P5-S2 {
  padding: 0 5% 0 6.5%;
}

.page5 .window1-descript.P2.S2-P2.P5-S2 {
  font: normal normal 400 44px/80px 'Poppins', sans-serif;
  padding: 0 3% 0 6.5%;
  max-width: 790px;
  letter-spacing: -2.1px;
}

.yellow-upper.P5 {
  font: normal normal 400 44px/68px 'Poppins', sans-serif;
  letter-spacing: -2px;
}

.grey {
  background-color: #f7f7fb;
  width: 100%;
  max-width: initial;
  padding: 97px 0 0;
}

.Screen6.S6.P5-S6 {
  max-width: 1470px;
  margin: 0 auto;
}

.window2.Screen6--w2.Screen5--w1.S6.P5-S6,
.window1.Screen6--w2.Screen5--w2.S6.P5-S6 {
  max-width: 50%;
  flex: 0 0 50%;
  top: auto;
  margin: 0;
  padding: 40px 0 117px;
}

.dots.Screen6--dots.P5-S6 {
  top: 0;
  left: 0;
  height: 428px;
  width: 567px;
  margin: 92px auto auto;
}

.illustrate.Screen5-img.Screen6-img.P5-S6 {
  height: 355px;
  width: 481px;
  border-radius: 30px;
}

.dots.Screen5--dots.Screen6--dots.P5-S6::after {
  bottom: 23px;
  top: auto;
  height: 209px;
  width: 212px;
}

.Screen5--decription.S5.S52.P5-S6 {
  width: auto;
  margin-top: 10px;
}

.Screen5--h2.head2.P5-S6 {
  font: normal normal 600 40px/84px 'Poppins', sans-serif;
  margin: 0 0 0 13%;
}

.Screen5--p.P1-S6.P5-S6 {
  padding: 3% 0 3% 16%;
  font: normal normal 400 28px/56px 'Poppins', sans-serif;
  color: #232D53;
}

.Screen5--description--content.S6c.P5-S6 {
  max-width: initial;
  padding: 3px 0 0;
}

.our-team {
  padding: 112px 0 0;
}

.our-team-h2 {
  text-align: center;
  font: normal normal 700 72px/60px 'Poppins', sans-serif;
  letter-spacing: -2.5px;
  margin: 10px 0 0;
}

.our-team-strong {
  font-weight: 700;
}

.our-team-span {
  font-weight: 400;
}

.avatars-collection {
  display: flex;
  flex-wrap: wrap;
  margin: 89px auto 0;
  max-width: 1640px;
  justify-content: space-evenly;
  box-sizing: initial;
}

.name-team-member {
  font: normal normal 700 22px/39px 'Poppins', sans-serif;
  color: #696871;
  margin: 10px 0 0;
  letter-spacing: -1.2px;
}

.specialist-type {
  font: normal normal 400 22px/39px 'Poppins', sans-serif;
  color: #696871;
  letter-spacing: -1.2px;
}

.linkedinlogo {
  width: 52px;
  height: 52px;
  background: url('../../Images/linkedinlogo.svg') center no-repeat;
  margin: 10px 0 0;
}

.linkedinlogo:hover {
  filter: invert(100%);
}

.photo-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our-team-photo {
  border-radius: 21px;
}

.Screen5--p.P1-S6.P5-S6::before {
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  border-color: #f7f7fb;
}

.Screen5--p.P1-S6.P5-S6::after {
  height: 110%;
  top: 55%;
  left: 2.3%;
}

.Screen5--p.P1-S6.P5-S6:last-of-type::after {
  background-color: #f7f7fb;
  width: 2%;
  left: 1.2%;
}

.wishes {
  font: normal normal 700 40px/52px 'Poppins', sans-serif;
  color: #FF6B0F;
  display: block;
  margin: 23px 0 0 16%;
  letter-spacing: -0.7px;
}

.page5 .twofixed .main-post-link.text-big.text-S2-P4.P4.S2.center {
  font: normal normal 700 24px/48px 'Poppins', sans-serif;
}

.text-big.text-S2-P4.P4.S2.P5-our-team.P5 {
  margin: 65px auto 55px;
  letter-spacing: 0;
  position: relative;
  padding: 38px 0 80px;
}

.text-big.text-S2-P4.P4.S2,
.text-big.text-S2-P4.P4.S2.P5-our-team.P5 {
  font: normal normal 700 24px/48px 'Poppins', sans-serif;
}

.text-big.text-S2-P4.P4.S2.P5-our-team.P5 {
  max-width: 700px;
}

.page5.twofixed .Screen7--h2.S8.P4-S8 {
  max-width: 700px;
}

.main-page .First_Screen .window1-descript::after,
.text-big.text-S2-P4.P4.S2.P5-our-team.P5::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 1px;
  width: 67%;
  background-color: #EAE9F2;
}

.main-page .First_Screen .window1-descript::after {
  content: none;
}

.page5 .Screen8 {
  padding-bottom: 130px;
}

.page5 .Screen7--h2.S8.P4-S8 {
  font: normal normal 400 32px/60px 'Poppins', sans-serif;
  padding: 94px 0 0;
}

.page5.twofixed .login.buttonIDN.decide {
  margin: 36px auto 0;
}

/*Page5 finish*/


.page3 .S7.S71.dot-list.Advisor,
.page2 .S7.S71.dot-list.Advisor {
  opacity: 1;
}

@media (max-width: 1070px) {
  .page5 .cookie,
  .page5 .policy {
      margin: 16px;
  }

  .page5 .cookie h1,
  .page5 .policy h1.head3.P4-S3 {
      font-size: 40px;
      line-height: 50px;
      margin-top: 70px;
  }

  .main-page .First_Screen .window1-descript::after {
      content: '';
  }

  .main-page .First_Screen,
  .twofixed .First_Screen,
  .main-header.S3,
  .idea,
  .main-page .Screen5.S5,
  .twofixed .Screen5.S5,
  .main-page .Screen6.S6,
  .twofixed .Screen6.S6,
  .main-page .Screen9.P1-S9,
  .twofixed .Screen9.P1-S9,
  .twofixed .sectors.S7.P2-S7-remade {
      margin: 0 16px;
  }

  .twofixed .Screen9.P1-S9,
  .main-page .Screen9.P1-S9 {
      padding-bottom: 48px;
  }

  .main-page .First_Screen .window1-descript {
      position: relative;
  }

  .twofixed .First_Screen {
      height: auto;
      justify-content: start;
  }

  .twofixed .main-post-link.P2.third-screen:before,
  .main-post-link.P2.S2-P2::before {
      height: 2px;
      bottom: 0;
  }

  .twofixed.page2 .First_Screen, .twofixed.page3 .First_Screen {
      height: auto;
  }

  /*burger*/
  .lines-menu, .lines-x {
      display: block;
      height: 35px;
      width: 35px;
      position: absolute;
      right: 14px;
      top: 11px;
  }

  .burger-shower + .lines-menu {
      background: url("../../Images/burger.svg") center no-repeat;
      z-index: 1;
  }

  .vis .lines-menu {
      display: none;
  }

  .vis .lines-x {
      background: url("../../Images/x.svg") center no-repeat;
      opacity: 1;
      z-index: 1;
  }

  .lines-x {
      opacity: 0;
      z-index: 1;
  }

  .twofixed .login-block, .login-block {
      width: 100%;
  }

  .main-page .top-horizon-menu,
  .twofixed .top-horizon-menu {
      flex-direction: column;
      margin: 0;
      background-color: #00000000;
      height: 100vh;
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
  }

  .burger-shower {
      z-index: 2;
      display: block;
      position: absolute;
      right: 15px;
      top: 11px;
      height: 35px;
      width: 35px;
      border-width: 0;
      margin: 0;
      opacity: 0;
  }

  .main-top-nav .active::after {
      width: 15%;
      left: 40px;
      right: auto;
      bottom: 5px;
  }

  .twofixed .main-top-nav,
  .main-top-nav {
      padding: 29vh 0 0;
      flex-direction: column;
      background-color: #fff;
      height: 58vh;
      box-sizing: border-box;
      position: relative;
      margin: 0 0 -1px;
  }

  .main-page .top-horizon-menu::before,
  .twofixed .top-horizon-menu::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-color: #000000;
      padding-left: 100%;
  }

  .hide .top-horizon-menu::before {
      opacity: 0;
      transition: opacity .2s ease-in;
  }

  .vis .top-horizon-menu::before {
      opacity: 0.6;
      transition: opacity .2s ease-out;
  }

  .top-horizon-menu {
      visibility: hidden;
  }

  .vis .top-horizon-menu {
      padding-left: 34%;
      visibility: visible;
      transition: all .2s ease-in .1s;
  }

  .hide .top-horizon-menu {
      padding-left: 100%;
      visibility: collapse;
      transition: all .2s ease-out .1s;
  }

  .twofixed .main-top-nav-anchor,
  .main-top-nav-anchor {
      padding: 13px 40px;
      font-size: 16px;
  }

  /*burger*/
  .Header {
      z-index: 2;
      height: 40px;
  }

  .vis {
      overflow: hidden;
  }

  .hide {
      overflow-x: hidden;
  }

  .twofixed .Header--logo,
  .Header--logo {
      top: 0;
      left: 0;
  }

  .twofixed .logo-link,
  .logo-link {
      height: 35px;
  }

  .idnlogo {
      height: 25px;
      margin: 16px 0 0 16px;
  }

  .twofixed .login.buttonIDN.back-slider.top-login,
  .login.buttonIDN.back-slider.top-login {
      height: 40px;
      width: 130px;
      margin: 14px auto 0 40px;
      font: normal normal 500 14px/40px 'Poppins', sans-serif;
  }

  .twofixed .login-block,
  .login-block {
      padding: 0;
      background-color: #fff;
      height: 42vh;
      display: block;
  }

  .twofixed .main-top-nav-anchor, .main-top-nav-anchor {
      min-width: 130px;
      margin: 0;
  }

  .twofixed .First_Screen,
  .First_Screen {
      min-height: initial;
      max-height: initial;
      justify-content: start;
  }

  .First_Screen .Screen2.P1-S2,
  .First_Screen .Screen2 {
      flex-direction: column;
      margin: 0;
      flex-grow: 1;
  }

  /*s1-p1*/
  .main-page .First_Screen .Screen2 {
      flex-direction: column-reverse;
  }

  .main-page .First_Screen #top + .Screen2 .window2 {
      flex-grow: 5;
  }

  .main-page .First_Screen #top + .Screen2 .window1 {
      margin-top: 0;
      margin-bottom: 6vh;
      flex-grow: 0;
  }

  .main-page .First_Screen .window1--button-group {
      margin-top: 48px;
  }

  .main-page .First_Screen #top + .Screen2 .window2 .dots {
  }

  .main-page .First_Screen .illustrate.Screen2 {
      max-height: 600px;
  }

  /*s1-p1*/
  .First_Screen #top + .Screen2 .window2 {
      flex-grow: 1;
      align-items: center;
      /*height: 30%;*/
  }

  .page5 #top + .Screen2 .window2 {
      height: 29%;
  }

  .First_Screen .Screen2 .window2 {
      display: flex;
      justify-content: left;
  }

  .back-color {
      padding-bottom: 48px;
      margin-top: 0;
  }

  .main-page .window1,
  .window2,
  .window1.Screen6--w2.Screen5--w2.S6 {
      flex: 0 0 auto;
      padding: 0;
      left: 0;
  }

  .window2.Screen6--w2.Screen5--w1.S6.P5-S6 {
      margin: 0 16px;
  }

  #top + .Screen2 .window1 {
      margin-top: 6vh;
      display: block;
      height: auto;
      flex-grow: 0;
  }

  .main-page .window2.Screen6--w2.Screen5--w1.S6,
  .window2.Screen5--w2 {
      max-width: 100%;
      width: 100%;
      top: 0;
      margin: 48px 0 0;
  }

  .main-page .window2.Screen6--w2.Screen5--w1.S6 {
      top: 24px;
      margin: 0 0 24px;
  }

  .Screen5--decription.S5.S52 {
      width: auto;
  }

  .page4 .main-post-link.P2.S2-P2.text-big.text-S2-P4.P4.S2.center,
  .twofixed .main-post-link.P2.S2-P2.text-S2-P4,
  .twofixed .main-post-link.text-big.text-S2-P4.P4.S2.center,
  .text-big.text-S2-P4.P4.S2,
  .head3.P4-S3, .head3.P4-S2,
  .twofixed .Screen7--h2.P2-S7-remade,
  .Sreen5--h3 {
      font: normal normal 600 24px/32px 'Poppins', sans-serif;
      padding: 0;
      margin: 8px 0 0;
      letter-spacing: 0;
  }

  .text-big.text-S2-P4.P4.S2 {
      padding: 32px 0 0;
  }

  .twofixed .main-header-second-h2.P1-S6.P5-S6 {
      margin: 10px 0 6px 32px;
      font: normal normal 600 24px/26px 'Poppins', sans-serif;
  }


  .page4 .text-big.text-S2-P4.P4.S2.center.foot-head,
  .page5 .text-big.text-S2-P4.P4.S2.P5-our-team.P5 {
      padding: 24px 0 48px;
      margin: 0;
  }

  .twofixed .Screen7.P2-S7-remade {
      padding: 40px 16px 0;
  }

  .Screen7--p.P2-S7-remade::after {
      width: 100%;
      bottom: -20px;
  }

  .twofixed .info-graph {
      padding: 0;
  }

  .twofixed .center.main-p.sector.normal,
  .Screen5--strong {
      font: normal normal 600 14px/24px 'Poppins', sans-serif;
  }

  .Screen5--p.P5-S6::before, .Screen5--strong::after {
      box-sizing: border-box;
      left: 0;
      height: 20px;
      width: 20px;
  }

  .main-page .Screen5--p::after {
      left: 9px;
      width: 1px;
  }

  .Screen5--p.S5:last-child::after {
      left: 8px;
      width: 5px;
  }

  .chevron {
      display: none;
  }

  .window1-h {
      font: normal normal 600 28px/40px 'Poppins', sans-serif;
      margin: 0 auto 0 0;
      letter-spacing: 0;
      max-width: initial;
  }

  .window1-descript {
      font: normal normal 400 12px/24px 'Poppins', sans-serif;
      letter-spacing: 0;
      padding: 0;
  }

  .window1-descript, .window1-h {
      margin: 0 0 8px 0;
  }

  .dots.Screen6--dots.P5-S6,
  .twofixed .dots.topPhoto,
  .dots {
      left: 0;
      height: 81%;
      width: 92.5%;
  }

  .twofixed .dots.topPhoto, .dots {
      height: 64%;
  }

  .dots.Screen5--dots.Screen6--dots.P5-S6::after,
  .twofixed .dots::after,
  .dots.P1-S2::after {
      right: -20px;
      left: auto;
      bottom: -30px;
      width: 100px;
      height: 110px;
  }

  .dots.Screen5--dots.Screen6--dots.P5-S6::after {
      right: -25px;
      bottom: -22px;
  }

  .page2.twofixed .illustrate,
  .page3.twofixed .illustrate,
  .twofixed .illustrate,
  .illustrate,
  .illustrate.Screen5-img.Screen6-img.P5-S6 {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      object-fit: cover;
      object-position: top;
  }

  .P1-S2 .illustrate {
      height: 100%;
  }

  .twofixed .window1--button-group.P2.P2-S2,
  .window1--button-group {
      margin: 24px auto 0 0;
      width: auto;
      min-width: 100%;
      justify-content: left;
  }

  .twofixed .window1--button-group.P2.P2-S2 {
      margin: auto;
  }


  .First_Screen .window1--button-group.P2.P2-S2 {
      margin: 16px 0 0;
  }

  .window1--button-group.Screen5--button-group,
  .Screen5--button-group.S6bg {
      margin: 0 0 24px;
      width: auto;
      min-width: 100%;
      justify-content: flex-start;
  }

  .twofixed .window1--description,
  .window1--description {
      margin: 0 16px 0 0;
  }

  .window1--description--button {
      font: normal normal 500 14px/16px 'Poppins', sans-serif;
      margin: 0 0 8px auto;
      letter-spacing: 0;
  }

  .twofixed .buttonIDN.big.P1-S7,
  .twofixed .buttonIDN.big,
  .back-slider.buttonIDN.white.decide,
  .buttonIDN.big.P1-S7,
  .buttonIDN.big {
      width: 130px;
      height: 40px;
      font: normal normal 500 14px/40px 'Poppins', sans-serif;
  }

  /*.Screen2,*/
  /*.Screen3,*/
  .Screen3.main {
      width: auto;
      padding: 0 0 24px;
  }

  .main-header {
      padding: 0 0 48px;
      flex-direction: column-reverse;
  }

  .Screen4, .Screen5--decription,
  .main-header-second {
      padding: 0;
  }

  .window1.Screen6--w2.Screen5--w2.S6,
  .window1.Screen5--w1 {
      max-width: 100%;
      margin: 0;
  }

  .twofixed .main-header-second-h2.head2,
  .twofixed .main-header-second-h2.head2.P2.S2-P2,
  .main-header-second-h2.head2, .main-header-second-h2.head2,
  .twofixed .main-header-second-h2.head2.P2 {
      font: normal normal 600 12px/24px 'Poppins', sans-serif;
      padding: 48px 0 0;
      letter-spacing: 0;
      margin: 0;
  }

  .twofixed .main-header-second-h2.head2.P2.S2-P2 {
      margin: 0;
      padding: 0;
  }

  .main-p {
      font: italic normal 400 20px/32px 'Poppins', sans-serif;
  }

  .main-post-link {
      font: normal normal 700 20px/24px 'Poppins', sans-serif;
  }


  .main-page .main-post-link:before {
      bottom: -7px;
      height: 1px;
  }

  .dots.Screen5--dots::after,
  .dots.Screen5--dots.Screen6--dots::after,
  .main-header-dots.dots::after {
      right: -8%;
      bottom: -6%;
      left: auto;
      top: auto;
      width: 37%;
      height: 33%;
      background-position: right bottom;
  }

  /*.Screen4,*/
  .normal.main-p.center.S4 {
      max-width: 91%;
      letter-spacing: 0;
      padding: 0;
      margin: 24px 16px 0;
  }

  .main-p,
  .n.main-p.coverormal.main-p.center.S4,
  .main-p.cover,
  .twofixed .main-p.cover.P2.P2-S4 {
      margin: 24px auto 0;
  }

  .main-post-link,
  .main-post-link.frame4 {
      text-align: center;
      margin: 24px auto;
  }

  .main-post-link {
      text-align: left;
      margin: 24px 0;
  }

  .main-post-link.frame4 {
      letter-spacing: 0;
      margin-bottom: 48px;
  }

  .main-post-link.frame4.center::before {
      margin: auto;
      left: 10%;
      right: 10%;
      width: 53%;
      bottom: -10px;
  }

  .main-page .main-post-link:before {
      bottom: -8px;
      height: 2px;
      width: 59%;
  }

  .back-color::after, .back-color::before {
      display: none;
  }

  .normal.main-p.center.S4,
  .normal.main-p {
      padding-top: 0;
      margin: 48px 16px 0;
  }

  .normal.main-p {
      padding-top: 48px;
      margin: 0 auto 0;
  }

  .sectors {
      max-width: 83%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }

  .main-page .center.sector, .main-page .center.sector.S7 {
      flex: 0 0 109px;
      max-width: 109px;
      padding-top: 73px;
      margin: 0;
      left: auto;
      font: normal normal 500 14px/16px 'Poppins', sans-serif;
      background-size: 30% 30%;
      background-position: center 39%;
  }

  .main-page .center.sector.S7 {
      margin: 0 0 20px;
  }

  .back-color.S7 {
      padding: 0;
  }

  .selectors--all {
      font: normal normal 700 14px/20px 'Poppins', sans-serif;
  }

  .selectors--all::after {
      top: 2px;
  }

  .window1--button-group.Screen5--button-group.S7 {
      justify-content: space-around;
      padding: 0 0 48px;
      margin: 0;
  }

  .window1--description--button.S7 {
      margin: 24px 0 8px;
      padding: 0;
  }

  /*.Screen5,*/
  .Screen5 {
      display: block;
      margin-top: 0;
  }

  .Screen5--description--content, .Screen5--description--content.S6c {
      max-width: 100%;
      padding: 24px 0;
  }

  .Screen5--p.P1-S6.P5-S6,
  .text-extending,
  .twofixed .Screen7--p.P2-S7-remade,
  .Screen5--p {
      font: normal normal 400 14px/24px 'Poppins', sans-serif;
      padding: 8px 0 24px 11.5%;
  }

  .twofixed .Screen7--p.P2-S7-remade {
      padding: 0;
      margin: 24px 0 32px;
      display: block;
  }

  .twofixed .dot-list {
      padding: 0;
      margin: 8px 0 0 16px;
  }

  .twofixed .sectors.S7.P2-S7-remade {
      margin: 0;
  }

  .main-header-dots.dots,
  .main-page .dots.Screen5--dots,
  .main-page .dots.Screen5--dots {
      width: 92.6%;
      height: auto;
      min-height: auto;
      max-height: initial;
      margin: 0;
      left: 0;
  }

  .twofixed .sector.normal.main-p.center.S7.P2-S7-remade {
      background-size: 42px 30px;
      background-position: 0 35px;
      display: block;
      width: 150px;
      text-align: left;
      margin-left: 0;
      padding: 80px 0 0;
      left: 0;
  }

  .window1--description--button.S7.Advisor.P2-S7 {
      text-indent: 0;
      margin: 10px 0 0;
  }

  .first-col-img,
  .illustrate.Screen5-img {
      height: 100%;
      width: 100%;
      margin: 20px auto 0 0;
      border-radius: 5px;
      object-fit: cover;
      left: 0;
  }

  .illustrate.Screen5-img {
      margin-top: 0;
  }

  /*.Screen6,*/
  .Screen6 {
      flex-wrap: wrap-reverse;
  }

  .main-page .Screen6.S6 {
      margin: 32px 16px 62px;
  }

  .window2.Screen6--w2.Screen5--w1.S6 {
      flex-basis: auto;
      margin: 0;
  }

  /*.Screen7,*/
  .Screen7 {
      padding: 0;
  }

  .our-team-h2,
  .page5 .Screen7--h2.S8.P4-S8,
  .twofixed .Screen7--h2.S8,
  .Screen7--h2 {
      letter-spacing: 0;
      font: normal normal 600 24px/32px 'Poppins', sans-serif;
      padding: 48px 0 16px;
  }

  .twofixed .Screen7--h2.S8 {
      padding: 65px 0 0;
  }

  .twofixed .login.buttonIDN.decide {
      margin-top: 24px;
  }

  .our-team-h2 {
      font-weight: 400;
      padding: 37px 0 0;
  }

  .sectors.S7 {
      max-width: 95%;
  }

  .Screen7--p {
      display: none;
  }

  .sector.normal.center.S7, .window1--description--button.S7 {
      font: normal normal 500 14px/16px 'Poppins', sans-serif;
      letter-spacing: 0;
      background-size: 39% 39%;
  }

  /*.Screen8,*/
  .Screen8 {
      padding: 0 0 64px;
  }

  .Screen7--h2.S8 {
      font: normal normal 600 24px/32px 'Poppins', sans-serif;
      padding: 64px 0 0;
      max-width: 72%;
      margin: auto;
      letter-spacing: 0;
  }

  .login.buttonIDN.decide {
      margin: 24px auto 0;
  }

  /*.Screen9 {*/
  .main-page .Screen9,
  .twofixed .Screen9 {
      display: flex;
      flex-direction: column;
      margin: 37px 16px 0;
      padding: 0;
  }

  .twofixed .foot-ul,
  .foot-ul {
      margin: 0 0 16px;
  }

  .window1--description--button.S7.Advisor.P2-S7,
  .foot-li.foot-li-head {
      font: normal normal 600 16px/24px 'Poppins', sans-serif;
      letter-spacing: 0;
  }

  .window1--description--button.S7.Advisor.P2-S7,
  .foot-li.foot-li-head {
      padding: 16px 0 8px;
      margin-top: 0;
  }

  .twofixed .info-graph:last-child {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
  }

  .common-info::before {
      left: -68.9%;
      bottom: -90.4%;
      width: 240%;
      height: 100%;
      border-radius: 50%;
  }

  .twofixed .S7.S71.dot-list.Advisor {
      padding: 0;
      margin: 40px 0 48px;
      align-items: center;
  }

  .foot-li.opacity6 {
      display: inline;
      margin: 0 20px 0 0;
  }

  .footA, .foot-li.opacity6 {
      font: normal normal 400 14px/24px 'Poppins', sans-serif;
      letter-spacing: 0;
  }

  .twofixed .idn-img,
  .idn-img {
      left: -2px;
      width: 60px;
      margin-top: 48px;
  }

  .twofixed .foot-lists {
      left: 0;
  }

  .foot-lists {
      flex-direction: column;
      right: 0;
  }

  .twofixed .in, .in {
      margin: 24px 0 0;
  }

  /*Page2*/
  .twofixed .window2,
  .twofixed .window1.P2 {
      flex-basis: auto;
      padding: 0;
      max-width: initial;
      margin: 0;
  }

  .twofixed .window1.P2.P2-S7.P2-S62,
  .twofixed .window2.P2.P2-S72.left-dashed,
  .twofixed .window2.P2.P2-S7.left-dashed {
      margin: 0;
  }

  .yellow-upper.P5,
  .twofixed .page3.window1-descript.P2.S2-P2,
  .twofixed .main-post-link.P2.S2-P2,
  .twofixed .page2.window1-descript.P2.S2-P2 {
      font: normal normal 400 24px/32px 'Poppins', sans-serif;
      padding: 0;
      max-width: initial;
      letter-spacing: 0;
  }

  .twofixed.page4 .main-post-link.P2.S2-P2 {
      margin-left: 3px;
  }

  .twofixed.page3 .window1-descript.P2.S2-P2,
  .twofixed.page2 .window1-descript.P2.S2-P2 {
      margin: 24px 0;
  }

  .twofixed .First_Screen .window1-descript.P2.S2-P2 {
      margin: 24px 0 0;
  }

  .twofixed .scroll-button.P2 {
      text-indent: -30px;
      background-position: 90% 59%;
      background-size: 16%;
  }

  /*.twofixed .Screen4,*/
  .twofixed .Screen4 {
  }

  .twofixed .Screen4.P2-S4::before, .twofixed .Screen4.P2-S4::after {
      display: none;
  }

  .twofixed .two-columns {
      column-count: 1;
  }

  .wishes,
  .twofixed .main-post-link.frame4.P2 {
      font: normal normal 600 24px/32px 'Poppins', sans-serif;
  }

  .wishes {
      margin: 0 0 0 36px;
      letter-spacing: 0;
  }

  .twofixed .Screen4.P2 {
      margin: 0;
      padding: 0;
  }

  .twofixed .main-post-link.center.frame4.P2 {
      letter-spacing: 0;
      margin: 8px 0 0;
      text-indent: 0;
  }

  .twofixed .two-columns {
      margin: 48px 0;
  }

  .twofixed .main-p.cover.P2.P2-S4,
  .twofixed .main-post-link.P2.third-screen.third-line,
  .normal.main-p.center.S4,
  .normal.main-p {
      font: normal normal 400 20px/32px 'Poppins', sans-serif;
      text-indent: 0;
  }

  .twofixed .main-post-link.P2.third-screen.third-line {
      text-align: center;
  }

  .twofixed .main-post-link.P2.third-screen.third-line:before {
      bottom: -16px;
      height: 2px;
  }

  .twofixed .two-column--p:first-child {
      margin: 0 0 24px;
  }

  .twofixed.page2 .two-column--p,
  .twofixed .two-column--p {
      text-align: center;
      display: block;
  }

  .idea .cover {
      position: relative;
  }

  .idea .cover::after {
      content: '';
      position: absolute;
      display: block;
      width: 70%;
      height: 2px;
      background-color: #ff6b0f;
      bottom: -12px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
  }

  .page2 .main-post-link.P2.third-screen.third-line:before,
  .page3 .main-post-link.P2.third-screen.third-line:before {
      content: none;
  }

  .twofixed .main-post-link.P2.third-screen:before {
      bottom: 0;
      width: 100%;
      height: 2px;
  }

  .twofixed .Screen5.P2.P2-S5dashed,
  .twofixed .Screen6.P2.P2-S5dashed.S6,
  .twofixed .P2.P2-S7.P2-S5dashed,
  .twofixed .twofixed .S5-P2dots,
  .twofixed .Screen5.P2.P2-S72,
  .twofixed .window1-h.P2.S6-P2.added-element-before::before,
  .wrapper.max-width1650 {
      display: block;
      margin: 0 16px;
  }

  .twofixed .window1.P2.P2-S5 {
      margin-top: 0;
  }

  .twofixed .window1-h.P2.S6-P2.added-element-before,
  .twofixed .window1-h.P2,
  .twofixed .window1-h.P2.P2-S7,
  .twofixed .window1-h.P2.S6-P2.added-element-before.P2-S6,
  .twofixed .window1-h.P2.beyond {
      font: normal normal 600 24px/32px 'Poppins', sans-serif;
      letter-spacing: 0;
      margin: -8px 0 8px;
      padding: 32px 0 32px 48px;
  }

  .twofixed .window1-descript.P2.S6-P2.S6-P22,
  .twofixed .window1-descript.P2,
  .twofixed .window1-descript.P2.P2-S72.beyond,
  .twofixed .two-column--p,
  .twofixed .main-post-link.P2.third-screen,
  .twofixed .dots-item {
      font: normal normal 400 14px/24px 'Poppins', sans-serif;
      letter-spacing: 0;
  }

  .twofixed .window1-h.P2.S6-P2.added-element-before::before,
  .twofixed .window1-h.P2::before {
      left: 8px;
      top: 0;
      margin: auto;
      height: 16px;
      width: 16px;
      bottom: 0;
  }

  .twofixed .window1.P2.P2-S7.P2-S62 {
      padding: 0;
  }

  .twofixed .rounded-frame::before {
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      background-size: 45%;
      top: 0;
      left: 0;
  }

  .twofixed .window1-descript.P2,
  .twofixed .window1-descript.P2.S6-P2,
  .twofixed .window1-descript.P2.P2-S7,
  .twofixed .window1-descript.P2.S6-P2.S6-P22,
  .twofixed .window1-descript.P2.P2-S72.beyond {
      padding: 0 0 40px 48px;
  }

  .twofixed .S5-P2dots::before {
      content: none;
  }

  .twofixed .left-dashed::before,
  .twofixed .window1.P2.P2-S6::before,
  .twofixed .window1.P2.P2-S7.P2-S62::before,
  .twofixed .window2.P2.P2-S72.left-dashed::before,
  .twofixed .window2.P2.P2-S5.left-dashed::before,
  .twofixed .window2.P2.P2-S7.left-dashed::before {
      height: 55%;
      top: 15%;
      left: 15px;
      width: 2px;
  }

  .twofixed .S5-P2dots {
      padding-bottom: 24px;
  }

  .twofixed .window2.P2.P2-S5.left-dashed::before {
      top: 110px;
  }

  .twofixed .window2.P2 {
      max-width: initial;
      padding: 0;
      margin: 0;
  }

  .twofixed .window1.P2.P2-S7.P2-S62 {
      display: block;
  }

  /*.twofixed .common-info,*/
  .twofixed .dots-item::before {
      width: 6px;
      height: 6px;
      top: 10px;
      left: -16px;
  }

  /*.twofixed .window1.P2.P2-S6.left-dashed .window2.P2,*/
  .window1.P2.P2-S7.P2-S72,
  .twofixed .window1.P2.P2-S7,
  .twofixed .P2-S5dashed::before,
  .Screen6.P2-S7 .window2.P2,
  .twofixed.page2 .hide320-600,
  .twofixed.page3 .hide320-600,
  .twofixed .common-info::after {
      display: none;
  }

  /*page4*/
  .window-left.P4-S3 {
      padding: 0;
  }

  .two-explain.Screen2,
  .two-explain.Screen2.P2-S3 {
      margin: 0 16px 0;
      padding: 48px 0 48px;
      flex-direction: column;
  }

  #page4, .two-explain {
      padding-bottom: 48px;
  }

  .two-explain.Screen2.max-width1600 {
      display: flex;
      flex-direction: column-reverse;
  }

  .window-right {
      padding: 0;
      height: initial;
      min-height: initial;
      max-height: initial;
  }

  .not-so-good {
      padding: 48px 0 0;
  }

  .dots.P2-S2.topPhoto.P4-S2,
  .dots.P2-S2.topPhoto.P4-S2.P4-S3 {
      width: 100%;
      padding: 0 7% 7% 0;
      margin: 16px auto;
      box-sizing: border-box;
  }

  .dots.P2-S2.topPhoto.P4-S2::after {
      right: 2px;
      bottom: 0;
  }

  .head3.P4-S3, .head3.P4-S2 {
      letter-spacing: 0;
  }

  .not-so-good .text-extending,
  .page4 .text-extending {
      margin: 24px 0;
  }

  .text-extending {
      padding: 0;
      margin: 0;
  }

  .visualtextinfo {
      justify-content: center;
      padding-bottom: 40px;
  }

  .cail {
      flex: 0 0 100%;
      margin: 40px 0 0;
  }

  .ul-P4 {
      margin: 0;
  }

  .info-vis {
      width: 100%;
      height: auto;
      border-radius: 5px;
  }

  .header.h3-P4 {
      font: normal normal 700 20px/24px 'Poppins', sans-serif;
      padding: 32px 0 8px;
  }

  .item.h3-P4 {
      font: normal normal 400 14px/24px 'Poppins', sans-serif;
  }

  .item.h3-P4::before {
      height: 6px;
      width: 6px;
  }

  /*Page5*/
  .window2.Screen6--w2.Screen5--w1.S6.P5-S6 {
      margin: 0;
      padding: 0 0 70px 0;
      max-width: 100%;
  }

  .illustrate.Screen5-img.Screen6-img.P5-S6 {
      margin: 0;
      max-height: 270px;
      object-position: center;
  }

  .Screen5--p.P1-S6.P5-S6::after {
      left: 9px;
      width: 1px;
      box-sizing: border-box;
      height: 125%;
  }

  .Screen5--p.P1-S6.P5-S6:last-of-type::after {
      left: 6px;
  }

  .Screen5--decription.S5.S52.P5-S6 {
      margin: 0;
  }

  .two-explain.Screen2.max-width1600,
  .grey {
      padding: 0;
  }

  .Screen6.P5-S6.P5-S6 {
      flex-wrap: wrap;
      flex-direction: column-reverse;
  }

  .name-team-member {
      font: normal normal 600 16px/24px 'Poppins', sans-serif;
      margin-top: 8px;
  }

  .specialist-type {
      font: normal normal 400 16px/24px 'Poppins', sans-serif;
      margin-top: 8px;
  }

  .window1.Screen6--w2.Screen5--w2.S6.P5-S6 {
      max-width: initial;
      padding: 0;
  }

  .dots.Screen6--dots.P5-S6 {
      margin: 25px 0 0;
  }

  .Screen5--p.P1-S6.P5-S6 {
      padding: 14px 0 14px 36px;
  }

  .Screen7--h2.S8.P4-S8 {
      max-width: initial;
      padding: 64px 16px 0;
  }

  .our-team {
      padding: 0;
      margin: 0 16px;
  }

  .avatars-collection {
      margin: 30px 0 0;
  }

  .our-team-photo {
      width: 65%;
      border-radius: 5px;
  }

  .linkedinlogo {
      width: 24px;
      height: 24px;
      background-size: 24px;
      margin-top: 8px;
      margin-bottom: 24px;
  }

  .text-big.text-S2-P4.P4.S2.P5-our-team.P5 {
      margin: 10px 16px 60px;
      padding: 20px 0 0;
  }

  .text-big.text-S2-P4.P4.S2.P5-our-team.P5,
  .twofixed .main-post-link.text-big.text-S2-P4.P4.S2.center {
      font: normal normal 600 16px/24px 'Poppins', sans-serif;
  }

  .page5.twofixed .Screen7--h2.S8.P4-S8,
  .page5 .text-big.text-S2-P4.P4.S2.P5-our-team.P5 {
      margin: auto;
  }

  .main-post-link.P5-our-team::before {
      height: 2px;
      bottom: -10px;
  }

  .page5 .Screen7--h2.S8.P4-S8 {
      padding-top: 64px;
  }

  .main-page .First_Screen .window1-descript::after,
  .text-big.text-S2-P4.P4.S2.P5-our-team.P5::after {
      width: 90%;
  }

  .main-page .First_Screen .window1-descript::after {
      bottom: -21%;
      top: auto;
      left: 0;
      right: auto;
      width: 80%;
  }

  .page5.twofixed .login.buttonIDN.decide {
      margin: 24px auto 0;
  }

  .page5 .Screen8 {
      padding: 0 16px 64px;
      width: auto;
  }

  .page4 .First_Screen .window1--button-group.P2.P2-S2,
  .login.buttonIDN[href="#page2"], .login.buttonIDN[href="#page3"] {
      display: none;
  }

  .main-page .login.buttonIDN.S2.big.white.back-slider.Screen2 {
      line-height: 36px;
  }
}

@media (orientation: landscape) and (max-width: 1070px) {
  /*.First_Screen,*/
  .First_Screen #top + .Screen2 .window2 .dots,
  .First_Screen .Screen2.P1-S2, .First_Screen .Screen2 {
      height: 30%;
  }

  .First_Screen #top + .Screen2 .window2 {
      margin: 100px 0 60px;
  }
}

@media (min-width: 600px) and (max-width: 1070px) {
  .main-page .First_Screen #top + .Screen2 .window2 {
      padding: 20% 0;
  }

  .main-page .First_Screen .window1--button-group {
      margin-top: 88px;
  }

  .main-page .Screen9.P1-S9,
  .twofixed .Screen9.P1-S9,
  .main-page .First_Screen,
  .twofixed .First_Screen,
  .main-header.S3,
  .idea,
  .main-page .Screen5.S5,
  .twofixed .Screen5.S5,
  .main-page .Screen6.S6,
  .twofixed .Screen5.P2.P2-S5dashed,
  .twofixed .P2.P2-S7.P2-S5dashed,
  .twofixed .S5-P2dots,
  .twofixed .Screen7.P2-S7-remade,
  .two-explain.Screen2.max-width1600,
  .grey, .two-explain.Screen2, .two-explain.Screen2.P2-S3,
  .wrapper.max-width1650, .twofixed .Screen7--h2.S8, .twofixed .Screen6.P2.P2-S5dashed.S6 {
      margin: 0 60px;
  }

  .idnlogo {
      height: 35px;
      margin: 40px 0 0 30px;
  }

  .burger-shower, .lines-menu, .lines-x {
      height: 40px;
      width: 45px;
      top: 40px;
      right: 30px;
  }

  .window1-h {
      font: normal normal 600 48px/70px 'Poppins', sans-serif;
  }

  .window1-descript {
      font: normal normal 400 22px/40px 'Poppins', sans-serif;
  }

  .window1--description--button {
      font: normal normal 500 24px/36px 'Poppins', sans-serif;
  }

  .twofixed .buttonIDN.big.P1-S7,
  .twofixed .buttonIDN.big,
  .back-slider.buttonIDN.white.decide,
  .buttonIDN.big.P1-S7,
  .buttonIDN.big {
      width: 190px;
      height: 50px;
      font: normal normal 500 24px/50px 'Poppins', sans-serif;
  }


  .twofixed .main-header-second-h2.head2,
  .twofixed .main-header-second-h2.head2.P2.S2-P2,
  .main-header-second-h2.head2, .main-header-second-h2.head2,
  .twofixed .main-header-second-h2.head2.P2 {
      font: normal normal 600 24px/36px 'Poppins', sans-serif;
      padding: 64px 0 0;
      letter-spacing: 0;
      margin: 0;
  }

  .main-p,
  .n.main-p.coverormal.main-p.center.S4,
  .main-p.cover,
  .twofixed .main-p.cover.P2.P2-S4 {
      margin: 30px auto 0;
  }

  .main-p {
      font: italic normal 400 30px/48px 'Poppins', sans-serif;
  }

  .main-post-link {
      font: normal normal 700 30px/48px 'Poppins', sans-serif;
  }

  .main-post-link {
      text-align: left;
      margin: 30px 0;
  }

  .twofixed .main-p.cover.P2.P2-S4,
  .twofixed .main-post-link.P2.third-screen.third-line,
  .normal.main-p.center.S4,
  .normal.main-p {
      font: normal normal 400 30px/60px 'Poppins', sans-serif;
      text-indent: 0;
  }

  .main-post-link.frame4 {
      text-align: center;
      margin: 30px auto;
  }

  .main-page .main-post-link {
      margin-right: 0;

  }

  .main-page .Screen4 .main-post-link {
      margin: 30px auto 90px;
  }

  .sectors {
      max-width: 300px;
      margin: auto;
  }

  .main-page .main-post-link::before {
      right: 0;
      left: auto;
      margin: auto;
      width: 58%;
      height: 3px;
      bottom: 0;
  }

  .main-page .center.sector, .main-page .center.sector.S7 {
      font: normal normal 400 18px/30px 'Poppins', sans-serif;
  }

  .selectors--all {
      font: normal normal 700 16px/24px 'Poppins', sans-serif;
  }

  .page4 .main-post-link.P2.S2-P2.text-big.text-S2-P4.P4.S2.center,
  .twofixed .main-post-link.P2.S2-P2.text-S2-P4,
  .twofixed .main-post-link.text-big.text-S2-P4.P4.S2.center,
  .text-big.text-S2-P4.P4.S2,
  .head3.P4-S3,
  .head3.P4-S2,
  .twofixed .Screen7--h2.P2-S7-remade,
  .Sreen5--h3 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
      margin-top: 10px;
  }

  .twofixed .center.main-p.sector.normal, .Screen5--strong {
      font: normal normal 600 22px/36px 'Poppins', sans-serif;
  }

  .Screen5--p.P1-S6.P5-S6, .text-extending, .twofixed .Screen7--p.P2-S7-remade, .Screen5--p {
      font: normal normal 400 18px/24px 'Poppins', sans-serif;
      padding: 0 0 30px 11.5%;
  }

  .main-page .Screen5--p::after {
      top: -15px;
      left: 16px;
  }

  .Screen5--p.P5-S6::before, .Screen5--strong::after {
      height: 32px;
      width: 32px;
  }

  .Screen7--h2.S8 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
      padding: 120px 0 0;
  }

  .Screen8 {
      padding: 0 0 120px 0;
  }

  .twofixed .Screen7--h2.S8 {
      padding-top: 120px;
  }

  .twofixed .idn-img, .idn-img {
      width: 70px;
      margin-top: 60px;
  }

  .window1--description--button.S7.Advisor.P2-S7,
  .foot-li.foot-li-head {
      padding: 20px 0 10px;
      margin-top: 0;
  }

  .window1--description--button.S7.Advisor.P2-S7, .foot-li.foot-li-head {
      font: normal normal 600 18px/30px 'Poppins', sans-serif;
  }

  .twofixed .foot-lists, .foot-lists {
      padding-bottom: 60px;
      flex-direction: row;
      flex-wrap: wrap;
  }

  .twofixed .foot-ul, .foot-ul {
      flex: 1 0 20%;
      margin: 0 14% 0 0;
      display: flex;
      flex-direction: column;
  }


  .foot-ul:last-child .foot-li:last-child {
      position: absolute;
      bottom: 60px;
  }

  .twofixed .in, .in {
      height: 48px;
      width: 48px;
  }

  /*page2*/
  .twofixed .Screen4.P2 {
      padding-bottom: 60px;
  }

  .wishes, .twofixed .main-post-link.frame4.P2 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
  }

  .twofixed .window1-descript.P2.S6-P2.S6-P22,
  .twofixed .window1-descript.P2,
  .twofixed .window1-descript.P2.P2-S72.beyond,
  .twofixed .two-column--p,
  .twofixed .main-post-link.P2.third-screen,
  .twofixed .dots-item {
      font: normal normal 400 18px/42px 'Poppins', sans-serif;
  }

  .twofixed .window1-h.P2.S6-P2.added-element-before, .twofixed .window1-h.P2, .twofixed .window1-h.P2.P2-S7, .twofixed .window1-h.P2.S6-P2.added-element-before.P2-S6, .twofixed .window1-h.P2.beyond {
      font: normal normal 600 24px/32px 'Poppins', sans-serif;
      letter-spacing: 0;
      margin: -10px 0 10px;
      padding: 40px 0 40px 60px;
  }

  .twofixed .window1-h.P2.S6-P2.added-element-before::before,
  .twofixed .window1-h.P2::before {
      left: 13px;
      top: 0;
      margin: auto;
      height: 20px;
      width: 20px;
      bottom: 0;
  }

  .twofixed .rounded-frame::before {
      width: 48px;
      height: 48px;
      box-sizing: border-box;
      top: 0;
      left: 0;
  }

  /*page2*/
  .dots.Screen5--dots::after,
  .dots.Screen5--dots.Screen6--dots::after,
  .main-header-dots.dots::after,
  .dots.Screen5--dots.Screen6--dots.P5-S6::after,
  .twofixed .dots::after, .dots.P1-S2::after {
      width: 201px;
      height: 200px;
      bottom: -40px;
      right: -36px;
  }

  .dots.P2-S2.topPhoto.P4-S2::after {
      bottom: 0;
      right: 0;
      width: 37.7%;
      height: 34.7%;
  }

  .twofixed .main-p.cover.P2.P2-S4,
  .twofixed .main-post-link.P2.third-screen.third-line,
  .normal.main-p.center.S4,
  .normal.main-p {
      font: normal normal 400 26px/40px 'Poppins', sans-serif;
      text-indent: 0;
  }

  .back-color {
      padding-bottom: 70px;
  }

  .main-page .center.sector, .main-page .center.sector.S7 {
      font: normal normal 400 18px/62px 'Poppins', sans-serif;
  }

  .sectors {
      max-width: 366px;
  }

  .main-page .center.sector, .main-page .center.sector.S7 {
      flex: 0 0 165px;
      max-width: 165px;
      padding-top: 125px;
      font: normal normal 500 18px/30px 'Poppins', sans-serif;
      background-position: center 44%;
      background-size: 29% 29%;
      margin: 0;
  }

  .sectors.S7 {
      max-width: 90%;
  }

  .Screen4--all.center {
      margin: 80px 0 0;
  }

  .page4 .main-post-link.P2.S2-P2.text-big.text-S2-P4.P4.S2.center,
  .twofixed .main-post-link.P2.S2-P2.text-S2-P4,
  .twofixed .main-post-link.text-big.text-S2-P4.P4.S2.center,
  .text-big.text-S2-P4.P4.S2, .head3.P4-S3,
  .head3.P4-S2, .twofixed .Screen7--h2.P2-S7-remade,
  .Sreen5--h3 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
      margin-top: 10px;
  }

  .Screen5--description--content, .Screen5--description--content.S6c {
      padding: 60px 0 0;
  }

  .twofixed .window1--description, .window1--description {
      margin: 0 30px 0 0;
  }

  .twofixed .buttonIDN.big.P1-S7,
  .twofixed .buttonIDN.big,
  .back-slider.buttonIDN.white.decide,
  .buttonIDN.big.P1-S7,
  .buttonIDN.big {
      width: 190px;
      height: 60px;
      font: normal normal 500 24px/60px 'Poppins', sans-serif;
  }

  .twofixed .scroll-button.P2 {
      background-size: 9%;
  }

  .main-page .window2.Screen6--w2.Screen5--w1.S6 {
      top: 30px;
      margin: 0 0 135px;
  }

  .our-team-h2, .page5 .Screen7--h2.S8.P4-S8, .twofixed .Screen7--h2.S8, .Screen7--h2 {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
      padding: 130px 0 60px;

  }

  .Screen7--p {
      display: block;
      font: normal normal 400 22px/40px 'Poppins', sans-serif;
      max-width: 490px;
      margin: 30px auto;
      left: 0;
  }

  .twofixed .foot-li.foot-li-head,
  .window1--description--button.S7.Advisor.P2-S7, .foot-li.foot-li-head,
  .window1--description--button.S7 {
      margin: 70px 0 36px;
      padding: 0;
      font: normal normal 500 24px/36px 'Poppins', sans-serif;
  }

  .buttonIDN.big.P1-S7, .buttonIDN.big {
      width: 220px;
  }

  .login.buttonIDN.S2.big.white.back-slider.Screen2 {
      line-height: 56px;
  }

  .back-slider.buttonIDN.white.decide {
      width: 260px;
      height: 50px;
      margin: 70px auto 0;
      font: normal normal 500 20px/50px 'Poppins', sans-serif;
  }

  .twofixed .main-header-second-h2.head2 {
      padding: 120px 0 30px;
  }

  .idea {
      padding: 20px 0;
  }

  .twofixed .main-post-link.P2.third-screen.third-line,
  .twofixed .main-p.cover.P2.P2-S4 {
      font: normal normal 500 30px/60px 'Poppins', sans-serif;
      width: 480px;
  }

  .twofixed .window1-h.P2.S6-P2.added-element-before,
  .twofixed .window1-h.P2,
  .twofixed .window1-h.P2.P2-S7,
  .twofixed .window1-h.P2.S6-P2.added-element-before.P2-S6,
  .twofixed .window1-h.P2.beyond {
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
      letter-spacing: 0;
      margin: -10px 0 10px;
      padding: 40px 0 40px 100px;
  }

  .twofixed .main-header-second-h2.head2.P2 {
      padding-left: 100px;
  }

  .twofixed .window1-descript.P2,
  .twofixed .window1-descript.P2.S6-P2, .twofixed .window1-descript.P2.P2-S7,
  .twofixed .window1-descript.P2.S6-P2.S6-P22, .twofixed .window1-descript.P2.P2-S72.beyond {
      font: normal normal 400 18px/24px 'Poppins', sans-serif;
      padding-left: 100px;
  }

  .twofixed .Screen5.P2.P2-S72 {
      margin: 0;
  }

  .twofixed .left-dashed::before, .twofixed .window1.P2.P2-S6::before, .twofixed .window1.P2.P2-S7.P2-S62::before, .twofixed .window2.P2.P2-S72.left-dashed::before,
  .twofixed .window2.P2.P2-S5.left-dashed::before, .twofixed .window2.P2.P2-S7.left-dashed::before {
      top: 20%;
      width: 2px;
      left: 23px;
  }

  .twofixed .window2.P2.P2-S5.left-dashed::before {
      top: 153px;
  }

  .twofixed .window2.P2.P2-S72.left-dashed::before {
      top: 70px;
  }

  .twofixed .Screen7.P2-S7-remade {
      padding: 80px 0 0;
  }

  .twofixed .Screen7--p.P2-S7-remade {
      font: normal normal 400 24px/56px 'Poppins', sans-serif;
  }

  .twofixed .Screen7--p.P2-S7-remade {
      padding: 0;
      margin: 20px 0 60px;
  }

  .Screen7--p.P2-S7-remade::after {
      bottom: -35px;
  }

  .twofixed .sector.normal.main-p.center.S7.P2-S7-remade {
      padding: 95px 0 0;
      background-size: 55px 45px;
      background-position: 0 30px;
      font: normal normal 600 22px/32px 'Poppins', sans-serif;
  }

  .twofixed .window1-descript.P2.S6-P2.S6-P22, .twofixed .window1-descript.P2, .twofixed .window1-descript.P2.P2-S72.beyond, .twofixed .two-column--p, .twofixed .main-post-link.P2.third-screen, .twofixed .dots-item {
      font: normal normal 400 20px/32px 'Poppins', sans-serif;
  }

  .twofixed .dots-item {
      font-weight: 700;
  }

  .twofixed .dot-list {
      margin: 20px 0 20px 20px;
  }

  .twofixed .S7.S71.dot-list.Advisor {
      margin: 0;
      align-items: baseline;
      max-width: initial;
      min-width: initial;
      padding: 0 0 0 10px;
  }

  .twofixed .info-graph:last-child {
      justify-content: flex-start;
  }

  .window1--description--button.S7.Advisor.P2-S7 {
      margin: 20px 0 10px;
      font: normal normal 500 32px/60px 'Poppins', sans-serif;
  }

  .twofixed .buttonIDN.big.P1-S7 {
      width: 276px;
      margin: 0 0 70px;
  }

  .twofixed .Screen7--h2.S8 {
      padding-bottom: 0;
  }

  .page2 .Screen7--h2.S8,
  .page3 .Screen7--h2.S8 {
      margin: auto;
  }

  .page5 .Screen8 {
      padding: 0 60px 64px;
  }

  .common-info::before {
      width: 193%;
      bottom: -89.2%;
      left: -47%;
      height: 100%;
  }

  .twofixed .page3.window1-descript.P2.S2-P2, .twofixed .page2.window1-descript.P2.S2-P2,
  .yellow-upper.P5, .twofixed .main-post-link.P2.S2-P2, .twofixed .page3.window1-descript.P2.S2-P2 {
      font: normal normal 400 30px/54px 'Poppins', sans-serif;
  }

  .not-so-good .text-extending, .page4 .text-extending {
      margin: 50px 0 60px;
      padding: 0;
  }

  .not-so-good .text-extending, .page4 .text-extending, .item.h3-P4 {
      font: normal normal 400 18px/30px 'Poppins', sans-serif;
  }

  .item.h3-P4::before {
      height: 8px;
      width: 8px;
  }

  .grey {
      margin: auto;
  }

  .Screen6.P5-S6.P5-S6 {
      padding: 0 60px;
      margin: 0;
  }

  .twofixed .main-header-second-h2.P1-S6.P5-S6 {
      margin: 0;
      font: normal normal 600 40px/60px 'Poppins', sans-serif;
      padding: 100px 0 10px 55px;
  }

  .wishes {
      padding-left: 20px;
  }

  .window2.Screen6--w2.Screen5--w1.S6.P5-S6 {
      margin: 0;
  }

  .Screen5--p.P1-S6.P5-S6 {
      padding: 30px 0 30px 11.5%;
  }

  .Screen5--p.P1-S6.P5-S6::after {
      left: 15px;
      width: 2px;
  }

  .Screen5--p.P1-S6.P5-S6:last-of-type::after {
      left: 10px;
  }

  .dots.Screen6--dots.P5-S6 {
      margin: 75px 0 70px;
      width: 90.5%;
  }

  .dots.Screen5--dots.Screen6--dots.P5-S6::after {
      right: -46px;
      bottom: -40px;
  }

  .our-team-h2 {
      padding: 60px 0 0;
  }

  .avatars-collection {
      margin: 60px 0 0;
  }

  .avatars-collection {
      flex-direction: column;
  }

  .our-team-photo {
      width: auto;
  }

  .name-team-member {
      font: normal normal 700 22px/39px 'Poppins', sans-serif;
  }

  .specialist-type {
      font: normal normal 400 22px/39px 'Poppins', sans-serif;
  }

  .linkedinlogo {
      width: 52px;
      height: 52px;
      background-size: 44px;
  }

  .page5 .text-big.text-S2-P4.P4.S2.P5-our-team.P5,
  .twofixed .main-post-link.text-big.text-S2-P4.P4.S2.center {
      font: normal normal 700 24px/48px 'Poppins', sans-serif;
      letter-spacing: 0;
  }

  .page5 .text-big.text-S2-P4.P4.S2.P5-our-team.P5 {
      padding: 60px 0;
  }

  .text-big.text-S2-P4.P4.S2.P5-our-team.P5::after {
      top: 30px;
  }

  .twofixed .main-top-nav-anchor, .main-top-nav-anchor {
      line-height: 8vh;
      font-size: 32px;
      min-width: 250px;
  }

  .main-page .login.buttonIDN.S2.big.white.back-slider.Screen2 {
      line-height: 56px;
      font-size: 22px;
  }
}

@media (min-width: 320px) and (max-width: 359px) {
  .twofixed .dots.topPhoto, .dots {
      height: 190px;
      margin: 48px 0;
  }
}

@media (max-width: 1070px) {
  .twofixed .First_Screen, .main-page .First_Screen {
      height: auto;
  }

  .less1023 {
      display: block;
  }

  .terms .Header,
  .privacy .Header {
      z-index: 1;
      position: relative;
  }
}

@media (min-width: 600px) and  (max-width: 1070px) and (orientation: portrait ) {
  .twofixed .window1--button-group.P2.P2-S2, .window1--button-group {
      margin-bottom: 24px;
  }

  .First_Screen #top + .Screen2 .window2 {
      padding: 10% 0 20%;
  }
}

@media (min-width: 800px) and  (max-width: 1070px) and (orientation: landscape ) {
  .First_Screen #top + .Screen2 .window2 {
      padding: 0 0 20% 0;
  }
}

@media (max-width: 359px) and (orientation: portrait ) {
  .twofixed .window1--button-group.P2.P2-S2, .window1--button-group {
      margin-bottom: 24px;
  }

  .First_Screen #top + .Screen2 .window2 {
      padding-bottom: 20%;
  }
}

@media (max-width: 1070px) and (orientation: landscape ) {
  .main-page .First_Screen,
  .twofixed.page2 .First_Screen,
  .twofixed.page3 .First_Screen,
  .twofixed.page4 .First_Screen,
  .twofixed.page5 .First_Screen {
      height: auto;
  }
}

@media (min-width: 360px) and  (max-width: 599px) and (orientation: portrait ) {
  .main-page .First_Screen,
  .twofixed.page2 .First_Screen,
  .twofixed.page3 .First_Screen,
  .twofixed.page4 .First_Screen,
  .twofixed.page5 .First_Screen {
      height: 100vh;
  }
}

@media (max-width: 1070px)  and (orientation: landscape) {
  .twofixed .main-top-nav, .main-top-nav {
      height: auto;
      padding: 0;
  }

  #top + .Screen2 .window1 {
      margin-top: 100px;
  }
}

.login.buttonIDN.S2.big.white.back-slider.Screen2 {
  border: 2px solid #3680f6;
}

.login.buttonIDN.S2.big.white.back-slider.Screen2 {
  box-shadow: none;
  box-sizing: border-box;
}

.cookie,
.policy {
  max-width: 966px;
  margin: 100px auto;
}

.policy .head-policy .text-extending,
.policy .head-policy .text-extending.strong {
  margin: 0;
  line-height: 28px;
}

.policy h1.head3.P4-S3 {
  font-size: 60px;
  line-height: 110px;
  text-align: center;
  margin: 36px 0;
}

.policy ul {
  margin: 0;
  padding: 0;
}

.policy .bullet {
  margin-left: 80px;
}

.policy .bullet .dots-item {
  margin: 16px 0;
}

.policy .head3.P4-S3 {
  font-size: 16px;
  line-height: 27px;
  margin: 24px 0 16px;
  padding: 0;
  letter-spacing: 0;
}

.policy .head-policy {
  text-align: right;
}

.cookie h1,
.policy .main-header-second-h2.head2 {
  line-height: 27px;
  margin: 30px 0;
  color: #232D53;
  letter-spacing: 0;
  text-indent: 56px;
}

.cookie h1 {
  text-align: center;
  line-height: 60px;
  text-indent: 0;
}

.cookie h1 {
  text-align: center;
}

.policy .head-policy-h2 {
  text-align: center;
}

.policy > ul > li, .policy > ul > li > ul > li {
  list-style-type: none;
}

.policy .text-extending {
  padding: 0;
  margin: 16px 0;
}

.policy .text-extending.strong {
  font-weight: 700;
  line-height: 27px;
}

.cookie p, .cookie li, .cookie a,
.policy .text-extending,
.policy .text-extending.strong {
  font-size: 14px;
  color: #232D53;
  padding: 0;
  margin: 0 0 16px 0;
}

.policy .bullet .text-extending {
  margin: 0;
  padding: 0;
}

.twofixed .policy .dots-item::before {
  background-color: #232D53;
}

.policy .head-policy ~ .h3.text-extending {
  line-height: 24px;
  margin-left: 0;
}

.policy .h3 ~ .text-extending {
  margin: 16px 0 0 24px;
  line-height: 24px;
}

.policy strong {
  font-weight: 700;
}

.vis section {
  background-color: transparent;
}

@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;